export const namespaced = true

export const state = {
    browserLabels: null,
    browsers: null,
    clickCount: null,
    dates: null,
    deviceLabels: null,
    devices: null,
    hours: null,
    misc: null,
    os: null,
    osLabels: null,
    slugCount: null,
    urlList: null,
    userCount: null,
    cumulativeGraphs: null,
    systemMessage: null,
    userNotifications: null
}

export const mutations = {
    SET_DATA(state, data) {
        state.browserLabels = data.browserLabels
        state.browsers = data.browsers
        state.clickCount = data.clickCount
        state.dates = data.dates
        state.deviceLabels = data.deviceLabels
        state.devices = data.devices
        state.hours = data.hours
        state.misc = data.misc
        state.os = data.os
        state.osLabels = data.osLabels
        state.slugCount = data.slugCount
        state.urlList = data.urlList
        state.userCount = data.userCount
        state.cumulativeGraphs = data.cumulativeGraphs
        state.systemMessage = data.systemMessage
        state.userNotifications = data.userNotifications
    },
    SET_PARTAL_URL_DATA(state, data) {
        state.urlList = data
    },
    SET_LOCAL_URL(state, data) {
        if(state.urlList) {
            // only if dashboard is in cache
            state.urlList = [data, ...state.urlList]
            state.slugCount++
        }
    },
    REMOVE_LOCAL_URL(state, data) {
        if(state.urlList) {
            // only if dashboard is in cache
            state.urlList = state.urlList.filter((row) => {
                return row.slug !== data.slug
            })
            state.slugCount--
        }
    }
}

export const actions = {
    setData({ commit, dispatch }, data) {
        commit('SET_DATA', data)
    },
    setPartialUrlData({ commit, dispatch }, data) {
        commit('SET_PARTAL_URL_DATA', data)
    },
    setLocalUrl({ commit, dispatch }, data) {
        commit('SET_LOCAL_URL', data)
    },
    removeLocalUrl({ commit, dispatch }, data) {
        commit('REMOVE_LOCAL_URL', data)
    },
}
