import owasp from 'owasp-password-strength-test'

owasp.config({
    allowPassphrases: true,
    maxLength: 128,
    minLength: 8,
    minPhraseLength: 20,
    minOptionalTestsToPass: 4
})

export function owaspStrong(value) {
    return owasp.test(value).strong
}

export function owaspTest(value) {
    return owasp.test(value)
}

export function owaspToPT(errors) {
    const dict = {
        'The password must be at least ([0-9]+) characters long.': 'Pelo menos $ caracteres',
        'The password must be fewer than ([0-9]+) characters.': 'No máximo $ caracteres',
        'The password may not contain sequences of three or more repeated characters.': 'Não pode ter sequências de 3 ou mais caracteres iguais',
        'The password must contain at least one lowercase letter.': 'Pelo menos uma minúscula',
        'The password must contain at least one uppercase letter.': 'Pelo menos uma maiúscula',
        'The password must contain at least one number.': 'Pelo menos um número',
        'The password must contain at least one special character.': 'Pelo menos um caracter especial'
    }

    let pt = []
    for(let i = 0; i < errors.length; i++) {
        let errorEN = ''
        for (const s in dict) {
            let match = errors[i].match(s)
            if(match) {
                if(match[1]) {
                    let param = match[1]
                    let phrase = dict[s].split('$')
                    phrase = phrase[0] + param + phrase[1]
                    pt.push(phrase)
                } else {
                    pt.push(dict[s])
                }
            }
        }
    }
    return pt
}
