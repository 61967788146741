//
// charts.js
// Theme module
//

function parseOptions(chart, options) {
    for (var item in options) {
        if (typeof options[item] !== 'object') {
            chart[item] = options[item]
        } else {
            parseOptions(chart[item], options[item])
        }
    }
}

function pushOptions(chart, options) {
    for (var item in options) {
        if (Array.isArray(options[item])) {
            options[item].forEach(function(data) {
                chart[item].push(data)
            })
        } else {
            pushOptions(chart[item], options[item])
        }
    }
}

function popOptions(chart, options) {
    for (var item in options) {
        if (Array.isArray(options[item])) {
            options[item].forEach(function(data) {
                chart[item].pop()
            })
        } else {
            popOptions(chart[item], options[item])
        }
    }
}

function getChartInstance(chart) {
    let chartInstance = undefined

    Chart.helpers.each(Chart.instances, function(instance) {
        if (chart == instance.chart.canvas) {
            chartInstance = instance
        }
    })

    return chartInstance
}

function toggleTicks(chart, prefix, suffix) {
    prefix = prefix ? prefix : ''
    suffix = suffix ? suffix : ''

    chart.options.scales.yAxes[0].ticks.callback = function(value) {
        if ( !(value % 10) ) {
            return prefix + value + suffix
        }
    }

    chart.options.tooltips.callbacks.label = function(item, data) {
        const label = data.datasets[item.datasetIndex].label || ''
        const yLabel = item.yLabel
        let content = ''

        if (data.datasets.length > 1) {
            content += '<span class="popover-body-label mr-auto">' + label + '</span>'
        }

        content += '<span class="popover-body-value">' + prefix + yLabel + suffix + '</span>'
        return content
    }
}

function toggleOptions(el) {
    const target = el.dataset.target
    const targetEl = document.querySelector(target)
    const chart = getChartInstance(targetEl)
    const options = JSON.parse(el.dataset.add)

    if (el.checked) {
        pushOptions(chart, options)
    } else {
        popOptions(chart, options)
    }

    chart.update()
}

function updateOptions(el) {
    const target = el.dataset.target
    const targetEl = document.querySelector(target)
    const chart = getChartInstance(targetEl)
    const options = JSON.parse(el.dataset.update)
    const prefix = el.dataset.prefix
    const suffix = el.dataset.suffix

    parseOptions(chart, options);

    if (prefix || suffix) {
        toggleTicks(chart, prefix, suffix)
    }

    chart.update()
}

function toggleLegend(el) {
    const chart = getChartInstance(el)
    const legend = chart.generateLegend()
    const target = el.dataset.target
    const targetEl = document.querySelector(target)

    targetEl.innerHTML = legend
}

// TODO ideally we want to phase out this and pass the functionality to the chart components
export function addCallbacks(target, ignoreClick = false) {
    const toggle = document.querySelectorAll('[data-toggle="chart"][data-target="#' + target + '"]')
    if(toggle) {
        [].forEach.call(toggle, function(el) {
            el.addEventListener('change', function() {
                if (el.dataset.add) {
                    toggleOptions(el)
                }
            })
            if (!ignoreClick) {
                el.addEventListener('click', function() {
                    if (el.dataset.update) {
                        updateOptions(el)
                    }
                })
            }
        })
    }

    const legend = document.querySelectorAll('[data-toggle="legend"][data-target="#' + target + '"]')
    if (legend) {
        [].forEach.call(legend, function(el) {
            toggleLegend(el)
      })
    }
}
