<template>
<div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xl-8">
        <!-- Header -->
        <div class="header mt-md-5" id="sampleRow">
            <div class="header-body">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="header-pretitle">
                            Miniaturas
                        </h6>
                        <h1 class="header-title">
                            Criar nova miniatura
                        </h1>
                    </div>
                </div>
            </div>
        </div>

        <form class="mb-4" @submit.prevent="submitCreateSlug">
            <div id="floatingDiv">
                <div class="col-12">
                    <ShoppingCartCard id="priceCard" :cost="wsCost" :costBeforePromo="wsCostBeforePromo" :error="wsError" :submitStatus="submitButtonStatus" :isFree="slugType === 'random'" :priceAvailable="webSocketConnected" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="mb-3">Escolhe o tipo de miniatura</label>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="card h-100" style="cursor: pointer;"  :class="{ 'border-primary': slugType === 'random' }" @click="changeSlugType('random')">
                        <div class="card-body text-center">
                            <div class="card-avatar avatar avatar-lg mx-auto">
                                <inline-svg src="/assets/img/icons/dices.svg" class="avatar-img rounded"></inline-svg>
                            </div>

                            <h2 class="mb-3">
                                Miniatura Aleatória
                            </h2>

                            <p class="card-text text-muted">
                                Como utilizador registado podes criar uma miniatura aleatória com apenas 5 caracteres, facilitando a partilha do teu link.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="card h-100" style="cursor: pointer;" :class="{ 'border-primary': slugType === 'custom' }" @click="changeSlugType('custom')">
                        <div class="card-body text-center">
                            <div class="card-avatar avatar avatar-lg mx-auto">
                                <inline-svg src="/assets/img/icons/sketch.svg" class="avatar-img rounded"></inline-svg>
                            </div>
                            <h2 class="mb-3">
                                Miniatura Personalizada
                            </h2>
                            <p class="card-text text-muted">
                                Cria um texto memorável à tua escolha.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mt-5 mb-5">

            <!-- Destination field -->
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="mb-1">Insere o destino da tua miniatura</label>
                        <BaseTextArea v-model.trim="destination"
                            @blur="$v.destination.$touch()"
                            :dirty="$v.destination.$dirty"
                            :error="$v.destination.$error"
                        />

                        <BaseValidation
                            :messages="getValidationObjects().destination"
                        />
                    </div>
                </div>
            </div>

            <hr class="mt-5 mb-5">

            <!-- Choose slug field -->
            <div class="row animated fadeInDown" v-if="slugType === 'custom'">
                <div class="col-12">
                    <label class="mb-1">Escolhe a tua miniatura</label>
                    <small class="form-text text-muted">
                        Cria um texto curto e que represente o teu conteúdo.
                    </small>
                </div>
            </div>
            <div class="row animated fadeInDown" v-if="slugType === 'custom'">
                <div class="col-12">
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="customUrlNamespace">https://clica.ai/</span>
                            </div>

                            <BaseInput type="text" v-model="customSlug" id="customSlugInput" aria-describedby="customUrlNamespace" @click="slugType = 'custom'" @keyup="sendCostQuery"
                                @blur="$v.customSlug.$touch()"
                                :dirty="$v.customSlug.$dirty"
                                :error="$v.customSlug.$error || webSocketConnected && wsError !== null"
                            />

                            <BaseValidation
                                :messages="getValidationObjects().customSlug"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mt-5 mb-5 animated fadeInDown" v-if="slugType === 'custom'">

            <!-- Promo code field -->
            <div class="row animated fadeInDown" v-if="slugType === 'custom'">
                <div class="col-12">
                    <label class="mb-1">Código promocional</label>
                    <small class="form-text text-muted">
                        Se tiveres um código promocional, insere-o aqui. O preço final será mostrado no próximo passo.
                    </small>
                </div>
            </div>
            <div class="row animated fadeInDown" v-if="slugType === 'custom'">
                <div class="col-12">
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <BaseInput type="text" v-model="promoCode" id="promoCodeInput" @keyup="sendCostQuery" />
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mt-5 mb-5 animated fadeInDown" v-if="slugType === 'custom'">

            <!-- DIV where payment window "stops" -->
            <div id="stationaryDiv">
                <div class="col-12">
                    <ShoppingCartCard :cost="wsCost" :costBeforePromo="wsCostBeforePromo" :error="wsError" :submitStatus="submitButtonStatus" :isFree="slugType === 'random'" :priceAvailable="webSocketConnected" />
                </div>
            </div>

            <hr class="mt-5 mb-2">

            <BaseButton :status="cancelButtonStatus" :setButtonType="false" buttonExternalClasses="btn-link text-muted" @click="cancelCreateSlug">
                Cancelar
            </BaseButton>
        </form>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/store'

import { required, requiredIf } from 'vuelidate/lib/validators'

import Websocket from '@/mixins/websocket'
import ShoppingCartCard from '@/components/ShoppingCartCard'

export default {
    props: {
        startingSlugTypeCustom: {
            type: Boolean,
            default: false
        },
        parentNextStage: {
            type: Function,
            required: true
        }
    },
    mixins: [Websocket],
    components: {
        ShoppingCartCard
    },
    data () {
        return this.getFreshCreateSlugObject()
    },
    computed: {
        ...mapState({
            hasPendingPayment: state => state.createSlug.paymentData.paymentIntentClientSecret !== null
        }),
        submitButtonStatus() {
            return this.$v.$invalid? 'disabled' : (this.loading? 'loading' : 'default')
        },
        cancelButtonStatus() {
            return this.loading? 'disabled' : 'default'
        }
    },
    created() {
        $(function fixedUntilPoint(){
            const stationaryDiv = $('#stationaryDiv')
            const floatingDiv = $('#floatingDiv')

            const testScrollPosition = () => {
                const cardHeight = $('#priceCard').outerHeight()

                const stationaryDivTop = stationaryDiv.offset().top
                const stationaryDivBottom = stationaryDivTop + cardHeight

                const viewportBottomPos = $(document).scrollTop() + $(window).height()

                if (viewportBottomPos >= stationaryDivBottom) {
                    // bottom of viewport passed the bottom of the "inline" div, so show that inline div
                    if(floatingDiv.css('visibility') === 'visible') {
                        floatingDiv.css('visibility', 'hidden')
                        stationaryDiv.css('visibility', 'visible')
                    }

                    // floatingDiv.css({
                    //     bottom: 'auto',
                    //     position: 'absolute',
                    //     top: stationaryDivTop
                    // })
                } else {
                    // bottom of viewport does not show the bottom of the "inline" div, so show the floating one
                    if(floatingDiv.css('visibility') !== 'visible') {
                        stationaryDiv.css('visibility', 'hidden')
                        floatingDiv.css('visibility', 'visible')
                    }
                }

                stationaryDiv.css({
                    height: cardHeight
                })

                floatingDiv.css({
                    width: $('#sampleRow').outerWidth(),
                    bottom: 0,
                    position: 'fixed',
                    'margin-bottom': '-40px'
                })
            }

            $(window).on('scroll resize', testScrollPosition)
            testScrollPosition()
        })

        this.setupWebsocket(this.sendCostQuery, this.receivedMessage)

        if (this.startingSlugTypeCustom)
            this.slugType = 'custom'
    },
    beforeDestroy() {
        this.closeWebSocket()
    },
    methods: {
        getFreshCreateSlugObject() {
            return {
                wsCost: 0,
                wsCostBeforePromo: 0,
                wsError: null,

                loading: false,

                destination: '',
                slugType: 'random',
                customSlug: '',
                promoCode: ''
            }
        },
        getValidationObjects() {
            return {
                destination: [{
                    condition: !this.$v.destination.required,
                    message: 'Destino obrigatório'
                }],
                customSlug: [
                    { condition: !this.$v.customSlug.required, message: 'Miniatura obrigatória' },
                    { condition: this.$v.customSlug.required && (this.wsError === 'slug_invalid' || this.wsError === 'slug_illegal'), message: 'Miniatura com caracteres inválidos' },
                    { condition: this.$v.customSlug.required && this.wsError === 'slug_too_long', message: 'Miniatura muito longa' },
                    { condition: this.$v.customSlug.required && (this.wsError === 'slug_in_use' || this.wsError === 'slug_reserved'), message: 'Miniatura já existente. Tenta outra combinação!' }
                ],
            }
        },
        changeSlugType(type) {
            this.slugType = type
            this.sendCostQuery()
        },
        sendCostQuery() {
            if (this.slugType === 'custom') {
                const q = { t: 'c', s: this.customSlug, p: this.promoCode }
                this.sendWebSocketMessage(q)
            }
        },
        receivedMessage(msg) {
            if (msg.e) {
                this.wsError = msg.e
                this.wsCost = -1
                return
            } else {
                this.wsError = null
                this.wsCost = msg.c / 100
                this.wsCostBeforePromo = msg.p / 100
            }
        },
        cancelCreateSlug() {
            Object.assign(this.$data, this.getFreshCreateSlugObject())
            this.$router.push({ name: 'home' })
        },
        async submitCreateSlug() {
            this.$v.$touch() // makes the form dirty to show errors
            if (this.$v.$pending || this.$v.$error || this.$v.$invalid)
                return

            if (this.hasPendingPayment) {
                // a payment intent was already requested, show the modal directly
                this.parentNextStage('pay')
                return
            }

            this.loading = true

            try {
                const createSlugBody = {
                    destination: this.destination,
                    token: null,
                    slugType: this.slugType,
                    promoCode: this.promoCode
                }

                switch (this.slugType) {
                    case 'custom':
                        createSlugBody.desiredSlug = this.customSlug.toString()
                        break
                    default:
                        createSlugBody.desiredSlug = null
                        break
                }

                const res = await store.dispatch('api/createSlug', createSlugBody)
                this.loading = false

                const slugData = {
                    destination: this.destination,
                    namespace: 'https://clica.ai/',
                    slug: createSlugBody.desiredSlug
                }

                await store.dispatch('createSlug/setSlugData', slugData)

                if (res.data.body.clientSecret) {
                    // a payment is needed
                    const paymentData = {
                        paymentIntentClientSecret: res.data.body.clientSecret,
                        serverSideCostEur: res.data.body.costEur,
                        serverSideCostEurOriginal: res.data.body.costEurOriginal,
                        timeLimit: Date.now() + 600000
                    }

                    await store.dispatch('createSlug/setPaymentData', paymentData)

                    // put slug in the dashboard, in case the user aborts midway
                    await store.dispatch('dashboard/setLocalUrl', {
                        url: this.destination,
                        slug: createSlugBody.desiredSlug,
                        click_count: 0,
                        destination_long: !(this.destination.includes('http://') || this.destination.includes('https://')) ? 'https://' + this.destination : this.destination,
                        destination: this.destination.length > 83 ? this.destination.substring(0,80) + '...' : this.destination,
                        date_created: this.isoDateToHuman(new Date().toISOString()),
                        last_click: '-',
                        pending: true
                    }, { root: true })

                    this.loading = false

                    // show next stage
                    this.parentNextStage('pay')

                } else if (res.data.body.url){
                    // slug is done, show to user
                    const fullUrl = res.data.body.url
                    const namespace = res.data.body.namespace
                    const slug = res.data.body.slug

                    // open success modal
                    this.$root.$emit('openSlugModal', fullUrl, namespace, slug)

                    // clean form and component state
                     Object.assign(this.$data, this.getFreshCreateSlugObject())
                     this.$v.$reset()
                } else {
                    console.log('error')
                }

            } catch (err) {
                this.loading = false
                console.log(err);
                // TODO handle errors
            }
        }
    },
    validations() {
        const validation = {
            destination: {
                required
            },
            customSlug: {

            }
        }

        if (this.slugType === 'custom') {
            validation.customSlug = {
                required
            }
        }

        return validation
    }
}
</script>

<style lang="css" scoped>
#floatingDiv {
    bottom: 0;
    position: fixed;
    z-index: 1000;
}
</style>
