<template>
    <div>
        <ModalStartTrial ref="trialModal" />
        <ModalSlugCreated ref="slugCreatedModal" :fullUrl="slugCreatedModal.fullUrl" :namespace="slugCreatedModal.namespace" :slug="slugCreatedModal.slug" />
        <ModalConfirmCancelSlugReservation ref="cancelSlugReservationModal" :namespace="cancelSlugReservationModal.namespace" :slug="cancelSlugReservationModal.slug" />

        <ModalConfirmBlock ref="blockModal" :namespace="blockModal.namespace" :slug="blockModal.slug" />
        <ModalChangeDestination ref="changeDestinationModal" :namespace="changeDestinationModal.namespace" :slug="changeDestinationModal.slug" />
        <ModalConfirmAdminDelete ref="adminDeleteModal" :namespace="adminDeleteModal.namespace" :slug="adminDeleteModal.slug" />

        <ModalBillingConfirmRemoveAddress ref="billingConfirmRemoveAddressModal" :statusSetter="billingConfirmRemoveAddressModal.statusSetter" />
        <ModalBillingConfirmRemoveCard ref="billingConfirmRemoveCardModal" :statusSetter="billingConfirmRemoveCardModal.statusSetter" :isLastCard="billingConfirmRemoveCardModal.isLastCard" :cardData="billingConfirmRemoveCardModal.cardData" />

        <ModalPayInvoice ref="payInvoiceModal" :invoiceId="payInvoiceModal.invoiceId" :invoiceNumber="payInvoiceModal.invoiceNumber" :invoiceTotal="payInvoiceModal.invoiceTotal" :clientSecret="payInvoiceModal.clientSecret" :setParentUpdatingInvoice="payInvoiceModal.setParentUpdatingInvoice" />

        <!-- Navigation Sidebar -->
        <NavigationSidebarFree v-if="userType === 'free'" />
        <NavigationSidebarPremium v-else-if="userType === 'premium'" />
        <NavigationSidebarAdmin v-else-if="userType === 'admin'" />

        <!-- Main Content -->
        <router-view></router-view>
    </div>
</template>

<script>
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'

import ModalStartTrial from '@/components/modals/ModalStartTrial'
import ModalSlugCreated from '@/components/modals/ModalSlugCreated'
import ModalConfirmCancelSlugReservation from '@/components/modals/ModalConfirmCancelSlugReservation'
import ModalConfirmBlock from '@/components/modals/ModalConfirmBlock'
import ModalChangeDestination from '@/components/modals/ModalChangeDestination'
import ModalConfirmAdminDelete from '@/components/modals/ModalConfirmAdminDelete'
import ModalBillingConfirmRemoveAddress from '@/components/modals/ModalBillingConfirmRemoveAddress'
import ModalBillingConfirmRemoveCard from '@/components/modals/ModalBillingConfirmRemoveCard'
import ModalPayInvoice from '@/components/modals/ModalPayInvoice'

import { getStorage } from '@/assets/localStorage.js'

export default {
    components: {
        ModalStartTrial,
        ModalSlugCreated,
        ModalConfirmCancelSlugReservation,
        ModalConfirmBlock,
        ModalChangeDestination,
        ModalConfirmAdminDelete,
        ModalBillingConfirmRemoveAddress,
        ModalBillingConfirmRemoveCard,
        ModalPayInvoice
    },
    computed: {
        ...authComputed
    },
    data() {
        return {
            slugCreatedModal: {
                fullUrl: null,
                namespace: null,
                slug: null
            },
            cancelSlugReservationModal: {
                namespace: null,
                slug: null
            },
            blockModal: {
                namespace: null,
                slug: null
            },
            changeDestinationModal: {
                namespace: null,
                slug: null
            },
            adminDeleteModal: {
                namespace: null,
                slug: null
            },
            billingConfirmRemoveAddressModal: {
                statusSetter: null
            },
            billingConfirmRemoveCardModal: {
                statusSetter: null,
                isLastCard: null,
                cardData: null
            },
            payInvoiceModal: {
                invoiceNumber: null,
                invoiceId: null,
                invoiceTotal: null,
                clientSecret: null,
                setParentUpdatingInvoice: null
            }
        }
    },
    async created () {
        const isTest = JSON.parse(atob(getStorage('user')['token'].split('.')[1]))['u'] === 'd3c7b91b-bfbb-4f6f-8803-f36dbb732b7d'
        const key = isTest? this.constants.stripeClientTestKey : this.constants.stripeClientLiveKey
        await store.dispatch('stripe/setStripeObject', key, { root: true })
    },
    mounted() {
        this.$root.$on('openTrialModal', () => this.openTrialModal())
        this.$root.$on('openSlugModal', (fullUrl, namespace, slug) => this.openSlugModal(fullUrl, namespace, slug))
        this.$root.$on('openCancelSlugReservationModal', (namespace, slug) => this.openCancelSlugReservationModal(namespace, slug))

        this.$root.$on('openBlockModal', (namespace, slug) => this.openBlockModal(namespace, slug))
        this.$root.$on('openChangeDestinationModal', (namespace, slug) => this.openChangeDestinationModal(namespace, slug))
        this.$root.$on('openAdminDeleteModal', (namespace, slug) => this.openAdminDeleteModal(namespace, slug))

        this.$root.$on('openBillingConfirmRemoveAddressModal', (statusSetter) => this.openBillingConfirmRemoveAddressModal(statusSetter))
        this.$root.$on('openBillingConfirmRemoveCardModal', (statusSetter, isLastCard, cardData) => this.openBillingConfirmRemoveCardModal(statusSetter, isLastCard, cardData))

        this.$root.$on('openPayInvoiceModal', (invoiceId, invoiceNumber, invoiceTotal, clientSecret, setParentUpdatingInvoice) => this.openPayInvoiceModal(invoiceId, invoiceNumber, invoiceTotal, clientSecret, setParentUpdatingInvoice))
    },
    methods: {
        openTrialModal () {
            const element = this.$refs.trialModal.$el
            $(element).modal('show')
        },
        openSlugModal (fullUrl, namespace, slug) {
            this.slugCreatedModal.fullUrl = fullUrl
            this.slugCreatedModal.namespace = namespace
            this.slugCreatedModal.slug = slug

            const element = this.$refs.slugCreatedModal.$el
            $(element).modal('show')
        },
        openCancelSlugReservationModal (namespace, slug) {
            this.cancelSlugReservationModal.namespace = namespace
            this.cancelSlugReservationModal.slug = slug

            const element = this.$refs.cancelSlugReservationModal.$el
            $(element).modal('show')
        },
        openBlockModal (namespace, slug) {
            this.blockModal.namespace = namespace
            this.blockModal.slug = slug

            const element = this.$refs.blockModal.$el
            $(element).modal('show')
        },
        openChangeDestinationModal (namespace, slug) {
            this.changeDestinationModal.namespace = namespace
            this.changeDestinationModal.slug = slug

            const element = this.$refs.changeDestinationModal.$el
            $(element).modal('show')
        },
        openAdminDeleteModal (namespace, slug) {
            this.adminDeleteModal.namespace = namespace
            this.adminDeleteModal.slug = slug

            const element = this.$refs.adminDeleteModal.$el
            $(element).modal('show')
        },
        openBillingConfirmRemoveAddressModal (statusSetter) {
            this.billingConfirmRemoveAddressModal.statusSetter = statusSetter

            const element = this.$refs.billingConfirmRemoveAddressModal.$el
            $(element).modal('show')
        },
        openBillingConfirmRemoveCardModal (statusSetter, isLastCard, cardData) {
            this.billingConfirmRemoveCardModal.statusSetter = statusSetter
            this.billingConfirmRemoveCardModal.isLastCard = isLastCard
            this.billingConfirmRemoveCardModal.cardData = cardData

            const element = this.$refs.billingConfirmRemoveCardModal.$el
            $(element).modal('show')
        },
        openPayInvoiceModal (invoiceId, invoiceNumber, invoiceTotal, clientSecret, setParentUpdatingInvoice) {
            this.payInvoiceModal.invoiceId = invoiceId
            this.payInvoiceModal.invoiceNumber = invoiceNumber
            this.payInvoiceModal.invoiceTotal = invoiceTotal
            this.payInvoiceModal.clientSecret = clientSecret
            this.payInvoiceModal.setParentUpdatingInvoice = setParentUpdatingInvoice

            const element = this.$refs.payInvoiceModal.$el
            $(element).modal('show')
        }
    }
}
</script>

<style scoped></style>
