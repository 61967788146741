<template>
<div class="modal fade" id="modalPayInvoice" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Pagar Fatura
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <h1>Pagar fatura #{{ invoiceNumber }}</h1>

                    <div class="row">
                        <div class="col-12">
                            <span class="text-body">
                                <ul>
                                    <li>Valor: <b>{{ getHumanPrice(invoiceTotal) }}</b></li>
                                    <li>Escolhe um cartão guardado na tua conta ou usa um cartão <b>apenas para esta transação</b>.</li>
                                    <li>Se inserires um cartão será apenas usado para esta transação e não serão cobradas outras faturas.</li>
                                    <li>A tua subscrição poderá demorar uns minutos até ficar regularizada.</li>
                                </ul>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <CreditCardSelector @card-selection="setSelectedCard" @stripe-card-element="setStripeCardElement" @card-validation="setInsertedCardValidity" :addCardOption="true" />
                        </div>
                    </div>

                    <hr class="mt-4 mb-5">

                    <form class="mb-4" @submit.prevent="attemptPay">
                        <div class="row">
                            <div class="col-12">
                                <div class="alert alert-danger" role="alert" v-if="uiError">
                                    {{ uiError }}
                                </div>
                                <BaseButton type="submit" :status="submitButtonStatus">
                                    Pagar {{ getHumanPrice(invoiceTotal) }}
                                </BaseButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/store'
import { mapState, mapGetters } from 'vuex'
import { authComputed } from '@/store/helpers.js'

import { required, minLength } from 'vuelidate/lib/validators'

import CreditCardSelector from '@/components/billing/CreditCardSelector'
import CreditCardData from '@/components/billing/CreditCardData'

export default {
    props: {
        invoiceId: {
            type: String
        },
        invoiceNumber: {
            type: String
        },
        invoiceTotal: {
            type: Number
        },
        clientSecret: {
            type: String
        },
        setParentUpdatingInvoice: {
            type: Function
        }
    },
    components: {
        CreditCardSelector,
        CreditCardData
    },
    data() {
        return {
            uiError: null,
            loading: false, // track submission status to display pending button

            isCardDataValid: false,
            stripeCardElement: null,
            selectedCard: null
        }
    },
    computed: {
        ...mapState({
            cards: state => state.billing.cards,
            stripeObject: state => state.stripe.stripeObject
        }),
        ...mapGetters({ pending: 'api/isPending' }),
        isSubmitPending() {
            // get requestBillingSetup api status for button feedback
            return this.pending('requestBillingSetupIntent')
        },
        submitButtonStatus() {
            if (!this.selectedCard && !this.isCardDataValid)
                return 'disabled'

            if (this.loading || this.isSubmitPending)
                return 'loading'

            return 'default'
        }
    },
    mounted() {
        $('#modalPayInvoice').on('show.bs.modal', (e) => {
            this.$root.$emit('payModalReady')
        })
    },
    methods: {
        setSelectedCard(id) {
            this.selectedCard = id
        },
        setStripeCardElement(element) {
            this.stripeCardElement = element
        },
        setInsertedCardValidity(status) {
            this.isCardDataValid = status
        },
        async savedCardPayment() {
            const res = await store.dispatch('api/payInvoice', { number: this.invoiceNumber, paymentMethodId: this.selectedCard })

            switch (res.data.status) {
                case 'need_auth': {
                    // tried to pay, but we need a card authentication after all
                    const authRes = await this.stripeObject.confirmCardPayment(res.data.clientSecret, { payment_method: this.selectedCard })

                    if (authRes.error) {
                        // 3D secure challenge failed, will need to re-add the payment method
                        if (authRes.error.code === 'payment_intent_authentication_failure') {
                            throw {
                                type: 'auth_fail',
                                msg: 'Falha na autenticação. Tenta efetuar o pagamento de novo.'
                            }
                        }
                    }
                }
                break
                case 'error': {
                    switch (res.data.code) {
                        case 'card_declined':
                            throw {
                                type: 'payment_error',
                                msg: 'O teu cartão foi recusado. Tenta com um cartão diferente.'
                            }
                            break
                        default: {
                            throw {
                                type: 'payment_error',
                                msg: 'Falha no pagamento. Tenta efetuar o pagamento de novo.'
                            }
                        }
                    }
                }
                break
                // case 'success': // nothing to do
                // break
            }
        },
        async unsavedCardPayment() {
            // first we must ensure this card is not saved in the account
            // that would lead to an inconsistency: having a card saved but possibly no premium
            const resLocal = await store.dispatch('api/payInvoice', { action: 'no_attach', number: this.invoiceNumber, paymentMethodId: null })
            if (resLocal.data.status !== 'success')
                throw { type: 'payment_error', msg: 'Ocorreu um erro. Tenta de novo ou contacta a equipa de suporte.' }

            // send payment confirmation to stripe
            const res = await this.stripeObject.confirmCardPayment(this.clientSecret, {
                payment_method: { card: this.stripeCardElement },
                setup_future_usage: null // this doesn't seem to make a difference since we're using a payment intent which could have the parameter set
            })

            if (res.error) {
                // display error message
                throw { type: 'payment_error', msg: this.getErrorString(res.error) }
            } else {
                // The payment has been processed!
                if (res.paymentIntent.status !== 'succeeded') {
                    console.log('error')
                    console.log(result)
                }
            }
        },
        async attemptPay() {
            try {
                this.loading = true
                this.setParentUpdatingInvoice(this.invoiceNumber)

                if (!this.selectedCard)
                    await this.unsavedCardPayment()
                else
                    await this.savedCardPayment()

                // show notification
                await store.dispatch('notification/add', {
                    status: 'success',
                    category: 'card',
                    message: 'Pagamento efetuado com sucesso!'
                }, { root: true })

                // update invoice table to show paid invoice
                await store.dispatch('billing/setInvoiceStatus', { id: this.invoiceId, status: 'paid' }, { root: true })

                // close the modal
                $('#modalPayInvoice').modal('hide')

            } catch (err) {
                switch (err.type) {
                    case 'auth_fail':
                        this.uiError = err.msg
                        await store.dispatch('billing/setInvoiceStatus', { id: this.invoiceId, status: 'requires_payment_method' }, { root: true })
                        break
                    case 'payment_error':
                        this.uiError = err.msg
                        break
                    default:
                        this.uiError = 'Erro no pagamento.'
                        console.log(err)
                        break
                }
            } finally {
                this.setParentUpdatingInvoice(null)
                this.loading = false
            }
        }
    }
};
</script>

<style lang="css">
.card-body {
  max-height: 2000px !important;
}
</style>
