export default {
    data(){
        return {
            webSocket: null,
            webSocketConnected: false,
            webSocketPingTimeout: null,
            openedCallback: null,
            msgReceivedCallback: null,
            webSocketTimeout: 1000
        }
    },
    methods: {
        setupWebsocket(openedCallback, msgReceivedCallback) {
            if(this.webSocketConnected)
                return

            this.openedCallback = openedCallback
            this.msgReceivedCallback = msgReceivedCallback

            this.webSocket = new WebSocket('wss://lisboa.clica.ai')

            this.webSocket.onmessage = (event) => {
                const data = JSON.parse(event.data)
                if(data.pong)
                    return

                this.msgReceivedCallback(data)
            }

            this.webSocket.onopen = (event) => {
                // console.log('opened')
                this.webSocketConnected = true
                this.webSocketPingTimeout = setTimeout(this.pingWebSocket, this.webSocketTimeout)
                this.openedCallback()
            }

            this.webSocket.onclose = (event) => {
                // console.log('closed')

                // cleanup
                this.webSocketConnected = false
                clearTimeout(this.webSocketPingTimeout)
                this.webSocketPingTimeout = null
                this.webSocket = null

                // if(!event.wasClean) {
                //     this.webSocketPingTimeout = setTimeout(this.pingWebSocket, 1000)
                // }
            }

            this.webSocket.onerror = (error) => {
                console.log('err')
                console.log(error)

                this.webSocket.close()

                // cleanup
                this.webSocketConnected = false
                clearTimeout(this.webSocketPingTimeout)
                this.webSocketPingTimeout = null
                this.webSocket = null

                console.log('reconnecting')
                this.setupWebsocket(this.openedCallback, this.msgReceivedCallback)
            }
        },
        closeWebSocket() {
            this.webSocketConnected = false
            if (this.webSocket)
                this.webSocket.close()
        },
        pingWebSocket() {
            // console.log('.')
            if(!this.webSocketConnected) {
                console.log('reconnecting')
                this.setupWebsocket(this.openedCallback, this.msgReceivedCallback)
                return
            }

            this.webSocket.send(JSON.stringify({ping: true}))
            this.webSocketPingTimeout = setTimeout(this.pingWebSocket, this.webSocketTimeout)
            this.webSocketTimeout = this.webSocketTimeout > 15000? 15000: this.webSocketTimeout * 2
        },
        sendWebSocketMessage(msg) {
            if(this.webSocketConnected)
                this.webSocket.send(JSON.stringify(msg))
        }
    }
}
