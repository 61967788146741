import Vue from 'vue'
import Router from 'vue-router'

import Login from './views/authentication/Login.vue'
import Logout from './views/authentication/Logout.vue'
import Register from './views/authentication/Register.vue'
import VerifyUser from './views/authentication/VerifyUser.vue'
import PasswordRecoveryRequest from './views/authentication/PasswordRecoveryRequest.vue'
import PasswordRecovery from './views/authentication/PasswordRecovery.vue'

import AccountMain from './views/AccountMain.vue'
import AccountSettings from './views/AccountSettings.vue'
import AccountBilling from './views/AccountBilling.vue'

import AdminClicks from './views/admin/AdminClicks.vue'
import AdminHealth from './views/admin/AdminHealth.vue'
import AdminSlugs from './views/admin/AdminSlugs.vue'
import AdminUsers from './views/admin/AdminUsers.vue'
import AdminEmails from './views/admin/AdminEmails.vue'
import AdminSendEmail from './views/admin/AdminSendEmail.vue'
import SetSystemMessage from './views/admin/SetSystemMessage.vue'

import ContactUs from './views/ContactUs.vue'
import CreateSlug from './views/CreateSlug.vue'
import SingleUrl from './views/SingleUrl.vue'
import Invoice from './views/Invoice.vue'
import Pricing from './views/Pricing.vue'
import PageNotFound from './views/PageNotFound.vue'

import Home from './views/Home.vue'
import Dashboard from './views/Dashboard.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active', // this is to match with dashkit's class, see https://router.vuejs.org/api/#exact-active-class
    routes: [
        {
            path: "/",
            component: Home,
            beforeEnter: (to, from, next) => {
                next()
            },
            meta: { requiresAuth: true },
            children: [
                {
                    path: '',
                    name: 'home',
                    component: Dashboard
                },
                {
                    path: '/conta',
                    component: AccountMain,
                    children: [
                        {
                            path: '',
                            name: 'account-settings',
                            component: AccountSettings
                        },
                        {
                            path: 'pagamentos',
                            name: 'account-billing',
                            component: AccountBilling
                        }
                    ]
                },
                {
                    path: '/criar-miniatura',
                    name: 'create-slug',
                    component: CreateSlug,
                    props: (route) => ({
                        namespace: route.query.dominio,
                        slug: route.query.miniatura,
                        fromEmail: route.query.email
                    }),
                },
                {
                    path: '/miniatura/:slug',
                    name: 'single-url',
                    component: SingleUrl,
                    props: true
                },
                {
                    path: '/miniatura/:slug/fatura',
                    name: 'slug-invoice',
                    component: Invoice,
                    props: (route) => ({
                        slug: route.params.slug, // TODO should be just invoice num like above, and merge them eventually
                        type: 'slug'
                    })
                },
                {
                    path: 'fatura/:number',
                    name: 'billing-invoice',
                    component: Invoice,
                    props: (route) => ({
                        invoiceNumber: route.params.number,
                        type: 'billing'
                    })
                },
                {
                    path: '/contacta-nos',
                    name: 'contact-us',
                    component: ContactUs
                },
                {
                    path: '/precario',
                    name: 'pricing',
                    component: Pricing
                },
                {
                    path: '/admin-cliques',
                    name: 'admin-clicks',
                    component: AdminClicks,
                    meta: { adminOnly: true }
                },
                {
                    path: '/admin-estado',
                    name: 'admin-health',
                    component: AdminHealth,
                    meta: { adminOnly: true }
                },
                {
                    path: '/admin-miniaturas',
                    name: 'admin-slugs',
                    component: AdminSlugs,
                    meta: { adminOnly: true }
                },
                {
                    path: '/admin-utilizadores',
                    name: 'admin-users',
                    component: AdminUsers,
                    meta: { adminOnly: true }
                },
                {
                    path: '/admin-emails',
                    name: 'admin-emails',
                    component: AdminEmails,
                    meta: { adminOnly: true }
                },
                {
                    path: '/admin-enviar-email',
                    name: 'admin-send-email',
                    component: AdminSendEmail,
                    meta: { adminOnly: true }
                },
                {
                    path: '/admin-mensagem-sistema',
                    name: 'admin-system-message',
                    component: SetSystemMessage,
                    meta: { adminOnly: true }
                },
            ]
        },

        // authentication routes
        {
            path: "/entrar",
            name: "login",
            alias: "/login",
            component: Login,
            meta: { unauthenticated: true }
        },
        {
            path: "/sair",
            name: "logout",
            alias: "/logout",
            component: Logout,
            meta: { requiresAuth: true }
        },
        {
            path: "/registar-conta",
            name: "register",
            alias: "/registar",
            component: Register,
            meta: { unauthenticated: true }
        },
        {
            path: "/verificar-conta",
            name: "verify",
            alias: "/verificar",
            component: VerifyUser,
            props: (route) => ({
                token: route.query.token
            }),
            meta: { unauthenticated: true }
        },
        {
            path: "/pedir-recuperacao-palavra-passe",
            name: "recover-password-request",
            component: PasswordRecoveryRequest,
            meta: { unauthenticated: true }
        },
        {
            path: "/redefinir-palavra-passe",
            name: "recover-password",
            component: PasswordRecovery,
            props: (route) => ({
                user_id: route.query.user_id,
                resetToken: route.query.token
            }),
            meta: { unauthenticated: true }
        },

        // {
        //     path: '/estatísticas/:slug',
        //     name: 'statistics-show',
        //     component: StatisticsShow,
        //     props: true
        // },

        // catch all route
        {
            path: '*',
            name: '404',
            component: PageNotFound
        }
    ]
});
