import Vue from "vue"
import Vuex from "vuex"

import { URLService, setHeader } from '@/services/URLService'
import router from '@/router.js'

import * as adminData from '@/store/modules/adminData.js'
import * as billing from '@/store/modules/billing.js'
import * as dashboard from '@/store/modules/dashboard.js'
import * as statistics from '@/store/modules/statistics.js'
import * as api from '@/store/modules/api.js'
import * as notification from '@/store/modules/notification.js'
import * as stripe from '@/store/modules/stripe.js'
import * as createSlug from '@/store/modules/createSlug.js'
import * as shoppingCart from '@/store/modules/shoppingCart.js'

import { getStorage, setStorage, removeStorage } from '@/assets/localStorage.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        adminData,
        billing,
        dashboard,
        statistics,
        api,
        notification,
        stripe,
        createSlug,
        shoppingCart
    },
    state: {
        user: {
            token: null,
            type: null,
            hasTriedPremium: false,
            email: null
        }
    },
    mutations: {
        SET_USER_TOKEN(state, payload) {
            state.user.token = payload
            setStorage('user', 'token', payload)

            setHeader('Authorization', `Bearer ${payload}`)
        },
        SET_USER_TYPE(state, payload) {
            state.user.type = payload
            setStorage('user', 'type', payload)
        },
        SET_USER_HAS_TRIED_PREMIUM(state, payload) {
            state.user.hasTriedPremium = payload
            setStorage('user', 'hasTriedPremium', payload)
        },
        SET_USER_EMAIL(state, payload) {
            state.user.email = payload
            setStorage('user', 'email', payload)
        },
        UNSET_USER_DATA(state) {
            // invalidate sensitive state
            state.user = {
                token: null,
                type: null,
                hasTriedPremium: false
            }

            setHeader('Authorization', null)
            removeStorage('user')
        }
    },
    actions: {
        userLogin({ commit }, payload) {
            if (typeof payload.token !== 'undefined')
                commit('SET_USER_TOKEN', payload.token)

            if (typeof payload.type !== 'undefined')
                commit('SET_USER_TYPE', payload.type)

            if (typeof payload.hasTriedPremium !== 'undefined')
                commit('SET_USER_HAS_TRIED_PREMIUM', payload.hasTriedPremium)

            if (typeof payload.email !== 'undefined')
                commit('SET_USER_EMAIL', payload.email)
        },
        userLogout({ commit, state, dispatch }) {
            // invalidate JWT in backend
            dispatch('api/logout', { token: state.user.token })
                .then((res) => {
                    // clear local state
                    commit('UNSET_USER_DATA')

                    // redirect to login page
                    router.push({ name: 'login' })

                    // clear all state by refreshing
                    location.reload()
                })
                .catch((err) => {
                    // clear local state
                    commit('UNSET_USER_DATA')

                    // redirect to login page
                    router.push({ name: 'login' })

                    // clear all state by refreshing
                    location.reload()
                })
        }
    },
    getters: {
        loggedIn (state) {
            return !!state.user.token
        },
        userType (state) {
            return state.user.type
        }
    }
});
