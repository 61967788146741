<template>
<div class="card mt-4 animated fadeInDown">
    <div class="card-body m-n4">
        <div class="row p-2">
            <div class="col-auto">
                <h3 class="card-title">A tua miniatura clica.ai:</h3>
                <p class="mb-1">
                    <code>
                    <span>{{ namespace }}</span><span class="font-weight-bold">{{ slug }}</span>
                    </code>
                </p>
                <p class="small text-muted mb-0">
                    {{ destination }}
                </p>
            </div>
            <div class="col-auto align-self-center">
                <BaseButton id="copyButton" buttonExternalClasses="copy-btn btn-lg" :data-clipboard-text="namespace + slug" @click="showTooltip">
                    Copiar
                </BaseButton>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ClipboardJS from 'clipboard'

export default {
    props: {
        namespace: {
            // must end in '/' to concatenate with slug
            type: String,
            required: true
        },
        slug: {
            type: String,
            required: true
        },
        destination: {
            type: String,
            required: true
        }
    },
    mounted() {
        // activate clipboard.js
        new ClipboardJS('.copy-btn')

        // activate Bootstrap tooltips
        $('#copyButton').tooltip({
            title: 'Copiado!',
            placement: 'bottom',
            trigger: 'manual'
        })
    },
    methods : {
        showTooltip() {
            // show tooltip and hide after 3 seconds
            $('#copyButton').tooltip('show')

            setTimeout(() => {
                $('#copyButton').tooltip('hide')
            }, 3000)
        }
    }
};
</script>

<style scoped>
</style>
