<template>
<div class="modal fade" id="modalConfirmAdminDelete" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Apagar miniatura (administrador)
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                    Apagar a miniatura {{ namespace }}<b>{{ slug }}</b> (como ADMIN)?

                    <form class="mb-4" @submit.prevent="adminDeleteSlug">
                        <BaseButton type="submit" :status="buttonStatus">
                            Submeter
                        </BaseButton>

                        <router-link class="btn btn-block btn-link text-muted" :to="{ name: 'home' }">
                            Cancelar
                        </router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import store from '@/store/store'

export default {
    props: {
        namespace: {
            type: String
        },
        slug: {
            type: String
        }
    },
    computed: {
        ...mapGetters({
            pending: 'api/isPending'
        }),
        isAdminDeletePending() {
            // get api status for button feedback
            return this.pending('adminDeleteSlug')
        },
        buttonStatus() {
            return this.isAdminDeletePending ? 'loading' : 'default'
        }
    },
    methods: {
        adminDeleteSlug() {
            console.log(this.slug);

            store.dispatch('api/adminDeleteSlug', { slug: this.slug })
                .then((res) => {
                    $('#modalConfirmAdminDelete').modal('hide')
                })
                .catch((err) => {
                    console.log(err)
                    // TODO error page
                })
        }
    }
};
</script>

<style lang="css" scoped>
</style>
