<template>
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col">

                    <!-- Title -->
                    <h6 class="card-title text-uppercase text-muted mb-2">
                        {{ title }}
                    </h6>

                    <!-- Heading -->
                    <span class="h2 mb-0">
                        {{ headingText }}
                    </span>

                    <!-- Badge -->
                    <span v-if="badgeText" class="badge badge-soft-success mt-n1">
                        {{ badgeText }}
                    </span>

                </div>
                <div class="col-auto">
                    <!-- Icon -->
                    <span :class="'h2 fe ' + icon + ' text-muted mb-0'"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        headingText: {
            type: String,
            required: true
        },
        badgeText: {
            type: String
        },
        icon: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="css" scoped>
</style>
