<template>
<div class="list-group">
    <a :href="selectedCard === null? null: '#'" class="list-group-item list-group-item-action" :class="{ 'bg-light': selectedCard === null }" @click="selectCard(null)">
        <div class="row align-items-center">
            <div class="col-auto">
                <img class="img-fluid" :src="getCardImage(insertedCardBrand)" :alt="insertedCardBrand? capitaliseFirst(insertedCardBrand) : 'Operador do cartão desconhecido'" style="max-width: 38px;">
            </div>
            <div class="col ml-n2">
                <h4 class="text-body text-focus mb-1 name">
                    <CreditCardData
                        @card-validation="(status) => $emit('card-validation', status)"
                        @stripe-card-element="(element) => $emit('stripe-card-element', element)"
                        @card-brand="setInsertedCardBrand"
                        @focus="focusInsertCardElement"
                        label="Usar cartão apenas para esta transação"
                    />
                </h4>
            </div>
        </div>
    </a>
    <a :href="selectedCard === row.id? null: '#'" class="list-group-item list-group-item-action" :class="{ 'bg-light': selectedCard === row.id }" v-for="(row, rowIndex) in cards" @click="selectCard(row.id)">
        <div class="row align-items-center">
            <div class="col-auto">
                <img class="img-fluid" :src="getCardImage(row.brand)" :alt="capitaliseFirst(row.brand)" style="max-width: 38px;">
            </div>
            <div class="col ml-n2">
                <h4 class="text-body text-focus mb-1 name">
                    {{ getCardString(row.id) }}
                </h4>
                <p class="small" :class="{ 'text-muted': selectedCard !== row.id }">
                    Expira a {{ row.exp }}
                </p>
                <p class="small mb-1" :class="{ 'text-muted': selectedCard !== row.id }">
                    <span class="fe fe-clock"></span> Adicionado a {{ row.added_at }}
                </p>
            </div>
        </div>
    </a>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import CreditCardData from '@/components/billing/CreditCardData'

export default {
    props: {
        addCardOption: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CreditCardData
    },
    data () {
        return {
            insertedCardBrand: null,
            selectedCard: null
        }
    },
    computed: {
        ...mapState({
            cards: state => state.billing.cards
        })
    },
    created () {
        this.$root.$on('payModalReady', () => {
            if (this.addCardOption)
                return

            // select default card
            for (let i = 0; i < this.cards.length; i++) {
                if (this.cards[i].default) {
                    this.selectedCard = this.cards[i].id
                    this.$emit('card-selection', this.selectedCard)
                    return
                }
            }
        })
    },
    methods: {
        setInsertedCardBrand(brand) {
            this.insertedCardBrand = brand
        },
        getCardString(id) {
            for (let i = 0; i < this.cards.length; i++) {
                if(this.cards[i].id === id) {
                    if (this.cards[i].number)
                        return `${this.capitaliseFirst(this.cards[i].brand)} terminado em ${this.cards[i].number}`
                    else
                        return `${this.capitaliseFirst(this.cards[i].brand)} adicionado recentemente`
                }
            }
        },
        focusInsertCardElement() {
            this.selectCard(null)
        },
        selectCard(id) {
            this.selectedCard = id
            this.$emit('card-selection', id)
        }
    }
}
</script>

<style lang="css" scoped>
.list-group-item.active {
    /* background-color: #95aac9 !important;
    border-color: #95aac9 !important; */
}
</style>
