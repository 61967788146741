<template>
<div class="row">
    <div class="col-12">
        <label>{{ label }}</label>
    </div>

    <div class="col-12 col-md-7">
        <div class="form-group">
            <div class="form-control mb-3" ref="cardNumber" tabindex=1></div>
            <BaseValidation
                :messages="getValidationObjects().cardNumber"
            />
        </div>
    </div>

    <div class="col-12 col-md-3">
        <div class="form-group">
            <div class="form-control mb-3" ref="cardExpiry" tabindex=2></div>
            <BaseValidation
                :messages="getValidationObjects().cardExpiry"
            />
        </div>
    </div>

    <div class="col-12 col-md-2">
        <div class="form-group">
            <div class="form-control mb-3" ref="cardCvc" tabindex=3></div>
            <BaseValidation
                :messages="getValidationObjects().cardCvc"
            />
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/store'

export default {
    props: {
        label: {
            type: String,
            default: 'Cartão de Débito ou Crédito'
        }
    },
    data() {
        return {
            stripeCardNumber: null,
            stripeCardExpiry: null,
            stripeCardCVC: null,

            validCardNumber: false,
            validCardExpiry: false,
            validCardCvc: false
        }
    },
    computed: mapState({
        stripeObject: state => state.stripe.stripeObject
    }),
    created() {
        this.$root.$on('cleanupCardData', () => this.cleanup())
    },
    mounted() {
        const elementClasses = {
            complete: 'is-valid',
            focus: 'StripeElement--focus',
            invalid: 'is-invalid'
        }

        const baseStyle = {
            fontSize: '15px',
            lineHeight: '1.529',
            '::placeholder': {
                color: '#B1C2D9'
            }
        }

        const elements = this.stripeObject.elements()

        this.stripeCardNumber = elements.create('cardNumber', {
            classes: elementClasses,
            style: {
                base: baseStyle
            },
            placeholder: 'O meu cartão'
        })

        this.$emit('stripe-card-element', this.stripeCardNumber)

        this.stripeCardExpiry = elements.create('cardExpiry', {
            classes: elementClasses,
            style: {
                base: baseStyle
            },
            placeholder: 'MM / AA'
        })

        this.stripeCardCVC = elements.create('cardCvc', {
            classes: elementClasses,
            style: {
                base: baseStyle
            }
        })

        this.stripeCardNumber.mount(this.$refs.cardNumber)
        this.stripeCardExpiry.mount(this.$refs.cardExpiry)
        this.stripeCardCVC.mount(this.$refs.cardCvc)

        // focus stripe input instead of our container div (useful when tabbing from card number)
        this.stripeCardExpiry.on('ready', () => {
            const f = () => {
                this.stripeCardExpiry.focus()
            }
            this.$refs.cardExpiry.addEventListener('focus', f);
        })

        this.stripeCardCVC.on('ready', () => {
            const f = () => {
                this.stripeCardCVC.focus()
            }
            this.$refs.cardCvc.addEventListener('focus', f);
        })

        // detect changes in stripe components for validation purposes
        this.stripeCardNumber.on('change', (data) => {
            if (data.brand)
                this.$emit('card-brand', data.brand)

            if(data.error)
                this.validCardNumber = false

            if(data.complete)
                this.validCardNumber = true

            this.checkValidity()
        })

        this.stripeCardExpiry.on('change', (data) => {
            if(data.error)
                this.validCardExpiry = false

            if(data.complete)
                this.validCardExpiry = true

            this.checkValidity()
        })

        this.stripeCardCVC.on('change', (data) => {
            if(data.error)
                this.validCardCvc = false

            if(data.complete)
                this.validCardCvc = true

            this.checkValidity()
        })

        // detect focus on elements for callback purposes
        this.stripeCardNumber.on('focus', () => {
            this.$emit('focus')
        })

        this.stripeCardExpiry.on('focus', () => {
            this.$emit('focus')
        })

        this.stripeCardCVC.on('focus', () => {
            this.$emit('focus')
        })
    },
    beforeDestroy () {
        this.cleanup()
    },
    methods: {
        getValidationObjects() {
            return {
                cardNumber: [
                    { condition: !this.validCardNumber, message: 'Número inválido' }
                ],
                cardExpiry: [
                    { condition: !this.validCardExpiry, message: 'Data inválida' }
                ],
                cardCvc: [
                    { condition: !this.validCardCvc, message: 'Código inválido' }
                ]
            }
        },
        checkValidity () {
            this.$emit('card-validation', this.validCardNumber && this.validCardExpiry && this.validCardCvc)
        },
        cleanup () {
            this.stripeCardNumber.clear()
            this.stripeCardExpiry.clear()
            this.stripeCardCVC.clear()
        }
    }
}
</script>
