<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <!-- Title -->
                    <h4 class="card-header-title">
                        {{ title }}
                    </h4>
                </div>
                <!-- <div class="col-auto mr-n3">
                    <span class="text-muted">
                        Show affiliate:
                    </span>
                </div>
                <div class="col-auto">
                    <div class="custom-control custom-switch">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="cardToggle"
                            data-toggle="chart"
                            data-target="#ordersChartCustom"
                            data-add='{"data":{"datasets":[{"data":[15,10,20,12,7,0,8,16,18,16,10,22],"backgroundColor":"#d2ddec","label":"Affiliate"}]}}'
                        >
                        <label class="custom-control-label" for="cardToggle"></label>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="card-body">
            <!-- Chart -->
            <div class="chart">
                <canvas id="ordersChartCustom" class="chart-canvas"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import { addCallbacks } from '../../../assets/js/new-chart-callbacks.js'

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            required: true
        },
        dataLabel: {
            type: Array,
            required: true
        }
    },
    mounted() {
        const chart = document.getElementById('ordersChartCustom');

        if (typeof Chart === 'undefined' || !chart) {
            return
        }

        new Chart(chart, {
            type: 'bar',
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function(value) {
                                if ( !(value % 10) ) {
                                    return value
                                }
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function(item, data) {
                            const label = data.datasets[item.datasetIndex].label || ''
                            const yLabel = item.yLabel
                            let content = ''

                            if (data.datasets.length > 1) {
                                content += '<span class="popover-body-label mr-auto">' + label + '</span>';
                            }

                            const clickText = yLabel === 1 ? 'clique' : 'cliques'

                            content += '<span class="popover-body-value">' + yLabel + ' ' + clickText + '</span>';
                            return content
                        }
                    }
                }
            },
            data: {
              labels: this.dataLabel,
              datasets: [{
                label: 'Horas mais populares',
                data: this.data
              }]
            }
        })

        addCallbacks('ordersChartCustom')
    }
};
</script>

<style lang="css" scoped>
</style>
