<template>
<div class="main-content">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 col-xl-8">
                <div class="header mt-md-5">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    Vista de Administração
                                </h6>

                                <h1 class="header-title">
                                    Enviar email @ clica.ai
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Form -->
                <form class="mb-4" @submit.prevent="sendContactForm">
                    <div class="form-group">
                        <label for="subject">De (e-mail)</label>

                        <div class="input-group mb-3">
                            <BaseInput id="fromAddress" placeholder="Username remetente" v-model.trim="contact.fromAddress" type="text"
                                @blur="$v.contact.fromAddress.$touch()"
                                :dirty="$v.contact.fromAddress.$dirty"
                                :error="$v.contact.fromAddress.$error"
                            />
                            <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon2">@clica.ai</span>
                            </div>
                            <BaseValidation :messages="getValidationObjects().fromAddress" />
                        </div>

                    </div>

                    <div class="form-group">
                        <label for="subject">Para</label>
                        <BaseInput id="to" placeholder="E-mail destinatário" v-model.trim="contact.to" type="text"
                            @blur="$v.contact.to.$touch()"
                            :dirty="$v.contact.to.$dirty"
                            :error="$v.contact.to.$error"
                        />
                        <BaseValidation :messages="getValidationObjects().to" />
                    </div>

                    <hr class="mt-4 mb-5">

                    <div class="form-group">
                        <label for="subject">Assunto</label>
                        <BaseInput id="subject" placeholder="Assunto" v-model="contact.subject" type="text"
                            @blur="$v.contact.subject.$touch()"
                            :dirty="$v.contact.subject.$dirty"
                            :error="$v.contact.subject.$error"
                        />
                        <BaseValidation :messages="getValidationObjects().subject" />
                    </div>

                    <div class="form-group">
                        <label for="message">Mensagem</label>
                        <BaseTextArea id="message" placeholder="Escreve aqui a tua mensagem" :resizable="false" v-model="contact.body"
                            @blur="$v.contact.body.$touch()"
                            :dirty="$v.contact.body.$dirty"
                            :error="$v.contact.body.$error"
                        />
                        <BaseValidation :messages="getValidationObjects().body" />
                    </div>

                    <!-- Divider -->
                    <hr class="mt-5 mb-5">

                    <BaseButton type="submit" :status="buttonStatus">
                        Submeter
                    </BaseButton>

                    <router-link class="btn btn-block btn-link text-muted" :to="{ name: 'home' }">
                        Cancelar
                    </router-link>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            contact: this.getFreshMessageObject()
        }
    },
    computed: {
        ...mapGetters({
            pending: 'api/isPending'
        }),
        isMessagePending() {
            // get login api status for button feedback
            return this.pending('adminSendEmail')
        },
        buttonStatus() {
            return this.$v.$invalid ? 'disabled' : (this.isMessagePending ? 'loading' : 'default')
        }
    },
    methods: {
        getFreshMessageObject() {
            return {
                fromAddress: 'admin',
                to: '',
                subject: '',
                body: ''
            }
        },
        getValidationObjects() {
            return {
                fromAddress: [{
                    condition: !this.$v.contact.fromAddress.required,
                    message: 'Remetente obrigatório'
                }],
                to: [{
                    condition: !this.$v.contact.to.required,
                    message: 'Destinatário obrigatório'
                }],
                subject: [{
                    condition: !this.$v.contact.subject.required,
                    message: 'Assunto obrigatório'
                }],
                body: [{
                    condition: !this.$v.contact.body.required,
                    message: 'Conteúdo da mensagem obrigatório'
                }]
            }
        },
        async sendContactForm() {
            this.$v.$touch() // makes the form dirty to show errors
            if (this.$v.$pending || this.$v.$error || this.$v.$invalid)
                return

            try {
                const res = await this.$store.dispatch('api/adminSendEmail', {
                    fromAddress: this.contact.fromAddress,
                    to: this.contact.to,
                    subject: this.contact.subject,
                    body: this.contact.body,
                })

                this.contact = this.getFreshMessageObject()
                this.$v.$reset()
            } catch (err) {
                console.log('error from server...')
            } finally {

            }
        }
    },
    validations: {
        contact: {
            fromAddress: {
                required
            },
            to: {
                required
            },
            subject: {
                required
            },
            body: {
                required
            }
        }
    }
}
</script>

<style lang="css">
</style>
