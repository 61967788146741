<template>
    <div class="main-content">
        <template v-if="!loading">
            <div class="header">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-end">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    Vista de Administração
                                </h6>

                                <h1 class="header-title">
                                    Painel de Cliques
                                </h1>
                            </div>
                            <!-- <div class="col-auto">
                                    <a href="#!" class="btn btn-primary lift">
                                        Create Report
                                    </a>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <CardBarChart
                            title="Horas"
                            :dataLabel="['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h']"
                            :data="clickData.hours"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-xl-3">
                        <CardTable
                            title="Dispositivos"
                            :header="[{title: 'Dispositivo'}, {title: 'Número de cliques'}]"
                            :rowElements="['m', 'f']"
                            :rows="clickData.devices"
                        />
                    </div>
                    <div class="col-12 col-xl-3">
                        <CardTable
                            title="Navegadores"
                            :header="[{title: 'Navegador'}, {title: 'Número de cliques'}]"
                            :rowElements="['a', 'f']"
                            :rows="clickData.browsers"
                        />
                    </div>
                    <div class="col-12 col-xl-3">
                        <CardTable
                            title="Sistemas Operativos"
                            :header="[{title: 'Sistema'}, {title: 'Número de cliques'}]"
                            :rowElements="['a', 'f']"
                            :rows="clickData.os"
                        />
                    </div>
                    <div class="col-12 col-xl-3">
                        <CardTable
                            title="Localizações"
                            :header="[{title: 'Localidade'}, {title: 'Número de cliques'}]"
                            :rowElements="['name', 'f']"
                            :rows="clickData.locations"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CardMap from '@/components/cards/CardMap'
import CardTable from '@/components/cards/CardTable'
import CardList from '@/components/cards/CardList'
import CardPieChart from '@/components/cards/CardPieChart'
import CardBarChart from '@/components/cards/CardBarChart'
import CardSmallText from '@/components/cards/CardSmallText'
import CardSmallProgress from '@/components/cards/CardSmallProgress'
import CardSparklineChart from '@/components/cards/CardSparklineChart'
import HomeHeader from '@/components/HomeHeader'

import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'

export default {
    components: {
        CardTable,
        CardList,
        CardPieChart,
        CardBarChart,
        CardSmallText,
        CardSmallProgress,
        CardSparklineChart,
        CardMap
    },
    data () {
        return {
            loading: false
        }
    },
    created () {
        NProgress.start()
        this.loading = true

        store.dispatch('api/adminGetClickData')
            .then((res) => {
                const clickData = _.cloneDeep(res.data.body.click_data)

                // process hours
                const hours = clickData.hours
                for(let i = 0; i < hours.length; i++) {
                    // TODO SQL function sends it as array of objects with key 'f', should be changed at DB function
                    hours[i] = hours[i]['f']
                }


                store.dispatch('adminData/setAdminClickData', clickData, { root: true })
                    .then((res) => {
                        NProgress.done()
                        this.loading = false
                    })
            })
            .catch((err) => {
                console.log(err)
                // TODO error page
            })
    },
    computed: mapState({
        clickData: state => state.adminData.click
    })
};
</script>

<style scoped></style>
