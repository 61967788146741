<template>
<div class="modal fade" id="modalConfirmBlock" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Bloquear miniatura
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                    Bloquear a miniatura {{ namespace }}<b>{{ slug }}</b>?
                    <form class="mb-4" @submit.prevent="blockSlug">
                        <div class="form-group">
                            <label for="reason">Razão</label>
                            <BaseInput id="reason" placeholder="Razão" v-model.trim="block.reason" type="text"
                                @blur="$v.block.reason.$touch()"
                                :dirty="$v.block.reason.$dirty"
                                :error="$v.block.reason.$error"
                            />
                        </div>

                        <!-- Divider -->
                        <hr class="mt-5 mb-5">

                        <BaseButton type="submit" :status="buttonStatus">
                            Submeter
                        </BaseButton>

                        <router-link class="btn btn-block btn-link text-muted" :to="{ name: 'home' }">
                            Cancelar
                        </router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import store from '@/store/store'

export default {
    props: {
        namespace: {
            type: String
        },
        slug: {
            type: String
        }
    },
    computed: {
        ...mapGetters({
            pending: 'api/isPending'
        }),
        isBlockPending() {
            // get api status for button feedback
            return this.pending('blockSlug')
        },
        buttonStatus() {
            return this.$v.$invalid ? 'disabled' : (this.isBlockPending ? 'loading' : 'default')
        }
    },
    data() {
        return {
            block: this.getFreshBlockObject()
        }
    },
    methods: {
        getFreshBlockObject() {
            return {
                reason: null
            }
        },
        blockSlug() {
            console.log(this.slug);

            store.dispatch('api/blockSlug', { slug: this.slug, reason: this.block.reason })
                .then((res) => {
                    $('#modalConfirmBlock').modal('hide')
                    this.block = this.getFreshBlockObject()
                })
                .catch((err) => {
                    console.log(err)
                    // TODO error page
                })
        }
    },
    validations: {
        block: {
            reason: {
                //required
            }
        }
    }
};
</script>

<style lang="css" scoped>
</style>
