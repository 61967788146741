<template>
<div class="main-content">
    <div class="container-fluid" v-if="!loading">
        <Choose v-if="stage === 'choose'" :startingSlugTypeCustom="customSlugDefault" :parentNextStage="nextStage" />
        <Pay v-if="stage === 'pay'" :parentNextStage="nextStage" />
        <Success v-if="stage === 'success'" :parentResetCreateSlug="resetCreateSlug" />
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'


import Choose from '@/components/slugCreation/Choose'
import Pay from '@/components/slugCreation/Pay'
import Success from '@/components/slugCreation/Success'

export default {
    props: {
        customSlugDefault: {
            type: Boolean,
            default: false
        },
        namespace: {
            type: String,
            default: null
        },
        slug: {
            type: String,
            default: null
        },
        fromEmail: {
            type: String,
            default: '0'
        }
    },
    components: {
        Choose,
        Pay,
        Success
    },
    data () {
        return {
            stage: 'choose',
            loading: false
        }
    },
    computed: {
        fromEmailBoolean() {
            return this.fromEmail === '1'
        }
    },
    async created () {
        if (this.namespace && this.slug) {
            NProgress.start()
            this.loading = true

            try {
                const res = await store.dispatch('api/renewSlugPaymentData', { slug: this.slug, fromEmail: this.fromEmailBoolean })

                const paymentData = {
                    paymentIntentClientSecret: res.data.clientSecret,
                    serverSideCostEur: res.data.costEur,
                    serverSideCostEurOriginal: res.data.costEurOriginal,
                    timeLimit: Date.now() + 600000
                }

                await store.dispatch('createSlug/setPaymentData', paymentData)

                const slugData = {
                    destination: res.data.destination,
                    namespace: this.namespace + '/',
                    slug: this.slug
                }

                await store.dispatch('createSlug/setSlugData', slugData)

                this.stage = 'pay'
            } catch (err) {
                console.log(err)
            } finally {
                NProgress.done()
                this.loading = false
            }
        }
    },
    async beforeDestroy () {
        await store.dispatch('createSlug/clearSlugData')
        await store.dispatch('createSlug/clearPaymentData')
    },
    methods: {
        nextStage(stage) {
            this.stage = stage
        },
        cancelCreateSlug() {
            // Object.assign(this.$data, this.getFreshCreateSlugObject())
            this.$router.push({ name: 'home' })
        },
        async resetCreateSlug() {
            await store.dispatch('createSlug/clearSlugData')
            await store.dispatch('createSlug/clearPaymentData')

            this.stage = 'choose'
        }
    }
};
</script>

<style scoped>
</style>
