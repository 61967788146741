export default {
    data () {
        return {
            constants: {
                recaptchaKeyLogin: '6LdpLZ8UAAAAAPcDtN_Id1S1w1DPL1z3_BjXIvWc',
                recaptchaKeyCreateSlug: '6LdiVZ4UAAAAAJTYLaOXSUIJg3unfjHriXgPLgSL',
                recaptchaKeyRecoverPassword: '6Lcm6KAUAAAAAFAPeIGxy35El_bAdXsPzIspyvKz',
                recaptchaKeyRecoverPasswordRequest: '6Lc56KAUAAAAAJxNMuA5GB8XcK82lozqfU7ynkeJ',
                recaptchaKeyRegister: '6LcNiZ8UAAAAADXWtPHSNGu2NsB1tKDbbTZL_VIo',
                stripeClientTestKey: 'pk_test_SGzcHZykh3vmJr5KzjNxaVKL007KiVHDsO',
                stripeClientLiveKey: 'pk_live_QAUFMv6Ig4GgeaAFopn2QT7z00ZCYfTPCH',
                rowsPerUrlTable: 15
            }
        }
    }
}
