<template>
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">

            <!-- Title -->
            <h4 class="card-header-title">
              Projects
            </h4>

          </div>
          <div class="col-auto">

            <!-- Link -->
            <a href="#!" class="small">View all</a>

          </div>
        </div> <!-- / .row -->
      </div>
      <div class="card-body">

        <div class="row align-items-center">
          <div class="col-auto">

            <!-- Avatar -->
            <a href="project-overview.html" class="avatar avatar-4by3">
              <img src="/assets/img/avatars/projects/project-1.jpg" alt="..." class="avatar-img rounded">
            </a>

          </div>
          <div class="col ml-n2">

            <!-- Title -->
            <h4 class="card-title mb-1">
              <a href="project-overview.html">Homepage Redesign</a>
            </h4>

            <!-- Time -->
            <p class="card-text small text-muted">
              <time datetime="2018-05-24">Updated 5hr ago</time>
            </p>

          </div>
          <div class="col-auto">

            <!-- Dropdown -->
            <div class="dropdown">
              <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fe fe-more-vertical"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a href="#!" class="dropdown-item">
                  Action
                </a>
                <a href="#!" class="dropdown-item">
                  Another action
                </a>
                <a href="#!" class="dropdown-item">
                  Something else here
                </a>
              </div>
            </div>

          </div>
        </div> <!-- / .row -->

        <!-- Divider -->
        <hr>

        <div class="row align-items-center">
          <div class="col-auto">

            <!-- Avatar -->
            <a href="project-overview.html" class="avatar avatar-4by3">
              <img src="/assets/img/avatars/projects/project-2.jpg" alt="..." class="avatar-img rounded">
            </a>

          </div>
          <div class="col ml-n2">

            <!-- Title -->
            <h4 class="card-title mb-1">
              <a href="project-overview.html">Travels & Time</a>
            </h4>

            <!-- Time -->
            <p class="card-text small text-muted">
              <time datetime="2018-05-24">Updated 3hr ago</time>
            </p>

          </div>
          <div class="col-auto">

            <!-- Dropdown -->
            <div class="dropdown">
              <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fe fe-more-vertical"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a href="#!" class="dropdown-item">
                  Action
                </a>
                <a href="#!" class="dropdown-item">
                  Another action
                </a>
                <a href="#!" class="dropdown-item">
                  Something else here
                </a>
              </div>
            </div>

          </div>
        </div> <!-- / .row -->

        <!-- Divider -->
        <hr>

        <div class="row align-items-center">
          <div class="col-auto">

            <!-- Avatar -->
            <a href="project-overview.html" class="avatar avatar-4by3">
              <img src="/assets/img/avatars/projects/project-3.jpg" alt="..." class="avatar-img rounded">
            </a>

          </div>
          <div class="col ml-n2">

            <!-- Title -->
            <h4 class="card-title mb-1">
              <a href="project-overview.html">Safari Exploration</a>
            </h4>

            <!-- Time -->
            <p class="card-text small text-muted">
              <time datetime="2018-05-24">Updated 10hr ago</time>
            </p>

          </div>
          <div class="col-auto">

            <!-- Dropdown -->
            <div class="dropdown">
              <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fe fe-more-vertical"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a href="#!" class="dropdown-item">
                  Action
                </a>
                <a href="#!" class="dropdown-item">
                  Another action
                </a>
                <a href="#!" class="dropdown-item">
                  Something else here
                </a>
              </div>
            </div>

          </div>
        </div> <!-- / .row -->

        <!-- Divider -->
        <hr>

        <div class="row align-items-center">
          <div class="col-auto">

            <!-- Avatar -->
            <a href="project-overview.html" class="avatar avatar-4by3">
              <img src="/assets/img/avatars/projects/project-5.jpg" alt="..." class="avatar-img rounded">
            </a>

          </div>
          <div class="col ml-n2">

            <!-- Title -->
            <h4 class="card-title mb-1">
              <a href="project-overview.html">Personal Site</a>
            </h4>

            <!-- Time -->
            <p class="card-text small text-muted">
              <time datetime="2018-05-24">Updated 4hr ago</time>
            </p>

          </div>
          <div class="col-auto">

            <!-- Dropdown -->
            <div class="dropdown">
              <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fe fe-more-vertical"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a href="#!" class="dropdown-item">
                  Action
                </a>
                <a href="#!" class="dropdown-item">
                  Another action
                </a>
                <a href="#!" class="dropdown-item">
                  Something else here
                </a>
              </div>
            </div>

          </div>
        </div> <!-- / .row -->

      </div> <!-- / .card-body -->
    </div> <!-- / .card -->
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
</style>
