<template>
<div class="row">
    <div class="col">
        <nav aria-label="Barra de navegação da tabela">
            <ul class="pagination justify-content-center">
                <!-- Nav First -->
                <li :class="getNavBackClass">
                    <button class="page-link" aria-label="Anterior" @click="pageClickAction(0, 'first')">
                        <template v-if="firstLoading">
                            <!-- if button has been clicked show loading -->
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">A carregar...</span>
                        </template>
                        <template v-else>
                            <!-- show symbol -->
                            <span aria-hidden="true">&laquo;</span>
                        </template>
                    </button>
                </li>

                <!-- Nav Back -->
                <li :class="getNavBackClass">
                    <button class="page-link" aria-label="Anterior" @click="pageClickAction(currentPage-1, 'backward')">
                        <template v-if="backwardLoading">
                            <!-- if button has been clicked show loading -->
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">A carregar...</span>
                        </template>
                        <template v-else>
                            <!-- show symbol -->
                            <span aria-hidden="true">&langle;</span>
                        </template>
                    </button>
                </li>

                <!-- Left Ellipsis -->
                <li class="page-item disabled" v-if="getPageRange.length > 0 && getPageRange[0] > 1">
                    <button class="page-link">
                        <span aria-hidden="true">&hellip;</span>
                    </button>
                </li>

                <template v-for="page in getPageRange">
                    <li :class="getNavElementClass(page-1)">
                        <button class="page-link" @click="pageClickAction(page-1)">
                            <template v-if="!anyArrowLoading && nextPage === page - 1">
                                <!-- if button has been clicked show loading -->
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span class="sr-only">A carregar...</span>
                            </template>
                            <template v-else>
                                <!-- show page number -->
                                {{ page }}
                            </template>
                        </button>
                    </li>
                </template>

                <!-- Right Ellipsis -->
                <li class="page-item disabled" v-if="getPageRange.length > 0 && getPageRange[getPageRange.length - 1] < totalPages">
                    <button class="page-link">
                        <span aria-hidden="true">&hellip;</span>
                    </button>
                </li>

                <!-- Nav Forward -->
                <li :class="getNavForwardClass">
                    <button class="page-link" aria-label="Próximo" @click="pageClickAction(currentPage + 1, 'forward')">
                        <template v-if="forwardLoading">
                            <!-- if button has been clicked show loading -->
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">A carregar...</span>
                        </template>
                        <template v-else>
                            <!-- show symbol -->
                            <span aria-hidden="true">&rangle;</span>
                        </template>
                    </button>
                </li>

                <!-- Nav Last -->
                <li :class="getNavForwardClass">
                    <button class="page-link" aria-label="Próximo" @click="pageClickAction(totalPages - 1, 'last')">
                        <template v-if="lastLoading">
                            <!-- if button has been clicked show loading -->
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">A carregar...</span>
                        </template>
                        <template v-else>
                            <!-- show symbol -->
                            <span aria-hidden="true">&raquo;</span>
                        </template>
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</div>
</template>

<script>
import store from '@/store/store'
import { mapState } from 'vuex'

export default {
    props: {
        totalRows: {
            type: Number,
            required: true
        },
        callback: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            currentPage: 0,
            nextPage: -1, // page being loaded currently

            firstLoading: false,
            lastLoading: false,
            backwardLoading: false,
            forwardLoading: false
        }
    },
    methods: {
        getNavElementClass(element) {
            if(element === this.currentPage)
                return 'page-item active'
            return 'page-item'
        },
        getNavButtonClass(element) {
            if(element === this.currentPage)
                return 'page-link disabled'
            return 'page-link'
        },
        async pageClickAction(clickedPage, arrow = '') {
            this.nextPage = clickedPage

            // set arrow loader, if applicable
            switch (arrow) {
                case 'first':
                    this.firstLoading = true
                    break
                case 'last':
                    this.lastLoading = true
                    break
                case 'forward':
                    this.forwardLoading = true
                    break
                case 'backward':
                    this.backwardLoading = true
                    break
            }

            try {
                await this.callback(clickedPage * this.constants.rowsPerUrlTable, clickedPage)
                this.currentPage = clickedPage
            } catch (err) {
                throw err
            } finally {
                this.nextPage = -1

                // unset arrow loaders
                this.firstLoading = false
                this.lastLoading = false
                this.backwardLoading = false
                this.forwardLoading = false
            }
        },
        resetPage() {
            this.currentPage = 0
        },
        findBootstrapEnvironment () {
            let envs = ['xs', 'sm', 'md', 'lg', 'xl'];

            let el = document.createElement('div');
            document.body.appendChild(el);

            let curEnv = envs.shift();

            for (let env of envs.reverse()) {
                el.classList.add(`d-${env}-none`);

                if (window.getComputedStyle(el).display === 'none') {
                    curEnv = env;
                    break;
                }
            }

            document.body.removeChild(el);
            return curEnv;
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalRows / this.constants.rowsPerUrlTable)
        },
        getPageRange() {
            let pages = 10

            switch (this.findBootstrapEnvironment()) {
                case 'xs':
                case 'sm':
                    pages = 5
                break
                case 'md':
                case 'lg':
                    pages = 10
                break
                case 'xl':
                    pages = 15
                break
            }

            let pagesToShow = Math.min(pages, this.totalPages)

            const leftAvailable = this.currentPage
            const rightAvailable = this.totalPages - (this.currentPage + 1)

            let left = 0
            let right = 0

            while (pagesToShow > 1) { // stays at 1, which represents the "current page"
                if (leftAvailable - left > 0) {
                    left++
                    pagesToShow--
                }

                if (pagesToShow < 1)
                    break

                if (rightAvailable - right > 0) {
                    right++
                    pagesToShow--
                }
            }

            // create the range
            let r = []
            for (let i = this.currentPage - left + 1; i < this.currentPage + right + 2; i++)
                r.push(i)

            return r
        },
        getNavBackClass() {
            if(this.currentPage === 0)
                return 'page-item disabled'
            return 'page-item'
        },
        getNavForwardClass() {
            if(this.currentPage === this.totalPages - 1)
                return 'page-item disabled'
            return 'page-item'
        },
        anyArrowLoading() {
            return this.firstLoading  || this.lastLoading || this.backwardLoading || this.forwardLoading
        }
    }
};
</script>
