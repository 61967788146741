// Vue stuff
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";

// global plugins
import Vuelidate from 'vuelidate'
import {InlineSvgPlugin} from 'vue-inline-svg'

// global components
import NavigationSidebarFree from '@/components/NavigationSidebarFree'
import NavigationSidebarPremium from '@/components/NavigationSidebarPremium'
import NavigationSidebarAdmin from '@/components/NavigationSidebarAdmin'

// mixins
import Constants from '@/mixins/constants'
import Human from '@/mixins/human'
import Text from '@/mixins/text'
import Billing from '@/mixins/billing'
import Util from '@/mixins/util'

// misc
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { getStorage, setStorage, hasStorage } from '@/assets/localStorage.js'

Vue.config.productionTip = false

// global component registration
const requireComponent = require.context(
    './components',
    true,
    /(Base|Modal)[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
    )

    Vue.component(componentName, componentConfig.default || componentConfig)
})

// other global components
Vue.component('NavigationSidebarFree', NavigationSidebarFree)
Vue.component('NavigationSidebarPremium', NavigationSidebarPremium)
Vue.component('NavigationSidebarAdmin', NavigationSidebarAdmin)

// register global plugins
Vue.use(Vuelidate)
Vue.use(InlineSvgPlugin)

// global mixins
Vue.mixin(Constants)
Vue.mixin(Human)
Vue.mixin(Text)
Vue.mixin(Billing)
Vue.mixin(Util)

// global route guards
router.beforeEach((to, from, next) => {
    const loggedIn = hasStorage('user')

    // if not logged in, redirect to login page
    if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
        next({ name: 'login' })
    }

    // if logged in, redirect these routes to dashboard
    if (to.matched.some(record => record.meta.unauthenticated) && loggedIn) {
        next({ name: 'home' })
    }

    // if not an admin, redirect these routes to dashboard
    const userType = getStorage('user', 'type')
    if (to.matched.some(record => record.meta.adminOnly) && userType !== 'admin') {
        next({ name: 'home' })
    }

    // other routes just proceed normally
    next()
})

// init Vue
new Vue({
    router,
    store,
    created() {
        // restore user credentials from storage, if existing
        if (hasStorage('user')) {
            this.$store.dispatch('userLogin', getStorage('user'))
        }
    },
    render: h => h(App)
}).$mount("#app")
