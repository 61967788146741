<template>
    <div class="about">
        <h1>This is the logout page</h1>
    </div>
</template>

<script>
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'

export default {
    computed: {
        ...authComputed
    },
    beforeRouteEnter(routeTo, routeFrom, next) {
        /*store.dispatch('notification/add', {
            status: 'success',
            category: 'logout',
            message: 'Saiu com sucesso'
        })*/

        // clear local state
        store.dispatch('userLogout')

        // route does not resolve, not needed since we redirect in the store
        // next({ name: 'login' })
    }
}
</script>
