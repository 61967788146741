<template>
<div class="main-content">
    <ModalClientBillingData ref="billingDataModal"/>
    <ModalClientBillingCard ref="billingCardModal" :parentRefreshContents="refreshContents"/>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="alert alert-info" role="alert" v-if="!loading && cards.length === 0">
                    Para começares a usar o serviço <i>clica.ai premium</i> adiciona um cartão de débito ou crédito! Consulta <router-link :to="{ name: 'pricing' }">aqui</router-link> o preçário!
                </div>

                <div class="row">
                    <div class="col-12" :class="{ 'col-md-6': premiumData.active }">
                        <div class="card">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="text-uppercase text-muted mb-2">
                                            Estado da Subscrição
                                        </h6>

                                        <div class="spinner-border" role="status" style="color:#2c7be5;" v-if="loading">
                                            <span class="sr-only">A carregar...</span>
                                        </div>

                                        <span class="h2 mb-0" v-else>
                                            {{ subscriptionStatus }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div v-if="premiumData.active" class="col-12 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h6 class="text-uppercase text-muted mb-2">
                                                Uso estimado (ciclo atual) <i class="fe fe-info" id="sub-tooltip"></i>
                                            </h6>

                                            <div class="spinner-border" role="status" style="color:#2c7be5;" v-if="loading">
                                                <span class="sr-only">A carregar...</span>
                                            </div>

                                            <span class="h2 mb-0" v-else>
                                                {{ getHumanPrice(premiumData.total) }}
                                            </span>
                                    </div>
                                    <div class="col-auto">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h4 class="card-header-title">
                                    Dados de faturação
                                </h4>
                            </div>
                            <div class="col-auto">
                                <button @click="openBillingDataModal" class="btn btn-sm btn-primary" :disabled="loading">
                                    Editar dados de faturação
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-lg list-group-flush list my-n4">
                            <!-- Show spinner while loading -->
                            <li class="list-group-item px-0" v-if="loading">
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status" style="color:#2c7be5;">
                                        <span class="sr-only">A carregar...</span>
                                    </div>
                                </div>
                            </li>
                            <!-- Show billing data from backend -->
                            <li class="list-group-item px-0" v-else>
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <span class="avatar avatar-lg">
                                            <span class="avatar-title rounded bg-white text-secondary">
                                                <span class="fe fe-home"></span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col ml-n2">
                                        <h4 class="card-title mb-1 name">
                                            {{ billingData.name ? billingData.name : 'Cliente Final' }}
                                        </h4>
                                        <p class="card-text small text-muted mb-1" v-if="billingData.address">
                                            {{ billingData.address }}
                                        </p>
                                        <p class="card-text small text-muted mb-1" v-if="billingData.postalCode || billingData.town">
                                            {{ billingData.postalCode }} {{ billingData.town }}
                                        </p>
                                        <p class="card-text small text-muted" v-if="billingData.country">
                                            {{ billingData.country }}
                                        </p>
                                        <p class="card-text small text-muted mb-1" v-if="billingData.phone">
                                            <b>Contacto:</b> {{ billingData.phone }}
                                        </p>
                                        <p class="card-text small text-muted" v-if="billingData.vatNumber">
                                            <b>NIF:</b> {{ billingData.vatNumber }}
                                        </p>
                                    </div>
                                    <div class="col-auto" v-if="updatingBillingData">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">A carregar...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto" v-else>
                                        <div class="dropdown">
                                            <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fe fe-more-vertical"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <button @click="clearBillingData" class="dropdown-item">
                                                    Apagar dados de faturação
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h4 class="card-header-title">
                                    Cartões
                                </h4>
                            </div>
                            <div class="col-auto">
                                <button @click="openBillingCardModal" class="btn btn-sm btn-primary" :disabled="loading">
                                    Adicionar Cartão
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-lg list-group-flush list my-n4">
                            <!-- Show spinner while loading -->
                            <li class="list-group-item px-0" v-if="loading">
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status" style="color:#2c7be5;">
                                        <span class="sr-only">A carregar...</span>
                                    </div>
                                </div>
                            </li>

                            <!-- Show card data from backend -->
                            <template v-else>
                                <li class="list-group-item px-0" v-for="card in cards">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <div class="avatar avatar-lg">
                                                <span class="avatar-title bg-white">
                                                    <img class="img-fluid" :src="getCardImage(card.brand)" :alt="capitaliseFirst(card.brand)">
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col ml-n2">
                                            <h4 class="card-title mb-1 name">
                                                Cartão {{ capitaliseFirst(card.brand) }} <span v-if="card.number">(&bull;&bull;&bull;&bull; {{ card.number }})</span>
                                            </h4>
                                            <p class="card-text small text-muted" v-if="card.exp">
                                                Expira a {{ card.exp }}
                                            </p>
                                            <p class="card-text small text-muted mb-1">
                                                Adicionado a <time datetime="">{{ card.added_at }}</time>
                                            </p>
                                        </div>
                                        <!--  Show loading if this card is being deleted -->
                                        <div class="col-auto" v-if="updatingCard === card.id">
                                            <div class="d-flex justify-content-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">A carregar...</span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Show card menu otherwise -->
                                        <div class="col-auto" v-if="updatingCard !== card.id">
                                            <a v-if="!card.default" @click="setDefaultCard(card.id)" style="cursor: pointer;" class="btn btn-sm btn-outline-light btn-white d-none d-md-inline-block">
                                                Tornar predefinido
                                            </a>
                                            <a v-else role="button" style="cursor: pointer;" class="btn btn-sm btn-outline-success disabled d-none d-md-inline-block">
                                                Predefinido
                                            </a>
                                        </div>

                                        <div class="col-auto"  v-if="updatingCard !== card.id">
                                            <div class="dropdown">
                                                <a href="#" class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fe fe-more-vertical"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <button @click="deleteCard(card.id)" class="dropdown-item">
                                                        Apagar Cartão
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </template>

                            <!-- Show call to action when user has no cards -->
                            <li class="list-group-item px-0" v-if="!loading && cards.length === 0">
                                <div class="row align-items-center">
                                    <div class="col ml-n2">
                                        <h4 class="card-title mb-1 text-center">
                                            <a href="#" @click="openBillingCardModal">Ainda não tens cartões adicionados. Adiciona um para usufruir do <i>clica.ai premium</i>!</a>
                                        </h4>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="card-header-title">
                    Faturas
                </h4>
            </div>
            <InvoiceTable :loading="loading" />
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/store'
import { mapState, mapGetters } from 'vuex'
import { authComputed } from '@/store/helpers.js'

import InvoiceTable from '@/components/billing/InvoiceTable'

export default {
    components: {
        InvoiceTable
    },
    data() {
        return {
            loading: false,
            updatingCard: null,
            updatingBillingData: false
        }
    },
    async mounted() {
        await this.refreshContents(true)
        $('#sub-tooltip').tooltip({
            title: `O ciclo atual acaba a ${this.epochToHumanDate(this.premiumData.cycleEnd)}`,
            placement: 'top',
            trigger: 'click hover focus'
        })
    },
    computed: {
        subscriptionStatus() {
            return this.premiumData.active && this.cards && this.cards.length > 0 ? 'Ativa' : 'Inativa'
        },
        ...mapState({
            stripeObject: state => state.stripe.stripeObject,
            billingData: state => state.billing.billingData,
            cards: state => state.billing.cards,
            premiumData: state => state.billing.premiumData
        })
    },
    methods: {
        async refreshContents(useCache) {
            NProgress.start()
            this.loading = true

            try {
                // request customer data, if existing
                const res = await store.dispatch('api/fetchCustomerData', useCache)
                const customerData = _.cloneDeep(res.data.body)

                // save data in store
                await store.dispatch('billing/setBillingData', customerData.billingData, { root: true })
                await store.dispatch('billing/setCards', customerData.cards, { root: true })
                await store.dispatch('billing/setInvoices', customerData.premiumInvoices, { root: true })
                await store.dispatch('billing/setPremiumData', customerData.premiumData, { root: true })

                NProgress.done()
                this.loading = false
            } catch (err) {
                NProgress.done()
                this.loading = false

                if (err.response && err.response.status === 405) {

                } else {
                    console.log(err)
                    // TODO error page
                }
            }
        },
        openBillingDataModal() {
            const element = this.$refs.billingDataModal

            // if we have billing data then fill the modal for editing (instead of creating a new customer)
            if(this.billingData)
                element.setBillingData(this.billingData)

            $(element.$el).modal('show')
        },

        openBillingCardModal() {
            const element = this.$refs.billingCardModal.$el
            $(element).modal('show')
        },

        async setDefaultCard(cardId) {
            this.updatingCard = cardId

            try {
                const res = await store.dispatch('api/setBillingCardDefault', cardId)

                // update default in UI
                await store.dispatch('billing/setDefaultCard', cardId, { root: true })
                this.updatingCard = null
            } catch (err) {
                this.updatingCard = null
                console.log(err)
            }
        },
        deleteCardSetLoading (cardId) {
            this.updatingCard = cardId
        },
        async deleteCard (cardId) {
            const cardData = this.cards.filter((c) => { if (c.id === cardId) return c })[0]
            this.$root.$emit('openBillingConfirmRemoveCardModal', this.deleteCardSetLoading, this.cards.length < 2, cardData)
        },

        deleteBillingDataSetLoading (status) {
            this.updatingBillingData = status
        },
        async clearBillingData () {
            this.$root.$emit('openBillingConfirmRemoveAddressModal', this.deleteBillingDataSetLoading)
        }
    },
};
</script>

<style scoped>
</style>
