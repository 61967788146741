<template>
<div class="main-content">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 col-xl-8">
                <!-- Header -->
                <div class="header mt-md-5">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    Vista Geral
                                </h6>

                                <h1 class="header-title">
                                    A Minha Conta
                                </h1>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col">
                                <!-- Nav -->
                                <ul class="nav nav-tabs nav-overflow header-tabs">
                                    <li class="nav-item">
                                        <router-link class="nav-link" :to="{ name: 'account-settings' }">
                                            Definições
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link class="nav-link" :to="{ name: 'account-billing' }">
                                            Pagamentos
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <router-view></router-view>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {};
</script>
