<template>
<div class="card mt-4 card-border border-danger animated fadeInDown">
    <div class="card-body m-n4">
        <div class="row p-2">
            <div class="col-12 mr-auto">
                <p class="mb-1">
                    Não conseguimos minificar o link (<i>{{ destination }}</i>): <span class="font-weight-bold">{{ error }}</span>
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        error: {
            type: String,
            required: true
        },
        destination: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped>
</style>
