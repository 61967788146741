<template>
<div class="modal fade" id="modalSlugCreated" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Miniatura criada com sucesso!
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row p-2">
                        <div class="col-8">
                            <h3 class="card-title">A tua miniatura clica.ai:</h3>
                            <p class="mb-1">
                                <code>
                                <span>{{ namespace }}</span><span class="font-weight-bold">{{ slug }}</span>
                                </code>
                            </p>
                            <p class="small text-muted mb-0">
                                {{ fullUrl }}
                            </p>
                        </div>
                        <div class="col-4 align-self-center">
                            <BaseButton id="copyButtonSlugModal" buttonExternalClasses="copy-btn btn-lg" :data-clipboard-text="namespace + slug" @click="showTooltip">
                                Copiar
                            </BaseButton>
                        </div>
                    </div>

                    <hr/>

                    <span class="btn btn-lg btn-block btn-primary" style="cursor: pointer;" data-dismiss="modal">
                        Criar outra miniatura
                    </span>

                    <router-link class="btn btn-block btn-link text-muted" style="cursor: pointer;" data-dismiss="modal" :to="{ name: 'home', params: { useCache: false } }">
                        Ir para o meu painel
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ClipboardJS from 'clipboard'

export default {
    props: {
        fullUrl: {
            type: String
        },
        namespace: {
            type: String
        },
        slug: {
            type: String
        }
    },
    mounted() {
        // activate clipboard.js
        //$('#modalSlugCreated').on('shown.bs.modal', () => {
        //})
        const clipboard = new ClipboardJS('#copyButtonSlugModal', {
            container: document.getElementById('modalSlugCreated')
        })

        // activate Bootstrap tooltips
        $('#copyButtonSlugModal').tooltip({
            title: 'Copiado!',
            placement: 'bottom',
            trigger: 'manual'
        })
    },
    methods: {
        closeModal() {
            $('#modalSlugCreated').modal('hide')
        },
        showTooltip() {
            // show tooltip and hide after 3 seconds
            $('#copyButtonSlugModal').tooltip('show')

            setTimeout(() => {
                $('#copyButtonSlugModal').tooltip('hide')
            }, 3000)
        }
    }
};
</script>

<style lang="css" scoped>
</style>
