<template>
<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
    <div class="container-fluid">
        <!-- Toggler -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Brand -->
        <router-link class="navbar-brand" :to="{ name: 'home' }">
            <inline-svg src="/assets/img/logo.svg" class="navbar-brand-img mx-auto" alt="Clica.ai"></inline-svg>
        </router-link>

        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidebarCollapse">
            <!-- Navigation -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'home' }">
                        <i class="fe fe-home"></i> O meu painel
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'create-slug' }">
                        <i class="fe fe-link"></i> Criar Miniatura
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'account-settings' }">
                        <i class="fe fe-user"></i> A minha conta
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'account-billing' }">
                        <i class="fe fe-award"></i> Subscrição Premium <span class="badge badge-primary ml-auto">Novo!</span>
                    </router-link>
                </li>
            </ul>

            <!-- Divider -->
            <hr class="navbar-divider my-3">

            <!-- Navigation -->
            <ul class="navbar-nav mb-md-4">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'contact-us' }">
                        <i class="fe fe-mail"></i> Contacta-nos
                    </router-link>
                </li>

                <li class="nav-item">
                    <template v-if="isLogoutPending">
                        <span class="nav-link text-muted" style="cursor: default;">
                            <i class="fe fe-log-out"></i> Sair
                            &nbsp;&nbsp;
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">A sair...</span>
                            </div>
                        </span>
                    </template>
                    <template v-else>
                        <router-link class="nav-link" :to="{ name: 'logout' }">
                            <i class="fe fe-log-out"></i> Sair
                        </router-link>
                    </template>
                </li>
            </ul>
        </div> <!-- / .navbar-collapse -->
    </div>
</nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({ pending: 'api/isPending' }),
        isLogoutPending() {
            return this.pending('logout')
        },
    },
}
</script>

<style lang="css" scoped>
</style>
