<template>
    <select class="custom-select" @change="currencyChanged">
        <option value="PT" :selected="currentCountry === 'PT'">Euros</option>
        <option value="BR" :selected="currentCountry === 'BR'">Reais</option>
    </select>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/store'

export default {
    props: {
        currentCountry: {
            type: String,
            required: true
        },
        currencyChanged: {
            type: Function,
            required: true
        }
    }
}
</script>
