<template>
<div class="header bg-dark pb-5">
    <div class="container-fluid">
        <!-- Body -->
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <!-- Pretitle -->
                    <h6 class="header-pretitle text-secondary">
                        Visão Geral
                    </h6>

                    <!-- Title -->
                    <h1 class="header-title text-white">
                        {{ dataset[active].textLong }}
                    </h1>
                </div>
                <div class="col-auto">
                    <!-- Nav -->
                    <ul class="nav nav-tabs header-tabs">
                        <template v-for="(data, index) in dataset">
                            <li class="nav-item" @click="setActive(index)">
                                <a style="cursor: pointer;" class="nav-link text-center" :class="{ 'active': index === active }">
                                    <h6 class="header-pretitle text-secondary">
                                        {{ data.textShort }}
                                    </h6>
                                    <h3 class="text-white mb-0">
                                        {{ data.labelNum }}
                                    </h3>
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <div class="header-footer">
            <div class="chart">
                <canvas id="headerChartCustom" class="chart-canvas"></canvas>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { addCallbacks } from '../../assets/js/new-chart-callbacks.js'

export default {
    props: {
        dataset: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            chart: null,
            active: 0
        }
    },
    mounted() {
        const chart = document.getElementById('headerChartCustom')

        if (typeof Chart === 'undefined' || !chart) {
            return
        }

        this.chart = new Chart(chart, {
            type: 'line',
            options: {
                scales: {
                    yAxes: [{
                        gridLines: {
                            color: '#283E59',
                            zeroLineColor: '#283E59'
                        },
                        ticks: {
                            callback: function(value) {
                                if (!(value % 5)) {
                                    return value + ' cliques';
                                }
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function(item, data) {
                            const label = data.datasets[item.datasetIndex].label || ''
                            const yLabel = item.yLabel
                            let content = ''

                            if (data.datasets.length > 1) {
                                content += '<span class="popover-body-label mr-auto">' + label + '</span>'
                            }

                            const clickText = yLabel === 1 ? 'clique' : 'cliques'

                            content += '<span class="popover-body-value">' + yLabel + ' ' + clickText + '</span>'
                            return content
                        }
                    }
                }
            }
        });

        addCallbacks('headerChartCustom', true) // TODO phase out this, ideally - I think y-axis and data is now independent, but legends and "change" events probably are not
        this.setActive(0)
    },
    methods: {
        setActive(index) {
            this.active = index
            this.updateData()
        },
        updateData() {
            const dataset = this.dataset[this.active]

            // cleanup previous labels and data
            while(this.chart.data.labels.length > 0)
                this.chart.data.labels.pop()

            while(this.chart.data.datasets.length > 0)
                this.chart.data.datasets.pop()

            this.chart.data.datasets.push({data: []})

            // add new data
            for (let i = 0; i < dataset.labels.length; i++) {
                this.chart.data.labels.push(dataset.labels[i])
                this.chart.data.datasets[0].data.push(dataset.data[i])
            }

            this.chart.update()
        },
        createDataObject(obj) {
            // console.log(this.dataset);
            const copy = Object.assign({}, obj)
            return JSON.stringify({
                data: {
                    labels: copy.labels,
                    datasets: [{
                        // label: obj.textLong,
                        data: copy.data
                    }]
                }
            })
        }
    }
};
</script>

<style lang="css" scoped>
</style>
