<template>
    <textarea :value="value" @input="updateValue" :rows="resizable? 1:rows" v-on="listeners" v-bind="$attrs" class="form-control" :class="computedClasses" ref="textarea"></textarea>
</template>

<script>
import autosize from 'autosize'

export default {
    inheritAttrs: false,
    props: {
        value: [String, Number],
        additionalClasses: {
            type: String,
            default: ''
        },
        dirty: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        resizable: {
            type: Boolean,
            default: true
        },
        rows: {
            type: Number,
            default: 5
        }
    },
    computed: {
        listeners() {
            return { // vue mastery S04E11, conflict between listeners
                ...this.$listeners,
                input: this.updateValue
            }
        },
        computedClasses() {
            let classes = this.additionalClasses

            if (this.dirty)
                return this.error ? classes + ' is-invalid' : classes + ' is-valid'
            else
                return classes
        }
    },
    mounted() {
        $(() => {
            if (this.resizable)
                this.resize()
        })
    },
    methods: {
        resize () {
            autosize(this.$refs.textarea)
        },
        updateValue (event) {
            this.$emit('input', event.target.value)
            if (this.resizable)
                this.resize()
        }
    }
}
</script>
<style scoped>
    textarea {
        resize: none;
    }
</style>
