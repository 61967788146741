<template>
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <!-- Title -->
            <h4 class="card-header-title">
              {{ title }}
            </h4>
          </div>
          <!-- <div class="col-auto">
            <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
              <li class="nav-item" data-toggle="chart" :data-target="chartIdTarget" data-update='{"data":{"datasets":[{"data":[60,25,15]}]}}'>
                <a href="#" class="nav-link active" data-toggle="tab">
                  All
                </a>
              </li>
              <li class="nav-item" data-toggle="chart" :data-target="chartIdTarget" data-update='{"data":{"datasets":[{"data":[15,45,20]}]}}'>
                <a href="#" class="nav-link" data-toggle="tab">
                  Direct
                </a>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="card-body">
        <!-- Chart -->
        <div class="chart chart-appended">
            <canvas :id="chartId" class="chart-canvas" data-toggle="legend" :data-target="legendIdTarget"></canvas>
        </div>
        <!-- Legend -->
        <div :id="legendId" class="chart-legend"></div>
      </div>
    </div>
</template>

<script>
import { addCallbacks } from '../../../assets/js/new-chart-callbacks.js'

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            required: true
        },
        dataLabel: {
            type: Array,
            required: true
        }
    },
    computed: {
        chartId() {
            return this.id
        },
        chartIdTarget() {
            return '#' + this.id
        },
        legendId() {
            return this.id + 'Legend'
        },
        legendIdTarget() {
            return '#' + this.legendId
        }
    },
    mounted() {
        const chart = document.getElementById(this.chartId)

        new Chart(chart, {
            type: 'doughnut',
            options: {
                tooltips: {
                    callbacks: {
                        title: function(item, data) {
                            const title = data.labels[item[0].index]
                            return title
                        },
                        label: function(item, data) {
                            const value = data.datasets[0].data[item.index]
                            let content = ''

                            content += '<span class="popover-body-value">' + value + '%</span>'
                            return content
                        }
                    }
                }
            },
            data: {
                labels: this.dataLabel,
                datasets: [{
                    data: this.data,
                    backgroundColor: [
                      '#2C7BE5',
                      '#A6C5F7',
                      '#D2DDEC',
                      '#0749A5',
                      '#7CACEF',
                      '#D7D9D7' // TODO make the last colour always apply to "Other", might need to redo some js around...
                    ]
                }]
            }
        })

        addCallbacks(this.chartId)
        addCallbacks(this.legendId)
    }
};
</script>

<style lang="css" scoped>
</style>
