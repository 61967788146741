<template>
<div class="modal fade" id="modalChangeDestination" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Mudar destino
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                    Mudar o destino da miniatura {{ namespace }}<b>{{ slug }}</b>?
                    <form class="mb-4" @submit.prevent="changeSlugDestination">
                        <div class="form-group">
                            <label for="reason">Novo destino</label>
                            <BaseInput id="reason" placeholder="Novo destino" v-model.trim="newDestination" type="text"
                                @blur="$v.newDestination.$touch()"
                                :dirty="$v.newDestination.$dirty"
                                :error="$v.newDestination.$error"
                            />
                            <BaseValidation :messages="getValidationObjects().newDestination" />
                        </div>

                        <!-- Divider -->
                        <hr class="mt-5 mb-5">

                        <BaseButton type="submit" :status="buttonStatus">
                            Submeter
                        </BaseButton>

                        <router-link class="btn btn-block btn-link text-muted" :to="{ name: 'home' }">
                            Cancelar
                        </router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import store from '@/store/store'

export default {
    props: {
        namespace: {
            type: String
        },
        slug: {
            type: String
        }
    },
    computed: {
        ...mapGetters({
            pending: 'api/isPending'
        }),
        isPending() {
            // get api status for button feedback
            return this.pending('changeSlugDestination')
        },
        buttonStatus() {
            return this.$v.$invalid ? 'disabled' : (this.isPending ? 'loading' : 'default')
        }
    },
    data() {
        return {
            newDestination: null
        }
    },
    methods: {
        getValidationObjects() {
            return {
                newDestination: [
                    { condition: !this.$v.newDestination.required, message: 'Novo destino obrigatório' }
                ]
            }
        },
        async changeSlugDestination () {
            try {
                console.log('fasdfasdfasdf');
                await store.dispatch('api/changeSlugDestination', { slug: this.slug, newDestination: this.newDestination })
                $('#modalChangeDestination').modal('hide')
                this.newDestination = null
            } catch (err) {
                console.log(err)
                // TODO error page
            }
        }
    },
    validations: {
        newDestination: {
            required
        }
    }
};
</script>

<style lang="css" scoped>
</style>
