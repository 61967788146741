<template>
<div class="modal fade" id="modalClientBillingCard" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Adicionar Cartão de Débito/Crédito
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="alert alert-danger" role="alert" v-if="uiError">
                        {{ uiError }}
                    </div>

                    <form class="mb-4" @submit.prevent="submitPaymentData">
                        <CreditCardData @card-brand="setCardBrand" @card-validation="setCardDataValidity" @stripe-card-element="setStripeCardElement"/>
                        <div class="row">
                            <div class="col-12">
                                <span class="text-muted">
                                    <ul>
                                        <li>Ao clicares em submeter, autorizas o clica.ai a debitar periodicamente um valor (em EUROS) dependente dos <b>cliques obtidos</b> e <b>miniaturas criadas</b> por cada período de faturação anterior (um mês), mais uma taxa de serviço fixa no ínicio de cada período.</li>
                                        <li>Confirmo que abdico do direito de resolução do contrato ao clicar no botão "Submeter e pagar".</li>
                                        <li>Poderás consultar o valor pendente de faturação no teu <b>painel de utilizador</b>.</li>
                                        <li>Podes cancelar o serviço a <b>qualquer momento</b>, ficando sujeito ao pagamento do valor pendente à data de cancelamento.</li>
                                        <li>Se tiveres cobranças pendentes no clica.ai (tais como de subscrições anteriores), estas poderão ser cobradas ao cartão que estás a adicionar. A tua subscrição só ficará ativa após todos as cobranças serem bem sucedidas.</li>
                                        <li>O <i>clica.ai premium</i> ficará ativo alguns minutos depois de validarmos o teu cartão.</li>
                                    </ul>
                                </span>
                            </div>
                        </div>

                        <hr class="mt-4 mb-5">

                        <div class="row">
                            <div class="col-12">
                                <BaseButton type="submit" :status="submitButtonStatus">
                                    Submeter e pagar
                                </BaseButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/store'
import { mapState, mapGetters } from 'vuex'
import { authComputed } from '@/store/helpers.js'

import { required, minLength } from 'vuelidate/lib/validators'

import CreditCardData from '@/components/billing/CreditCardData'

export default {
    props: {
        parentRefreshContents: {
            type: Function,
            required: true
        }
    },
    components: {
        CreditCardData
    },
    data() {
        return {
            uiError: null,
            isCardDataValid: false,
            stripeCardElement: null,

            stripeSubmitting: false, // track submission status to display pending button

            cardBrand: null
        }
    },
    computed: {
        ...mapState({
            stripeObject: state => state.stripe.stripeObject
        }),
        ...mapGetters({ pending: 'api/isPending' }),
        isSubmitPending() {
            // get requestBillingSetup api status for button feedback
            return this.pending('requestBillingSetupIntent')
        },
        submitButtonStatus() {
            if (!this.isCardDataValid)
                return 'disabled'

            if (this.stripeSubmitting || this.isSubmitPending)
                return 'loading'

            return 'default'
        }
    },
    methods: {
        setCardDataValidity(status) {
            this.isCardDataValid = status
        },
        setStripeCardElement(element) {
            this.stripeCardElement = element
        },
        setCardBrand (brand) {
            this.cardBrand = brand
        },
        async submitPaymentData() {
            this.stripeSubmitting = true

            try {
                // get setup intent's secret
                const res = await store.dispatch('api/requestBillingSetupIntent')
                const clientSecret = _.cloneDeep(res.data.body.clientSecret)

                // send card data to stripe
                const result = await this.stripeObject.confirmCardSetup(clientSecret, {
                    payment_method: {
                        card: this.stripeCardElement
                    }
                })

                if (result.error) {
                    // Stripe returned an error
                    console.log(result.error)

                    // display error message
                    this.uiError = this.getErrorString(result.error)
                } else {
                    console.log(result);

                    // card add has succeeded
                    store.dispatch('notification/add', {
                        status: 'success',
                        category: 'added_card',
                        message: 'Cartão adicionado com sucesso',
                        timeout: 2000
                    })

                    // force parent to fetch data from backend again
                    // we need to do this since card data is not available at the frontend
                    // we commented this since sometimes Stripe does not return the card
                    // when we do this request right after the submit
                    // this.parentRefreshContents()

                    // update cards and subscription status in UI
                    store.dispatch('billing/addCardLocal', { cardId: result.setupIntent.payment_method , cardBrand: this.cardBrand, added: this.epochToHumanDate(result.setupIntent.created*1000) }, { root: true })
                    await store.dispatch('billing/enableSubscriptionLocal', { }, { root: true })

                    // close the modal
                    $('#modalClientBillingCard').modal('hide')
                }
            } catch (err) {
                if (err.response) {
                    this.uiError = err.response.data.error
                }

                console.log(err)
            } finally {
                // cleanup card elements
                this.$root.$emit('cleanupCardData')

                this.stripeSubmitting = false
            }
        }
    }
};
</script>

<style lang="css" scoped>
.card-body {
  max-height: 2000px !important;
}
</style>
