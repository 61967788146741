<template>
<div class="main-content">
    <template v-if="!loading">
        <div class="header">
            <div class="container-fluid">
                <div class="header-body">
                    <div class="row align-items-end">
                        <div class="col">
                            <h6 class="header-pretitle">
                                Vista de Administração
                            </h6>

                            <h1 class="header-title">
                                Painel de Miniaturas
                            </h1>
                        </div>
                        <!-- <div class="col-auto">
                                <a href="#!" class="btn btn-primary lift">
                                    Create Report
                                </a>
                            </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xl-3">
                    <CardSmallText
                        title="Miniaturas criadas (semana)"
                        :headingText="String(slugData.created_slugs.w)"
                        icon="fe-calendar"
                    />
                </div>
                <div class="col-12 col-xl-3">
                    <CardSmallText
                        title="Miniaturas criadas (mês)"
                        :headingText="String(slugData.created_slugs.m)"
                        icon="fe-calendar"
                    />
                </div>
                <div class="col-12 col-xl-3">
                    <CardSmallText
                        title="Miniaturas criadas (trimestre)"
                        :headingText="String(slugData.created_slugs.q)"
                        icon="fe-calendar"
                    />
                </div>
                <div class="col-12 col-xl-3">
                    <CardSmallText
                        title="Miniaturas criadas (sempre)"
                        :headingText="String(slugData.created_slugs.t)"
                        icon="fe-calendar"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <CardTable
                        title="Top miniaturas anónimas"
                        :header="[{title: 'Miniatura'}, {title: 'Destino'}, {title: 'Criado em'}, {title: 'Cliques'}, {title: 'Último clique'}]"
                        :rowElements="['slug', 'destination', 'date_created', 'click_count', 'last_click']"
                        :rows="slugData.top_anonymous_slugs"
                    />
                </div>
                <div class="col-12">
                    <CardTable
                        title="Top miniaturas de utilizadores"
                        :header="[{title: 'Miniatura'}, {title: 'Destino'}, {title: 'Utilizador'}, {title: 'Criado em'}, {title: 'Cliques'}, {title: 'Último clique'}]"
                        :rowElements="['slug', 'destination', 'email', 'date_created', 'click_count', 'last_click']"
                        :rows="slugData.top_registered_slugs"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xl-12">
                    <CardSmallText
                        title="Cliques em miniaturas de utilizadores"
                        :headingText="slugData.click_percentages.registered + '%'"
                        icon="fe-trending-up"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xl-12">
                    <CardTable
                        title="Últimas miniaturas bloqueadas"
                        :header="[{title: 'Detalhes', hasPageLink: true}, {title: 'Miniatura'}, {title: 'Destino'}, {title: 'Criado Por'}, {title: 'Criado em'}, {title: 'Banido em'}, {title: 'Razão'}]"
                        :rowElements="['slug', 'slug', 'destination', 'email', 'date_created', 'blocked_at', 'blocked_reason']"
                        :rows="slugData.last_blocked_slugs"
                    />

                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xl-12">
                    <CardTableAdminUrl
                        title="Top 25 Miniaturas (24 horas)"
                        :rows="slugData.top_daily_slugs"
                        :totalRows="0"
                        :rowsReceivedCallback="this.storeSlugs"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <CardTableAdminUrl
                        title="Miniaturas no sistema"
                        :rows="slugData.slug_table"
                        :totalRows="Number(slugData.created_slugs.t)"
                        :rowsReceivedCallback="this.storeSlugs"
                    />
                </div>
            </div>

        </div>
    </template>
</div>
</template>

<script>
import CardTable from '@/components/cards/CardTable'
import CardTableAdminUrl from '@/components/cards/CardTableAdminUrl'
import CardList from '@/components/cards/CardList'
import CardPieChart from '@/components/cards/CardPieChart'
import CardBarChart from '@/components/cards/CardBarChart'
import CardSmallText from '@/components/cards/CardSmallText'
import CardSmallProgress from '@/components/cards/CardSmallProgress'

import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'
import DashboardDataProcessing from '@/mixins/dashboardDataProcessing'

export default {
    components: {
        CardTableAdminUrl,
        CardTable,
        CardList,
        CardPieChart,
        CardBarChart,
        CardSmallText,
        CardSmallProgress
    },
    data() {
        return {
            loading: false
        }
    },
    mixins: [DashboardDataProcessing],
    methods: {
        storeSlugs(slugs) {
            const processed = this.processSlugTable(slugs)
            return store.dispatch('adminData/setPartialSlugData', processed, { root: true })
        }
    },
    created() {
        NProgress.start()
        this.loading = true

        store.dispatch('api/adminGetSlugData')
            .then((res) => {
                const slugData = _.cloneDeep(res.data.body.slug_data)

                // convert dates to human readable format
                slugData.top_anonymous_slugs = slugData.top_anonymous_slugs.map((el) => {
                    el.date_created = this.isoDateToHuman(el.date_created)
                    el.last_click = this.isoDateToHuman(el.last_click)
                    return el
                })

                slugData.top_registered_slugs = slugData.top_registered_slugs.map((el) => {
                    el.date_created = this.isoDateToHuman(el.date_created)
                    el.last_click = this.isoDateToHuman(el.last_click)
                    return el
                })

                slugData.last_blocked_slugs = slugData.last_blocked_slugs.map((el) => {
                    el.blocked_at = this.isoDateToHuman(el.blocked_at)
                    el.date_created = this.isoDateToHuman(el.date_created)
                    return el
                })

                slugData.slug_table = slugData.slug_table.map((el) => {
                    el.date_created = this.isoDateToHuman(el.date_created)
                    if(el.blocked_at)
                        el.blocked_at = this.isoDateToHuman(el.blocked_at)
                    return el
                })

                slugData.top_daily_slugs = slugData.top_daily_slugs.map((el) => {
                    el.date_created = this.isoDateToHuman(el.date_created)
                    if(el.last_click)
                        el.last_click = this.isoDateToHuman(el.last_click)
                    else
                        el.last_click = '-'
                    return el
                })

                // make sure all tables have minimal horizontal scroll
                for (var i = 0; i < slugData.slug_table.length; i++) {
                  // ellipsis on destination
                  slugData.slug_table[i]['destination_long'] = slugData.slug_table[i]['destination']
                  if(slugData.slug_table[i]['destination'].length > 83)
                      slugData.slug_table[i]['destination'] = slugData.slug_table[i]['destination'].substring(0,80) + '...'
                }
                for (var i = 0; i < slugData.top_anonymous_slugs.length; i++) {
                  // ellipsis on destination
                  slugData.top_anonymous_slugs[i]['destination_long'] = slugData.top_anonymous_slugs[i]['destination']
                  if(slugData.top_anonymous_slugs[i]['destination'].length > 123)
                      slugData.top_anonymous_slugs[i]['destination'] = slugData.top_anonymous_slugs[i]['destination'].substring(0,120) + '...'
                }
                for (var i = 0; i < slugData.top_registered_slugs.length; i++) {
                  // ellipsis on destination
                  slugData.top_registered_slugs[i]['destination_long'] = slugData.top_registered_slugs[i]['destination']
                  if(slugData.top_registered_slugs[i]['destination'].length > 93)
                      slugData.top_registered_slugs[i]['destination'] = slugData.top_registered_slugs[i]['destination'].substring(0,90) + '...'
                }
                for (var i = 0; i < slugData.top_daily_slugs.length; i++) {
                  // ellipsis on destination
                  slugData.top_daily_slugs[i]['destination_long'] = slugData.top_daily_slugs[i]['destination']
                  if(slugData.top_daily_slugs[i]['destination'].length > 93)
                      slugData.top_daily_slugs[i]['destination'] = slugData.top_daily_slugs[i]['destination'].substring(0,90) + '...'
                }

                store.dispatch('adminData/setAdminSlugData', slugData, { root: true })
                    .then((res) => {
                        NProgress.done()
                        this.loading = false
                    })
            })
            .catch((err) => {
                console.log(err)
                // TODO error page
            })
    },
    computed: {
        ...mapState({
            slugData: state => state.adminData.slug
        })
    }
};
</script>

<style scoped></style>
