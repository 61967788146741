<template>
<div class="main-content" v-if="!loading">
    <div class="header">
        <div class="container-fluid">
            <div class="header-body">
                <div class="row align-items-end">
                    <div class="col-auto my-auto">
                        <router-link class="btn btn-primary" :to="{ name: 'home'}">
                            <i class="fe fe-arrow-left d-none d-md-inline mr-3"></i> Voltar ao Painel
                        </router-link>
                    </div>
                    <div class="col">
                        <h6 class="header-pretitle">
                            Vista de Miniatura
                        </h6>

                        <h1 class="header-title">
                            {{ slug }} <i class="fe fe-arrow-right"></i> {{ slugStatistics.slugData.destination }}
                        </h1>
                    </div>
                    <!-- <div class="col-auto">
                        <a href="#!" class="btn btn-primary lift">
                            Create Report
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-8">
                <!-- Orders -->
                <CardBarChart
                    title="Horas"
                    :dataLabel="['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h']"
                    :data="slugStatistics.hours"
                />
            </div>
            <div class="col-12 col-xl-4">
                <CardPieChart
                    id="miscDataChart"
                    title="Tipos de Dispositivos"
                    :dataLabel="['Computador', 'Tablet', 'Móvel']"
                    :data="slugStatistics.misc"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12">
                        <CardSmallText
                            title="Total de cliques"
                            :headingText="slugStatistics.clickCount"
                            icon="fe-loader"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <CardSmallText
                            title="Criada em"
                            :headingText="slugStatistics.slugData.created"
                            icon="fe-calendar"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <CardPieChart
                    id="browsersDataChart"
                    title="Navegadores"
                    :dataLabel="slugStatistics.browserLabels"
                    :data="slugStatistics.browsers"
                />
            </div>
            <div class="col-12 col-md-4">
                <CardPieChart
                    id="osDataChart"
                    title="Sistemas Operativos"
                    :dataLabel="slugStatistics.osLabels"
                    :data="slugStatistics.os"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <CardLineChart
                    :dataset="slugStatistics.dates"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xl-6">
                <CardTable
                    title="Dispositivos Mais Populares"
                    :header="[{title: 'Dispositivo'}, {title: 'Cliques'}]"
                    :rowElements="['model', 'freq']"
                    :rows="slugStatistics.devices"
                    showEmptyString="Amostra de dispositivos muito pequena para mostrar"
                />
            </div>
            <div class="col-12 col-xl-6">
                <CardTable
                    title="Localizações"
                    :header="[{title: 'Local'}, {title: 'País'}, {title: 'Cliques'}]"
                    :rowElements="['n', 'c', 'f']"
                    :rows="slugStatistics.locations"
                    showEmptyString="Amostra de localizações muito pequena para mostrar"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import CardMap from '@/components/cards/CardMap'
import CardTable from '@/components/cards/CardTable'
import CardList from '@/components/cards/CardList'
import CardPieChart from '@/components/cards/CardPieChart'
import CardLineChart from '@/components/cards/CardLineChart'
import CardBarChart from '@/components/cards/CardBarChart'
import CardSmallText from '@/components/cards/CardSmallText'
import CardSmallProgress from '@/components/cards/CardSmallProgress'
import CardSparklineChart from '@/components/cards/CardSparklineChart'
import HomeHeader from '@/components/HomeHeader'

import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'

import DashboardDataProcessing from '@/mixins/dashboardDataProcessing'

export default {
    props: {
        slug: {
            type: String,
            required: true
        }
    },
    mixins: [DashboardDataProcessing],
    components: {
        CardTable,
        CardList,
        CardPieChart,
        CardBarChart,
        CardSmallText,
        CardSmallProgress,
        CardSparklineChart,
        HomeHeader,
        CardLineChart,
        CardMap,
    },
    data() {
        return {
            loading: false
        }
    },
    async created() {
        this.loading = true
        NProgress.start()

        try {
            // get data from backend
            const res = await store.dispatch('api/fetchSlugData', this.slug)
            const data = _.cloneDeep(res.data.body.slug_data)
            const processedData = this.processPremiumDashboardData(data)

            processedData['slugData'] = data.slug_data
            processedData['slugData']['created'] = this.isoDateToHuman(processedData['slugData']['created'])

            // store processed data in store
            await store.dispatch('statistics/setData', processedData, { root: true })

            this.loading = false
            NProgress.done()

        } catch (err) {
            // TODO handle other errors
            if (err && err.response && err.response.status === 403) {
                this.loading = false
                NProgress.done()

                this.$store.dispatch('notification/add', {
                    status: 'error',
                    category: 'forbidden',
                    message: 'Miniatura sem cliques para consultar',
                    timeout: 2000
                })

                this.$router.push({ name: 'home' })
            }
        }
    },
    mounted() {

    },
    methods: {},
    computed: mapState({
        slugStatistics: state => state.statistics
    })
};
</script>

<style scoped></style>
