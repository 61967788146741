export const namespaced = true

export const state = {
    billingData: {},
    cards: [],
    invoices: [],
    premiumData: {
        active: false,
        total: "0,00€"
    }
}

export const mutations = {
    SET_CARDS(state, data) {
        state.cards = data
    },
    SET_BILLING_DATA(state, data) {
        state.billingData = data
    },
    SET_INVOICES(state, data) {
        state.invoices = data
    },
    SET_INVOICE_STATUS(state, data) {
        state.invoices = state.invoices.map((el) => {
            if(el.id === data.id)
                el.status = data.status
            return el
        })
    },
    SET_PREMIUM_DATA(state, data) {
        state.premiumData = data
    },
    SET_DEFAULT_CARD(state, cardId) {
        console.log(cardId);
        state.cards = state.cards.map((el) => {
            if(el.id === cardId)
                el.default = true
            else
                el.default = false
            return el
        })
    },
    REMOVE_CARD_LOCAL(state, cardId) {
        state.cards = state.cards.filter((el) => el.id !== cardId)
    },
    ADD_CARD_LOCAL(state, data) {
        state.cards.push({
            id: data.cardId,
            brand: data.cardBrand,
            added_at: data.added,
            default: false
        })

        // if there's only one card, that is the default
        if(state.cards.length === 1)
            state.cards[0].default = true
    },
    ENABLE_SUBSCRIPTION_LOCAL(state) {
        state.premiumData.active = true
    },
    DISABLE_SUBSCRIPTION_LOCAL(state) {
        state.premiumData.active = false
    },
}

export const actions = {
    setBillingData({ commit, dispatch }, data) {
        commit('SET_BILLING_DATA', data)
    },
    setCards({ commit, dispatch }, data) {
        commit('SET_CARDS', data)
    },
    setInvoices({ commit, dispatch }, data) {
        commit('SET_INVOICES', data)
    },
    setInvoiceStatus({ commit, dispatch }, data) {
        commit('SET_INVOICE_STATUS', data)
    },
    setPremiumData({ commit, dispatch }, data) {
        commit('SET_PREMIUM_DATA', data)
    },
    setDefaultCard({ commit, dispatch }, cardId) {
        commit('SET_DEFAULT_CARD', cardId)
    },
    removeCardLocal({ commit, dispatch }, cardId) {
        commit('REMOVE_CARD_LOCAL', cardId)
    },
    addCardLocal({ commit, dispatch }, data) {
        commit('ADD_CARD_LOCAL', data)
    },
    enableSubscriptionLocal({ commit, dispatch }, data) {
        commit('ENABLE_SUBSCRIPTION_LOCAL')
    },
    disableSubscriptionLocal({ commit, dispatch }, data) {
        commit('DISABLE_SUBSCRIPTION_LOCAL')
    },
    clearData({ commit, dispatch }) {
        commit('SET_BILLING_DATA', null)
        commit('SET_CARDS', [])
        commit('SET_INVOICES', [])
        commit('SET_PREMIUM_DATA', {
            active: true,
            total: "0,00€"
        })
    }
}
