<template>
<div class="main-content">
    <template v-if="!loading">
        <div class="header">
            <div class="container-fluid">
                <div class="header-body">
                    <div class="row align-items-end">
                        <div class="col">
                            <h6 class="header-pretitle">
                                Vista de Administração
                            </h6>

                            <h1 class="header-title">
                                Painel de Utilizadores
                            </h1>
                        </div>
                        <!-- <div class="col-auto">
                                <a href="#!" class="btn btn-primary lift">
                                    Create Report
                                </a>
                            </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xl-3">
                    <CardSmallText
                        title="Utilizadores Registados"
                        :headingText="String(userData.registered.total)"
                        icon="fe-users"
                    />
                </div>
                <div class="col-12 col-xl-3">
                    <CardSmallText
                        title="Registos Este Mês"
                        :headingText="String(userData.registered.last_month)"
                        icon="fe-user"
                    />
                </div>
                <div class="col-12 col-xl-3">
                    <CardSmallText
                        title="Utilizadores com bloqueios"
                        :headingText="String(userData.users_with_blocks) + ' (' + (userData.users_with_blocks/userData.registered.total*100).toFixed(2) + '%)'"
                        icon="fe-lock"
                    />
                </div>
                <div class="col-12 col-xl-3">
                    <CardSmallText
                        title="Última denúncia"
                        :headingText="lastReportDate"
                        icon="fe-clock"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xl-6">
                    <CardTable
                        title="Melhores utilizadores por número de cliques"
                        :header="[{title: 'Utilizador'}, {title: 'Número de cliques'}]"
                        :rowElements="['email', 'click_count']"
                        :rows="userData.top_users_per_clicks"
                    />
                </div>
                <div class="col-12 col-xl-6">
                    <CardTable
                        title="Melhores utilizadores por número de miniaturas"
                        :header="[{title: 'Utilizador'}, {title: 'Número de miniaturas'}]"
                        :rowElements="['email', 'slug_count']"
                        :rows="userData.top_users_per_slugs"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <CardTable
                        title="Utilizadores registados"
                        :header="[{title: 'ID'}, {title: 'Email'}, {title: 'Número miniaturas'}, {title: 'Total Cliques'}, {title: 'Último Login'}, {title: 'Última Ação'}, {title: 'Premium'}]"
                        :rowElements="['user_id', 'email', 'slug_count', 'click_count', 'last_login', 'last_action', 'premium']"
                        :rows="userData.user_stats_table"
                    />
                </div>
            </div>
        </div>
    </template>
</div>
</template>

<script>
import CardTable from '@/components/cards/CardTable'
import CardList from '@/components/cards/CardList'
import CardPieChart from '@/components/cards/CardPieChart'
import CardBarChart from '@/components/cards/CardBarChart'
import CardSmallText from '@/components/cards/CardSmallText'
import CardSmallProgress from '@/components/cards/CardSmallProgress'

import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'

export default {
    components: {
        CardTable,
        CardList,
        CardPieChart,
        CardBarChart,
        CardSmallText,
        CardSmallProgress
    },
    data() {
        return {
            loading: false
        }
    },
    async created() {
        NProgress.start()
        this.loading = true

        try {
            const res = await store.dispatch('api/adminGetUserData')
            const userData = _.cloneDeep(res.data.body.user_data)

            // convert last login to human
            userData.user_stats_table = userData.user_stats_table.map((el) => {
                if(el.last_login)
                    el.last_login = this.isoDateToHuman(el.last_login)
                else
                    el.last_login = '-'

                return el
            })

            // convert last action to human
            userData.user_stats_table = userData.user_stats_table.map((el) => {
                if(el.last_action)
                    el.last_action = this.isoDateToHuman(el.last_action)
                else
                    el.last_action = '-'
                return el
            })

            // convert premium to readable
            userData.user_stats_table = userData.user_stats_table.map((el) => {
                if(el.premium)
                    el.premium = 'S'
                else
                    el.premium = ''
                return el
            })

            await store.dispatch('adminData/setAdminUserData', userData, { root: true })
        } catch(err) {
            console.log(err)
            // TODO error page
        } finally {
            NProgress.done()
            this.loading = false
        }
    },
    computed: {
        lastReportDate() {
            return this.userData.reports.last_report? this.isoDateToHuman(this.userData.reports.last_report) : '-'
        },
        ...mapState({
            userData: state => state.adminData.user
        })
    }
};
</script>

<style scoped></style>
