import { render, staticRenderFns } from "./ModalClientBillingData.vue?vue&type=template&id=35be1f28&scoped=true&"
import script from "./ModalClientBillingData.vue?vue&type=script&lang=js&"
export * from "./ModalClientBillingData.vue?vue&type=script&lang=js&"
import style0 from "./ModalClientBillingData.vue?vue&type=style&index=0&id=35be1f28&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35be1f28",
  null
  
)

export default component.exports