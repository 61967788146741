<template>
<div class="modal fade" id="modalStartTrial" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Começar período de teste
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <p>Queres começar o teu período de testes por 3 meses? É gratuito e não tens de usar cartão!</p>
                    <p class="font-italic text-muted">A página vai atualizar automaticamente.</p>

                    <hr/>
                    <form @submit.prevent="startTrial">
                        <BaseButton type="submit" :status="buttonStatus">
                            Começar
                        </BaseButton>

                        <span class="btn btn-block btn-link text-muted" style="cursor: pointer;" data-dismiss="modal">
                            Cancelar
                        </span>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from '@/store/store'

export default {
    computed: {
        ...mapGetters({
            pending: 'api/isPending'
        }),
        isStartTrialPending() {
            // get api status for button feedback
            return this.pending('accountStartTrial')
        },
        buttonStatus() {
            return this.isStartTrialPending ? 'loading' : 'default'
        }
    },
    methods: {
        async startTrial() {
            try {
                const res = await store.dispatch('api/accountStartTrial')
            } catch(err) {
                console.log(err)
                // TODO error page
            } finally {
                this.closeModal()
                location.reload()
            }
        },
        closeModal() {
            $('#modalStartTrial').modal('hide')
        }
    }
};
</script>

<style lang="css" scoped>
</style>
