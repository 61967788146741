<template>
<div class="main-content">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 col-xl-8">
                <div class="header mt-md-5">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    Contacta a equipa clica.ai
                                </h6>

                                <h1 class="header-title">
                                    Contacta-nos
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Form -->
                <form class="mb-4" @submit.prevent="sendContactForm">
                    <!-- <div class="form-group">
                        <label>Remetente</label>
                        <input type="text" disabled class="form-control">
                    </div>

                    <hr class="mt-4 mb-5"> -->

                    <div class="form-group">
                        <label for="subject">Assunto</label>
                        <BaseInput id="subject" placeholder="Assunto" v-model="contact.subject" type="text" />
                    </div>

                    <div class="form-group">
                        <label for="message">Mensagem</label>
                        <BaseTextArea id="message" placeholder="Escreve aqui a tua mensagem" :resizable="false" v-model="contact.message"
                            @blur="$v.contact.message.$touch()"
                            :dirty="$v.contact.message.$dirty"
                            :error="$v.contact.message.$error"
                        />
                        <BaseValidation :messages="getValidationObjects().message" />
                    </div>

                    <!-- Divider -->
                    <hr class="mt-5 mb-5">

                    <BaseButton type="submit" :status="buttonStatus">
                        Submeter
                    </BaseButton>

                    <router-link class="btn btn-block btn-link text-muted" :to="{ name: 'home' }">
                        Cancelar
                    </router-link>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            contact: this.getFreshMessageObject()
        }
    },
    computed: {
        ...mapGetters({
            pending: 'api/isPending'
        }),
        isMessagePending() {
            // get login api status for button feedback
            return this.pending('sendContactForm')
        },
        buttonStatus() {
            return this.$v.$invalid ? 'disabled' : (this.isMessagePending ? 'loading' : 'default')
        }
    },
    methods: {
        getFreshMessageObject() {
            return {
                subject: '',
                message: ''
            }
        },
        getValidationObjects() {
            return {
                message: [{
                    condition: !this.$v.contact.message.required,
                    message: 'Conteúdo da mensagem obrigatório'
                }]
            }
        },
        sendContactForm() {
            this.$v.$touch() // makes the form dirty to show errors
            if (this.$v.$pending || this.$v.$error || this.$v.$invalid)
                return

            this.$store.dispatch('api/sendContactForm', {
                type: 'contact',
                subject: this.contact.subject,
                message: this.contact.message
            }).then(() => {
                console.log('submission successful, cleaning...')
                this.contact = this.getFreshMessageObject()
                this.$v.$reset()
            }).catch(() => {
                console.log('error from server...')
            })
        }
    },
    validations: {
        contact: {
            message: {
                required
            }
        }
    }
}
</script>

<style lang="css">
</style>
