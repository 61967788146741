import { render, staticRenderFns } from "./ModalSlugCreated.vue?vue&type=template&id=19603e42&scoped=true&"
import script from "./ModalSlugCreated.vue?vue&type=script&lang=js&"
export * from "./ModalSlugCreated.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19603e42",
  null
  
)

export default component.exports