<template>
    <!-- TODO this is not updated as per the new template; should be consistend with how it will be used in front-office in the report url view -->
    <div class="form-label-group">
        <select :value="value" @change="updateValue" v-on="listeners" v-bind="$attrs" class="form-control custom-select" :class="inputClass">
            <option value="" disabled hidden>{{ label }}</option>
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                :selected="option.value === value">
                {{ option.text }}
            </option>
        </select>
        <template v-for="validation in validationMessages">
            <span v-if="error && validation.condition && validation.message.length > 0" class="invalid-feedback">
                {{ validation.message }}
            </span>
        </template>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        options: {
            type: Array,
            required: true
        },
        value: [String, Number],
        label: {
            type: String,
            default: ''
        },
        inputClass: {
            type: String
        },
        error: {
            type: Boolean,
            default: false
        },
        validationMessages: {
            type: Array
        }
    },
    computed: {
        listeners() {
            return { // vue mastery S04E11, conflict between listeners
                ...this.$listeners,
                input: this.updateValue
            }
        },
        isValid() {
            // ugh, this is awful
            // Bootstrap only shows error validation with html5 validation,
            // so we set a regex pattern that is either a match-anything
            // or a match-nothing. If we know the input is valid from vuelidate,
            // we can match anything to display it correctly.
            return this.error ? 'a^' : '[\\s\\S]*'
        }
    },
    methods: {
        updateValue(event) {
            this.$emit('input', event.target.value)
        }
    }
}
</script>
