<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <h4 class="card-header-title">
                        Criar Miniatura
                    </h4>
                </div>
                <div class="col-auto">
                    <router-link class="nav-link" :to="{ name: 'create-slug', params: { 'customSlugDefault': true } }">
                        <span class="small">Personalizar</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <form @submit.prevent="createSlug">
                        <div class="form-group">
                            <BaseInput class="form-control-rounded" id="url" label="URL original" placeholder="URL original" v-model="originalUrl" />
                        </div>

                        <BaseButton type="submit" :status="buttonStatus" buttonExternalClasses="mb-2">
                            Criar Miniatura <i class="fe fe-arrow-right d-none d-md-inline ml-3"></i>
                        </BaseButton>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-auto">
                    <CardCreateSlugSuccess ref="cardSlugSuccess" v-if="this.slug" :namespace="this.namespace" :slug="this.slug" :destination="this.destination" />
                    <CardCreateSlugError ref="cardSlugError" v-else-if="this.errorMsg" :error="this.errorMsg" :destination="this.destination" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { URLService } from '@/services/URLService'
import ClipboardJS from 'clipboard'

import CardCreateSlugSuccess from '@/components/cards/CardCreateSlugSuccess'
import CardCreateSlugError from '@/components/cards/CardCreateSlugError'

import store from '@/store/store'

export default {
    data() {
        return {
            destination: '',
            namespace: '',
            slug: '',
            errorMsg: '',

            originalUrl: '',
            buttonStatus: 'default'
        };
    },
    components: {
        CardCreateSlugSuccess,
        CardCreateSlugError
    },
    methods : {
        async createSlug() {
            this.namespace = ''
            this.slug = ''
            this.destination = ''
            this.errorMsg = ''

            this.buttonStatus = 'loading'
            this.destination = this.originalUrl

            try {
                const res = await store.dispatch('api/createSlug', {
                    destination: this.destination,
                    notify: false // ui only
                }, { root: true })

                this.namespace = res.data.body.namespace
                this.slug = res.data.body.slug

                await store.dispatch('dashboard/setLocalUrl', {
                    url: this.destination,
                    slug: this.slug,
                    click_count: 0,
                    destination_long: !(this.destination.includes('http://') || this.destination.includes('https://')) ? 'https://' + this.destination : this.destination,
                    destination: this.destination.length > 83 ? this.destination.substring(0,80) + '...' : this.destination,
                    date_created: this.isoDateToHuman(new Date().toISOString()),
                    last_click: '-'
                }, { root: true })

                // cleanup
                this.originalUrl = ''
                this.buttonStatus = 'default'
            } catch (err) {
                this.errorMsg = err.response ? err.response.data.error : 'Erro do servidor'
                this.buttonStatus = 'default'
            }
        }
    }
};
</script>

<style lang="css" scoped>
</style>
