export default {
    methods: {
        dateToHuman(date) {
            const components = date.split('-')
            const day = components[2].split('T')[0]

            switch (components[1]) {
                case '01':
                    return day + ' Jan'
                case '02':
                    return day + ' Fev'
                case '03':
                    return day + ' Mar'
                case '04':
                    return day + ' Abr'
                case '05':
                    return day + ' Mai'
                case '06':
                    return day + ' Jun'
                case '07':
                    return day + ' Jul'
                case '08':
                    return day + ' Ago'
                case '09':
                    return day + ' Set'
                case '10':
                    return day + ' Out'
                case '11':
                    return day + ' Nov'
                case '12':
                    return day + ' Dez'
                default:
                    return day + ' ' + components[1]
            }
        },

        // creates a dates object compatible with the HomeHeader format
        processDates(rawDates, days, textShort, textLong, labelNum) {
            const availableDays = days < 0 ? rawDates.length : Math.min(rawDates.length, days)
            let obj = {
                labels: [],
                data: [],
                textShort: textShort,
                textLong: textLong,
                labelNum: labelNum
            }

            for(let i = rawDates.length - availableDays; i < rawDates.length; i++) {
                const date = this.dateToHuman(rawDates[i]['d'])

                obj.labels.push(date)
                obj.data.push(rawDates[i]['f'])
            }

            return obj
        },

        processSlugTable(slugData) {
            // TODO when fetching this from the database, should be ordered
            // by date_created (descending). MUST use pagination strategy when fetching from API.

            for(let i = 0; i < slugData.length; i++) {
                // convert created date to human
                slugData[i]['date_created'] = this.isoDateToHuman(new Date(slugData[i]['date_created']))

                // convert blocked date to human
                if(slugData[i].blocked_at) {
                    slugData[i].blocked_at = this.isoDateToHuman(new Date(slugData[i].blocked_at))
                }

                // convert last click date to human
                if(slugData[i].last_click)
                    slugData[i].last_click = this.isoDateToHuman(new Date(slugData[i].last_click))
                else
                    slugData[i].last_click = '-'

                // ellipsis on destination
                slugData[i]['destination_long'] = slugData[i]['destination']
                if(slugData[i]['destination'].length > 63)
                    slugData[i]['destination'] = slugData[i]['destination'].substring(0,60) + '...'

                // ellipsis on description
                slugData[i]['description'] = slugData[i]['description'] || ''
                slugData[i]['description_long'] = slugData[i]['description']
                if(slugData[i]['description'].length > 45)
                    slugData[i]['description'] = slugData[i]['description'].substring(0,40) + '...'
            }

            return slugData
        },

        processFreeDashboardData(data) {
            const processedData = {}

            processedData.clickCount = (data.stats.click_count || 0).toString()
            processedData.slugCount = (data.stats.slug_count || 0).toString()

            // process and store url list
            processedData.urlList = this.processSlugTable(data.user_urls)

            // store system message
            processedData.systemMessage = data.system_message

            // store user notifications
            processedData.userNotifications = data.userNotifications

            return processedData
        },

        processPremiumDashboardData(data) {
            const processedData = {}

            if (data.stats) {
                processedData.slugCount = (data.stats.slug_count || 0).toString()
            }

            processedData.clickCount = (data.slug_analytics.misc.clicks_total || 0).toString()

            if(data.user_urls) {
                processedData.urlList = this.processSlugTable(data.user_urls)
            }

            if(data.slug_analytics) {
                const slugAnalytics = data.slug_analytics

                // process hours
                const hours = slugAnalytics.hours
                for(let i = 0; i < hours.length; i++) {
                    // TODO SQL function sends it as array of objects with key 'f', should be changed at DB function
                    hours[i] = hours[i]['f']
                }

                processedData.hours = hours

                // process dates
                const datesBackend = slugAnalytics.dates

                processedData.dates = []
                processedData.dates.push(this.processDates(datesBackend, 10, 'Semana', 'Última Semana', slugAnalytics.misc.clicks_week))

                if(datesBackend.length > 10)
                    processedData.dates.push(this.processDates(datesBackend, 30, 'Mês', 'Último Mês', slugAnalytics.misc.clicks_month))

                if(datesBackend.length > 30)
                    processedData.dates.push(this.processDates(datesBackend, 90, 'Trimestre', 'Último Trimestre', slugAnalytics.misc.clicks_trimester))

                // type of device data
                const totalMisc = slugAnalytics.misc.pc + slugAnalytics.misc.tablet + slugAnalytics.misc.mobile
                processedData.misc = [
                    Math.round(slugAnalytics.misc.pc / totalMisc * 100),
                    Math.round(slugAnalytics.misc.tablet / totalMisc * 100),
                    Math.round(slugAnalytics.misc.mobile / totalMisc * 100)
                ]

                // os data
                const backendOsData = slugAnalytics.os
                processedData.osLabels = []
                processedData.os = []

                let percentages = 0
                for(let i = 0; i < backendOsData.length; i++) {
                    if (backendOsData[i]['a'] === 'Other')
                        continue

                    // version ommited for v1
                    // if (!backendOsData[i]['v'] || backendOsData[i]['v'].length === 0)
                    //     backendOsData[i]['v'] = ' (vários)'

                    processedData.osLabels.push(backendOsData[i]['a']/* + ' ' + backendOsData[i]['v']*/)

                    const p = Math.round(backendOsData[i]['f'] / processedData.clickCount * 100)
                    percentages += p
                    processedData.os.push(p)
                }

                // add ommited values (both from label 'Other', as well as truncated in backend)
                if (percentages < 100) {
                    processedData.osLabels.push('Outros')
                    processedData.os.push(100 - percentages)
                }

                // browser data
                const backendBrowserData = slugAnalytics.browsers

                processedData.browserLabels = []
                processedData.browsers = []

                percentages = 0
                for(let i = 0; i < backendBrowserData.length; i++) {
                    if (backendBrowserData[i]['a'] === 'Other')
                        continue

                    processedData.browserLabels.push(backendBrowserData[i]['a']) // version also ommited
                    const p = Math.round(backendBrowserData[i]['f'] / processedData.clickCount * 100)
                    percentages += p
                    processedData.browsers.push(p)
                }

                // add ommited values (both from label 'Other', as well as truncated in backend)
                if (percentages < 100) {
                    processedData.browserLabels.push('Outros')
                    processedData.browsers.push(100 - percentages)
                }

                // device data
                const backendDeviceData = slugAnalytics.devices

                processedData.deviceLabels = []
                processedData.devices = []

                percentages = 0
                for(let i = 0; i < backendDeviceData.length; i++) {
                    if (backendDeviceData[i]['m'] === 'Other')
                        continue

                    processedData.devices.push({ model: backendDeviceData[i]['m'], freq: backendDeviceData[i]['f'] })

                    //deviceLabels.push(backendDeviceData[i]['b']) // version also ommited
                    // const p = Math.round(backendDeviceData[i]['f'] / processedData.clickCount * 100)
                    // percentages += p
                    //devices.push(p)
                }

                // add ommited values (both from label 'Other', as well as truncated in backend)
                // if (percentages < 100) {
                //     deviceLabels.push('Outros')
                //     devices.push(100 - percentages)
                // }

                // process locations
                processedData.locations = slugAnalytics.locations
            }

            // store system message
            processedData.systemMessage = data.system_message

            // store user notifications
            processedData.userNotifications = data.userNotifications

            return processedData
        },

        processAdminDashboardData(data) {
            const processedData = this.processPremiumDashboardData(data)

            if (data.user_stats.user_count) {
                processedData.userCount = (data.user_stats.user_count || 0).toString()
            }

            return processedData
        }
    }
}
