<template>
<div class="table-responsive">
    <table class="table table-sm table-nowrap card-table">
        <thead>
            <tr>
                <th>Número</th>
                <th>Data</th>
                <th>Valor (EUR)</th>
                <th>Estado</th>
                <th></th>
            </tr>
        </thead>
        <tbody class="font-size-base">
            <tr v-if="loading">
                <td :colspan="5">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" style="color:#2c7be5;">
                            <span class="sr-only">A carregar...</span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr v-for="(row, rowIndex) in invoices" v-else>
                <td>
                    <router-link :to="{ name: 'billing-invoice', params: { number: row.number }}" v-if="row.status ==='paid'">
                        {{ row.number }}
                    </router-link>
                    <template v-else>
                        {{ row.number }}
                    </template>
                </td>
                <td>
                    {{ epochToHumanDate(row.created) }}
                </td>
                <td>
                    {{ getHumanPrice(row.amount) }}
                </td>
                <td>
                    <span class="badge badge-warning" v-if="row.status === 'requires_payment_method' || row.status === 'open'">Pagamento em Falta</span>
                    <span class="badge badge-soft-primary" v-if="row.status === 'requires_action'">Autenticação Necessária</span>
                    <span class="badge badge-success" v-if="row.status === 'paid'">Pago</span>
                </td>
                <td>
                    <template v-if="row.status === 'requires_payment_method' || row.status === 'open'">
                        <button class="btn btn-sm btn-primary" :disabled="updatingInvoice === row.number" @click="openPayInvoiceModal(row.id, row.number, row.amount, row.clientSecret)">
                            <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status" v-if="updatingInvoice === row.number" />
                            Efetuar Pagamento
                        </button>
                    </template>
                    <button v-if="row.status === 'requires_action'" class="btn btn-sm btn-primary" :disabled="updatingInvoice === row.number" @click="attemptAuth(row.id, row.number, row.clientSecret)">
                        <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status" v-if="updatingInvoice === row.number" />
                        Autorizar
                    </button>
                </td>
            </tr>
            <tr v-if="!loading && invoices.length === 0">
                <td colspan="5" class="text-center">
                    As tuas faturas do serviço <i>clica.ai premium</i> irão aparecer aqui
                </td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
import store from '@/store/store'
import { mapState, mapGetters } from 'vuex'
import { authComputed } from '@/store/helpers.js'

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    components: {
    },
    data() {
        return {
            updatingInvoice: null
        }
    },
    computed: {
        ...mapState({
            stripeObject: state => state.stripe.stripeObject,
            invoices: state => state.billing.invoices
        })
    },
    methods: {
        setUpdatingInvoice (invoiceNumber) {
            this.updatingInvoice = invoiceNumber
        },
        async openPayInvoiceModal (invoiceId, invoiceNumber, invoiceTotal, clientSecret) {
            this.$root.$emit('openPayInvoiceModal', invoiceId, invoiceNumber, invoiceTotal, clientSecret, this.setUpdatingInvoice)
        },
        async attemptAuth (invoiceId, invoiceNumber, clientSecret) {
            try {
                this.setUpdatingInvoice(invoiceNumber)
                const res = await this.stripeObject.confirmCardPayment(clientSecret)

                if (res.error) {
                    await store.dispatch('notification/add', {
                        status: 'error',
                        category: 'card',
                        message: 'Falha na autenticação. Tenta efetuar o pagamento de novo.'
                    }, { root: true })

                    if (res.error.code === 'payment_intent_authentication_failure' || res.error.code === 'payment_intent_incompatible_payment_method')
                        await store.dispatch('billing/setInvoiceStatus', { id: invoiceId, status: 'requires_payment_method' }, { root: true })
                } else {
                    if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
                        // show notification
                        await store.dispatch('notification/add', {
                            status: 'success',
                            category: 'card',
                            message: 'Pagamento efetuado com sucesso!'
                        }, { root: true })

                        // update invoice table to show paid invoice
                        await store.dispatch('billing/setInvoiceStatus', { id: this.invoiceId, status: 'paid' }, { root: true })
                    } else {
                        console.log('error in attemptAuth')
                        console.log(err)
                    }
                }
            } catch (err) {
                console.log(err)
                console.log(err.type)
                console.log(err.code)
            } finally {
                this.setUpdatingInvoice(null)
            }
        }
    },
};
</script>

<style scoped>
</style>
