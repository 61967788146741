<template>
    <div v-if="!loading">
      <!-- HEADER -->
      <div class="header">
        <div class="container-fluid">
          <div class="header-body">
            <div class="row align-items-end">
              <div class="col">
                <!-- Pretitle -->
                <h6 class="header-pretitle">
                  Visão geral
                </h6>

                <!-- Title -->
                <h1 class="header-title">
                  Painel clica.ai
                </h1>
              </div>
              <!-- <div class="col-auto">
                <a href="#!" class="btn btn-primary lift">
                  Create Report
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- CARDS -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-8 col-xl">
              <CardCreateSlug />
          </div>
          <div class="col-12 col-lg-4 col-xl">
              <CardSmallText
                  title="Cliques"
                  :headingText="clickCount"
                  icon="fe-loader"
              />
              <CardSmallText
                  title="Miniaturas"
                  :headingText="slugCount"
                  icon="fe-list"
              />
          </div>
        </div>

        <div class="row" v-if="systemMessage">
            <div class="col-12">
                <div class="alert alert-warning text-center" role="alert">
                    <strong>{{ systemMessage }}</strong>
                </div>
            </div>
        </div>

        <div class="row" v-if="showPremiumAlert">
            <div class="col-12">
                <div class="alert alert-info alert-dismissible fade show text-center" role="alert">
                    <span class="alert-link" style="cursor: pointer;" @click="openTrialModal"><u>Experimenta a versão paga do clica.ai</u></span> sem compromissos nem cartões de crédito. Os primeiros 3 meses são por nossa conta.

                    <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" @click="dismissPremiumAlert">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="alert alert-light text-center" role="alert">
                    <strong>Os cliques dos utilizadores são processados periodicamente e poderão demorar até 1 hora a serem contabilizados.</strong>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
              <CardTableSlugsBase
                  type="free"
                  title="As minhas miniaturas"
                  :rows="urlList"
                  :totalRows="Number(slugCount)"
                  :rowsReceivedCallback="this.storeSlugs"
              />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import CardTableSlugsBase from '@/components/cards/CardTableSlugsBase'
import CardSmallText from '@/components/cards/CardSmallText'
import CardCreateSlug from '@/components/cards/CardCreateSlug'

import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'

import DashboardDataProcessing from '@/mixins/dashboardDataProcessing'
import { getStorage, setStorage, hasStorage } from '@/assets/localStorage.js'

export default {
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    mixins: [DashboardDataProcessing],
    components: {
        CardCreateSlug,
        CardTableSlugsBase,
        CardSmallText
    },
    data () {
        return {
            showPremiumAlert: true
        }
    },
    methods: {
        storeSlugs(slugs) {
            const processed = this.processSlugTable(slugs)
            return store.dispatch('dashboard/setPartialUrlData', processed, { root: true })
        },
        dismissPremiumAlert() {
            setStorage('settings', 'showPremiumAlert', false)
            this.showPremiumAlert = false
        },
        openTrialModal() {
            this.$root.$emit('openTrialModal')
        }
    },
    beforeMount() {
        // check if we are to hide the premium alert
        this.showPremiumAlert = !this.hasTriedPremium
        if(hasStorage('settings')) {
            this.showPremiumAlert = this.showPremiumAlert && getStorage('settings', 'showPremiumAlert')
        }
    },
    computed: mapState({
        urlList: state => state.dashboard.urlList,
        clickCount: state => state.dashboard.clickCount,
        slugCount: state => state.dashboard.slugCount,
        hasTriedPremium: state => state.user.hasTriedPremium,
        systemMessage: state => state.dashboard.systemMessage
    })
}
</script>

<style lang="css" scoped>
</style>
