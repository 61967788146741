<template lang="html">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
                <!-- Image -->
                <div class="text-center">
                <img src="assets/img/illustrations/lost.svg" alt="..." class="img-fluid">
                </div>
            </div>
            <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                <div class="text-center">
                    <!-- Preheading -->
                    <h6 class="text-uppercase text-muted mb-4">
                        Erro 404
                    </h6>

                    <!-- Heading -->
                    <h1 class="display-4 mb-3">
                        Não conseguimos encontrar a página 😭
                    </h1>

                    <!-- Subheading -->
                    <p class="text-muted mb-4">
                        Parece que chegaste aqui por acidente?
                    </p>

                    <!-- Button -->
                    <button @click="routerBack" class="btn btn-lg btn-primary">
                        Voltar atrás...
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    beforeRouteEnter(routeTo, routeFrom, next) {
        const classes = ["d-flex", "align-items-center", "bg-auth", "border-top", "border-top-2", "border-primary"]
        document.body.classList.add(...classes)
        next()
    },
    beforeRouteLeave(routeTo, routeFrom, next) {
        const classes = ["d-flex", "align-items-center", "bg-auth", "border-top", "border-top-2", "border-primary"]
        document.body.classList.remove(...classes)
        next()
    },
    methods: {
        routerBack() {
            this.$router.go(-1)
        }
    }
};
</script>

<style lang="css">
</style>
