export const namespaced = true

export const state = {
    stripeObject: null
}

export const mutations = {
    SET_STRIPE_OBJECT(state, data) {
        state.stripeObject = data
    },
}

export const actions = {
    setStripeObject({ commit, dispatch }, data) {
        if(!state.stripeObject)
            commit('SET_STRIPE_OBJECT', Stripe(data))
    },
}
