export const namespaced = true

export const state = {
    countryCode: 'PT',
    exchangeRateBrl: 5.9
}

export const mutations = {
    SET_COUNTRY_CODE(state, data) {
        state.countryCode = data
    },
    SET_EXCHANGE_RATE_BRL(state, data) {
        state.exchangeRateBrl = data
    }
}

export const actions = {
    setCountryCode({ commit, dispatch }, data) {
        commit('SET_COUNTRY_CODE', data)
    },
    setExchangeRateBrl({ commit, dispatch }, data) {
        commit('SET_EXCHANGE_RATE_BRL', data)
    },
    cleanup({ commit, dispatch }, data) {
        commit('SET_COUNTRY_CODE', 'PT')
        commit('SET_EXCHANGE_RATE_BRL', 5.9)
    },
}
