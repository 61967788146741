export default {
    functional: true,
    props: {
        messages: {
            type: Array
        }
    },
    render(createElement, context) {
        let validations = []
        for(let i = 0; i < context.props.messages.length; i++) {
            const v = context.props.messages[i]

            if (v.condition && v.message.length > 0)
                validations.push(createElement('span', {
                    attrs: {
                        class: 'invalid-feedback'
                    }
                }, v.message))
        }

        return validations
    }
}
