<template>
<div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xl-8">
        <div class="header mt-md-5">
            <div class="header-body">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="header-pretitle">
                            Miniaturas
                        </h6>
                        <h1 class="header-title">
                            Pagamento bem sucedido!
                        </h1>
                        <h3 class="header-subtitle">
                            A tua miniatura ficará pronta nos próximos minutos
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-2">
            <div class="col-8">
                <h3 class="card-title">A tua miniatura clica.ai:</h3>

                <p class="mb-1">
                    <code>
                    <span>{{ namespace }}</span><span class="font-weight-bold">{{ slug }}</span>
                    </code>
                </p>
                <p class="small text-muted mb-0">
                    {{ destination }}
                </p>
            </div>
            <div class="col-4 align-self-center">
                <BaseButton id="copyButtonSlug" buttonExternalClasses="copy-btn btn-lg" :data-clipboard-text="namespace + slug" @click="showTooltip">
                    Copiar
                </BaseButton>
            </div>
        </div>

        <hr/>

        <span class="btn btn-lg btn-block btn-primary" style="cursor: pointer;" @click="resetCreateSlug()">
            Criar outra miniatura
        </span>

        <router-link class="btn btn-block btn-link text-muted" style="cursor: pointer;" :to="{ name: 'home', params: { useCache: false } }">
            Ir para o meu painel
        </router-link>
        </div>
</div>
</template>

<script>
import store from '@/store/store'
import { mapState, mapGetters } from 'vuex'

import ClipboardJS from 'clipboard'

export default {
    props: {
        parentResetCreateSlug: {
            type: Function,
            required: false
        }
    },
    computed: {
        ...mapState({
            destination: state => state.createSlug.slugData.destination,
            namespace: state => state.createSlug.slugData.namespace,
            slug: state => state.createSlug.slugData.slug,
        })
    },
    mounted() {
        // activate clipboard.js
        const clipboard = new ClipboardJS('#copyButtonSlug')

        // activate Bootstrap tooltips
        $('#copyButtonSlug').tooltip({
            title: 'Copiado!',
            placement: 'bottom',
            trigger: 'manual'
        })
    },
    methods: {
        showTooltip() {
            // show tooltip and hide after 3 seconds
            $('#copyButtonSlug').tooltip('show')

            setTimeout(() => {
                $('#copyButtonSlug').tooltip('hide')
            }, 3000)
        },
        resetCreateSlug() {
            if (this.parentResetCreateSlug) {
                this.parentResetCreateSlug()
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
