export default {
    methods: {
        isoDateToHuman: (isoDate) => {
            //console.log(isoDate);
            const date = new Date(isoDate)

            const day = `${date.getDate()}`.padStart(2, '0')
            const month = `${date.getMonth()+1}`.padStart(2, '0')
            const year = `${date.getFullYear()}`.padStart(2, '0')

            const h = `${date.getHours()}`.padStart(2, '0')
            const m = `${date.getMinutes()}`.padStart(2, '0')

            //console.log(day + '/' + month + '/' + year + ' ' + h + ':' + m);
            return day + '/' + month + '/' + year + ' ' + h + ':' + m
        },
        epochToHumanDate: (epoch) => {
            const date = new Date(epoch)

            const day = `${date.getDate()}`.padStart(2, '0')
            const month = `${date.getMonth()+1}`.padStart(2, '0')
            const year = `${date.getFullYear()}`.padStart(2, '0')

            return day + '/' + month + '/' + year
        },
        epochToHumanDateTime: (epoch) => {
            const date = new Date(epoch)

            const day = `${date.getDate()}`.padStart(2, '0')
            const month = `${date.getMonth()+1}`.padStart(2, '0')
            const year = `${date.getFullYear()}`.padStart(2, '0')
            const hour = `${date.getHours()}`.padStart(2, '0')
            const min = `${date.getMinutes()}`.padStart(2, '0')

            return day + '/' + month + '/' + year + ' ' + hour + ':' + min
        },
        readbleElapsedTimeMinutes: (time) => {
            if (time < 60) {
                // return time in minutes
                return time + 'm'
            } else if(time < 1440) {
                // return time in hours and minutes
                const h = Math.floor(time / 60)
                const m = time - (h * 60)
                return h + 'h ' + m + 'm'
            } else {
                // return time in days, hours and minutes
                const d = Math.floor(time / 1440)
                const h = Math.floor((time - (d * 1440)) / 60)
                const m = time - (d * 1440) - (h * 60)
                return d + 'd ' + h + 'h ' + m + 'm'
            }
        }
    }
}
