<template>
<div class="modal fade" ref="removeCardModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Remover cartão de crédito
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="card-body" v-if="showMessage">
                    <p>A tua subscrição foi cancelada com sucesso.</p>

                    <span class="text-muted">
                        <ul>
                            <li v-if="showMessage.total > 0">Pagaste {{ showMessage.paid }} faturas de um total de {{ showMessage.total }} faturas pendentes.</li>
                            <li v-if="showMessage.lastFail">Não conseguimos efetuar o pagamento da fatura do ciclo atual. Terás de completar o seu pagamento para reativar o serviço premium.</li>
                        </ul>
                    </span>

                    <hr />

                    <span class="btn btn-block btn-link text-muted" style="cursor: pointer;" data-dismiss="modal">
                        Fechar
                    </span>
                </div>

                <div class="card-body" v-else>
                    <p>{{ confirmationString }}</p>

                    <!-- Removal of card only results in premium loss if user was already premium and has no other card -->
                    <span class="text-muted" v-if="userType === 'premium' && isLastCard">
                        <ul>
                            <li>Ao removeres o teu último cartão de crédito vais desativar o teu <i>clica.ai premium</i>!</li>
                            <li>Se pretendes apenas <b>substituir o teu cartão</b>, insere o novo cartão no sistema antes de apagar o antigo. Não serão cobrados valores em duplicado.</li>
                            <li>Qualquer valor pendente até ao momento presente será cobrado ao teu cartão de crédito. Poderás sempre voltar a subscrever o serviço no futuro, as tuas estatísticas continuarão guardadas!</li>
                        </ul>
                    </span>

                    <hr />

                    <form @submit.prevent="deleteCard">
                        <BaseButton type="submit" :status="buttonStatus">
                            {{ userType === 'premium' && isLastCard ? 'Remover e terminar premium' : 'Remover' }}
                        </BaseButton>

                        <span class="btn btn-block btn-link text-muted" style="cursor: pointer;" data-dismiss="modal">
                            Cancelar
                        </span>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'

export default {
    props: {
        statusSetter: {
            type: Function
        },
        isLastCard: {
            type: Boolean
        },
        cardData: {
            type: Object
        }
    },
    computed: {
        ...mapGetters({
            pending: 'api/isPending'
        }),
        ...mapState({
            ...authComputed // loggedIn and userType prop
        }),
        isPending() {
            // get api status for button feedback
            return this.pending('deleteBillingCard')
        },
        buttonStatus() {
            return this.isPending ? 'loading' : 'default'
        },
        cardNumber() {
            return this.cardData? this.cardData.number : null
        },
        confirmationString() {
            return this.cardNumber? `Tens a certeza de que queres remover o cartão terminado em ${this.cardNumber}?` : `Tens a certeza de que queres remover o cartão adicionado?`
        }
    },
    data() {
        return {
            showMessage: null
        }
    },
    mounted () {
        $(this.$refs.removeCardModal).on('hidden.bs.modal', this.modalHidden)
    },
    methods: {
        async deleteCard () {
            this.statusSetter(this.cardData.id)
            try {
                const res = await store.dispatch('api/deleteBillingCard', { id: this.cardData.id, notify: false })

                // remove card from UI
                await store.dispatch('billing/removeCardLocal', this.cardData.id, { root: true })

                if (res.data.body.newDefault)
                    await store.dispatch('billing/setDefaultCard', res.data.body.newDefault, { root: true })

                // show message from backend
                this.showMessage = res.data.body.msg

                // show subscription disabled in UI
                if (this.userType === 'premium' && this.isLastCard)
                    await store.dispatch('billing/disableSubscriptionLocal', { }, { root: true })
            } catch (err) {
                console.log(err)
            } finally {
                this.statusSetter(null)

                if (!this.showMessage)
                    $(this.$refs.removeCardModal).modal('hide')
            }
        },
        modalHidden() {
            this.showMessage = null
        }
    }
};
</script>

<style lang="css" scoped>
</style>
