import { URLService } from '@/services/URLService'

/* POSSIBLE API CALL STATES */
const API_STATE = {
    PENDING: 'pending',
    OK: 'ok',
    FAIL_SERVER: 'fail_server',
    FAIL: 'fail'
}

/* PT STRINGS FOR USER FEEDBACK */
const STRINGS = {
    SERVER_ERROR: 'Erro do servidor',
    REGISTER_SUCCESS: 'Se o email não estiver registado, a tua conta será criada. Por favor, verifica o teu email!',
    VERIFY_SUCCESS: 'Conta verificada com sucesso',
    LOGIN_SUCCESS: 'Autenticado com sucesso',
    LOGOUT_SUCCESS: 'Saiste com sucesso!',
    REPORT_URL_SUCCESS: 'Denúncia submetida com sucesso!',
    REQUEST_PASSWORD_RESET_SUCCESS: 'Se houver uma conta registada com o email indicado, irás receber um email para redefinir a tua palavra-passe!',
    RESET_PASSWORD_SUCCESS: 'A tua palavra-passe foi redefinida com sucesso! Podes entrar na tua conta com as novas credenciais',
    SEND_CONTACT_FORM_SUCCESS: 'Mensagem enviada com sucesso',
    BLOCK_SLUG_SUCCESS: 'Miniatura bloqueada com sucesso',
    CHANGE_SLUG_DESTINATION_SUCCESS: 'Destino alterado com sucesso',
}

////////////////////////////////////////////////////////////////////////////////

const apiCallAndDispatchState = (commit, dispatch, data, notifySuccess = true, notifyError = true) => {
    commit(data.mutation, API_STATE.PENDING)

    return new Promise((resolve, reject) => {
        data.apiCall
            .then((res) => {
                commit(data.mutation, API_STATE.OK)
                if(notifySuccess)
                    dispatchNotification(dispatch, 'success', data.category, data.successMessage)
                return resolve(res)
            })
            .catch((err) => {
                if(!err || !err.response || !err.response.data) {
                    commit(data.mutation, API_STATE.FAIL_SERVER)
                    if(notifyError)
                        dispatchNotification(dispatch, 'error', data.category, STRINGS.SERVER_ERROR)
                } else {
                    const userMessage = err.response.data.error
                    commit(data.mutation, API_STATE.FAIL)
                    if(notifyError)
                        dispatchNotification(dispatch, 'error', data.category, userMessage)
                }

                return reject(err)
            })
    })
}

const dispatchNotification = (dispatch, status, category, message) => {
    const notification = {
        status: status,
        category: category,
        message: message
    }

    // this should come as argument (whether to timeout and when)
    if(category === 'login' && status === 'success')
        notification.timeout = 2000

    dispatch('notification/add', notification, { root: true })
}

const defaultApiState = () => {
    return {
        status: '',
        timestamp: new Date(0)
    }
}

const defaultMutation = (state, category, status) => {
    state[category].status = status
    state[category].timestamp = Date.now()
}

export const namespaced = true

////////////////////////////////////////////////////////////////////////////////

export const state = {
    register: defaultApiState(),
    verify: defaultApiState(),
    login: defaultApiState(),
    logout: defaultApiState(),
    reportUrl: defaultApiState(),
    requestPasswordReset: defaultApiState(),
    resetPassword: defaultApiState(),
    fetchUrlList: defaultApiState(),
    fetchDashboard: defaultApiState(),
    updateCustomerBillingData: defaultApiState(),
    clearCustomerBillingData: defaultApiState(),
    requestBillingSetupIntent: defaultApiState(),
    deleteBillingCard: defaultApiState(),
    setBillingDefaultCard: defaultApiState(),
    fetchCustomerData: defaultApiState(),
    fetchSlugData: defaultApiState(),
    sendContactForm: defaultApiState(),
    blockSlug: defaultApiState(),
    changeSlugDestination: defaultApiState(),
    adminGetUserData: defaultApiState(),
    adminGetSlugData: defaultApiState(),
    adminGetClickData: defaultApiState(),
    adminGetHealthData: defaultApiState(),
    adminGetEmail: defaultApiState(),
    adminSendEmail: defaultApiState(),
    adminSetSystemMessage: defaultApiState(),
    adminGetSystemMessage: defaultApiState(),
    adminDeleteSlug: defaultApiState(),
    accountChangePassword: defaultApiState(),
    accountChangeEmail: defaultApiState(),
    accountStartTrial: defaultApiState(),
    createSlug: defaultApiState(),
    cancelSlugCreation: defaultApiState(),
    renewSlugPaymentData: defaultApiState(),
    requestSlugInvoice: defaultApiState(),
    getSlugInvoice: defaultApiState(),
    getBillingInvoice: defaultApiState(),
    payInvoice: defaultApiState(),
}

export const mutations = {
    REGISTER(state, status) {
        defaultMutation(state, 'register', status)
    },
    VERIFY(state, status) {
        defaultMutation(state, 'verify', status)
    },
    LOGIN(state, status) {
        defaultMutation(state, 'login', status)
    },
    LOGOUT(state, status) {
        defaultMutation(state, 'logout', status)
    },
    REPORT_URL(state, status) {
        defaultMutation(state, 'reportUrl', status)
    },
    REQUEST_PASSWORD_RESET(state, status) {
        defaultMutation(state, 'requestPasswordReset', status)
    },
    RESET_PASSWORD(state, status) {
        defaultMutation(state, 'resetPassword', status)
    },
    FETCH_URL_LIST(state, status) {
        defaultMutation(state, 'fetchUrlList', status)
    },
    FETCH_DASHBOARD(state, status) {
        defaultMutation(state, 'fetchDashboard', status)
    },
    UPDATE_CUSTOMER_BILLING_DATA(state, status) {
        defaultMutation(state, 'updateCustomerBillingData', status)
    },
    CLEAR_CUSTOMER_BILLING_DATA(state, status) {
        defaultMutation(state, 'clearCustomerBillingData', status)
    },
    REQUEST_BILLING_SETUPINTENT(state, status) {
        defaultMutation(state, 'requestBillingSetupIntent', status)
    },
    SET_BILLING_DEFAULT_CARD(state, status) {
        defaultMutation(state, 'setBillingDefaultCard', status)
    },
    DELETE_BILLING_CARD(state, status) {
        defaultMutation(state, 'deleteBillingCard', status)
    },
    FETCH_CUSTOMER_DATA(state, status) {
        defaultMutation(state, 'fetchCustomerData', status)
    },
    FETCH_SLUG_DATA(state, status) {
        defaultMutation(state, 'fetchSlugData', status)
    },
    SEND_CONTACT_FORM(state, status) {
        defaultMutation(state, 'sendContactForm', status)
    },
    BLOCK_SLUG(state, status) {
        defaultMutation(state, 'blockSlug', status)
    },
    CHANGE_SLUG_DESTINATION(state, status) {
        defaultMutation(state, 'changeSlugDestination', status)
    },
    ADMIN_GET_USER_DATA(state, status) {
        defaultMutation(state, 'adminGetUserData', status)
    },
    ADMIN_GET_SLUG_DATA(state, status) {
        defaultMutation(state, 'adminGetSlugData', status)
    },
    ADMIN_GET_CLICK_DATA(state, status) {
        defaultMutation(state, 'adminGetClickData', status)
    },
    ADMIN_GET_HEALTH_DATA(state, status) {
        defaultMutation(state, 'adminGetHealthData', status)
    },
    ADMIN_GET_EMAIL(state, status) {
        defaultMutation(state, 'adminGetEmail', status)
    },
    ADMIN_SEND_EMAIL(state, status) {
        defaultMutation(state, 'adminSendEmail', status)
    },
    ADMIN_SET_SYSTEM_MESSAGE(state, status) {
        defaultMutation(state, 'adminSetSystemMessage', status)
    },
    ADMIN_GET_SYSTEM_MESSAGE(state, status) {
        defaultMutation(state, 'adminGetSystemMessage', status)
    },
    ADMIN_DELETE_SLUG(state, status) {
        defaultMutation(state, 'adminDeleteSlug', status)
    },
    ACCOUNT_CHANGE_PASSWORD(state, status) {
        defaultMutation(state, 'accountChangePassword', status)
    },
    ACCOUNT_CHANGE_EMAIL(state, status) {
        defaultMutation(state, 'accountChangeEmail', status)
    },
    ACCOUNT_START_TRIAL(state, status) {
        defaultMutation(state, 'accountStartTrial', status)
    },
    CREATE_SLUG(state, status) {
        defaultMutation(state, 'createSlug', status)
    },
    CANCEL_SLUG_CREATION(state, status) {
        defaultMutation(state, 'cancelSlugCreation', status)
    },
    RENEW_SLUG_PAYMENT_DATA(state, status) {
        defaultMutation(state, 'renewSlugPaymentData', status)
    },
    REQUEST_SLUG_INVOICE(state, status) {
        defaultMutation(state, 'requestSlugInvoice', status)
    },
    GET_SLUG_INVOICE(state, status) {
        defaultMutation(state, 'getSlugInvoice', status)
    },
    GET_BILLING_INVOICE(state, status) {
        defaultMutation(state, 'getBillingInvoice', status)
    },
    PAY_INVOICE(state, status) {
        defaultMutation(state, 'payInvoice', status)
    },
}

export const actions = {
    register({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.register_user(payload.email, payload.password, payload.recaptchaToken),
            mutation: 'REGISTER',
            category: 'register',
            successMessage: STRINGS.REGISTER_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    verify({ commit, dispatch }, token) {
        const data = {
            apiCall: URLService.verify_user(token),
            mutation: 'VERIFY',
            category: 'verify',
            successMessage: STRINGS.VERIFY_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    login({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.login(payload.email, payload.password, payload.rememberMe, payload.recaptchaToken),
            mutation: 'LOGIN',
            category: 'login',
            successMessage: STRINGS.LOGIN_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
            .then((data) => {
                // if login is successful store user data in state and local storage
                dispatch('userLogin', data.data.body, { root: true })

                return Promise.resolve(data) // ensure data is passed to the outside if needed
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    },
    logout({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.logout(payload.token),
            mutation: 'LOGOUT',
            category: 'logout',
            successMessage: STRINGS.LOGOUT_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data, false)
            .then((data) => {
                // clear user data in state and local storage
                dispatch('userLogout', null, { root: true })
                return Promise.resolve(data)
            })
            .catch((err) => {
                // clear user data in state and local storage
                dispatch('userLogout', null, { root: true })
                return Promise.reject(err)
            })
    },
    reportUrl({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.report_url(payload.motive, payload.slug, payload.comment, payload.recaptchaToken),
            mutation: 'REPORT_URL',
            category: 'reportUrl',
            successMessage: STRINGS.REPORT_URL_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    requestPasswordReset({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.requestPasswordReset(payload.email, payload.recaptchaToken),
            mutation: 'REQUEST_PASSWORD_RESET',
            category: 'requestPasswordReset',
            successMessage: STRINGS.REQUEST_PASSWORD_RESET_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    resetPassword({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.resetPassword(payload.password, payload.userId, payload.resetToken, payload.recaptchaToken),
            mutation: 'RESET_PASSWORD',
            category: 'resetPassword',
            successMessage: STRINGS.RESET_PASSWORD_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    fetchUserUrls({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.urlList(payload.useCache, payload.start, payload.limit, payload.sort, payload.order),
            mutation: 'FETCH_URL_LIST',
            category: 'fetchUrlList',
            successMessage: ''
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    fetchUserDashboard({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.dashboard(payload.useCache, payload.limitRows),
            mutation: 'FETCH_DASHBOARD',
            category: 'fetchDashboard',
            successMessage: ''
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    updateCustomerBillingData({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.billingSetBillingData(payload.name, payload.address, payload.postalCode, payload.town, payload.country, payload.phone, payload.vatNumber),
            mutation: 'UPDATE_CUSTOMER_BILLING_DATA',
            category: 'updateCustomerBillingData',
            successMessage: ''
        }

        return apiCallAndDispatchState(commit, dispatch, data, false, false)
    },
    clearCustomerBillingData({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.billingClearBillingData(),
            mutation: 'CLEAR_CUSTOMER_BILLING_DATA',
            category: 'clearCustomerBillingData',
            successMessage: ''
        }

        return apiCallAndDispatchState(commit, dispatch, data, false, false)
    },
    requestBillingSetupIntent({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.billingRequestSetupIntent(),
            mutation: 'REQUEST_BILLING_SETUPINTENT',
            category: 'requestBillingSetupIntent',
            successMessage: ''
        }

        return apiCallAndDispatchState(commit, dispatch, data, true, false)
    },
    setBillingCardDefault({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.billingSetDefaultCard(payload),
            mutation: 'SET_BILLING_DEFAULT_CARD',
            category: 'setBillingDefaultCard',
            successMessage: ''
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    deleteBillingCard({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.billingDeleteCard(payload.id),
            mutation: 'DELETE_BILLING_CARD',
            category: 'deleteBillingCard',
            successMessage: 'Cartão apagado com sucesso'
        }

        return apiCallAndDispatchState(commit, dispatch, data, !!payload.notify, true)
    },
    fetchCustomerData({ commit, dispatch }, useCache = true) {
        const data = {
            apiCall: URLService.getCustomerData(useCache),
            mutation: 'FETCH_CUSTOMER_DATA',
            category: 'fetchCustomerData',
            successMessage: ''
        }

        return apiCallAndDispatchState(commit, dispatch, data, false)
    },
    fetchSlugData({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.getSlugData(payload),
            mutation: 'FETCH_SLUG_DATA',
            category: 'fetchSlugData',
            successMessage: ''
        }

        return apiCallAndDispatchState(commit, dispatch, data, false)
    },
    sendContactForm({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.sendContactForm(payload.type, payload.subject, payload.message),
            mutation: 'SEND_CONTACT_FORM',
            category: 'sendContactForm',
            successMessage: STRINGS.SEND_CONTACT_FORM_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    blockSlug({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.blockSlug(payload.slug, payload.reason),
            mutation: 'BLOCK_SLUG',
            category: 'blockSlug',
            successMessage: STRINGS.BLOCK_SLUG_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    changeSlugDestination({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.changeSlugDestination(payload.slug, payload.newDestination),
            mutation: 'CHANGE_SLUG_DESTINATION',
            category: 'changeSlugDestination',
            successMessage: STRINGS.CHANGE_SLUG_DESTINATION_SUCCESS
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminGetUserData({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.adminGetUserData(),
            mutation: 'ADMIN_GET_USER_DATA',
            category: 'adminGetUserData'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminGetSlugData({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.adminGetSlugData(),
            mutation: 'ADMIN_GET_SLUG_DATA',
            category: 'adminGetSlugData'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminGetClickData({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.adminGetClickData(),
            mutation: 'ADMIN_GET_CLICK_DATA',
            category: 'adminGetClickData'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminGetHealthData({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.adminGetHealthData(),
            mutation: 'ADMIN_GET_HEALTH_DATA',
            category: 'adminGetHealthData'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminGetEmail({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.adminGetEmail(),
            mutation: 'ADMIN_GET_EMAIL',
            category: 'adminGetEmail'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminSendEmail({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.adminSendEmail(payload.fromAddress, payload.to, payload.subject, payload.body),
            mutation: 'ADMIN_SEND_EMAIL',
            category: 'adminSendEmail'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminSetSystemMessage({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.adminSetSystemMessage(payload.message),
            mutation: 'ADMIN_SET_SYSTEM_MESSAGE',
            category: 'adminSetSystemMessage'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminGetSystemMessage({ commit, dispatch }) {
        const data = {
            apiCall: URLService.adminGetSystemMessage(),
            mutation: 'ADMIN_GET_SYSTEM_MESSAGE',
            category: 'adminGetSystemMessage'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    adminDeleteSlug({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.adminDeleteSlug(payload.slug),
            mutation: 'ADMIN_DELETE_SLUG',
            category: 'adminDeleteSlug'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    accountChangePassword({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.accountChangePassword(payload.currentPassword, payload.newPassword),
            mutation: 'ACCOUNT_CHANGE_PASSWORD',
            category: 'accountChangePassword',
            successMessage: 'Palavra-passe alterada com sucesso'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    accountChangeEmail({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.accountChangeEmail(payload.currentPassword, payload.newEmail),
            mutation: 'ACCOUNT_CHANGE_EMAIL',
            category: 'accountChangeEmail',
            successMessage: 'Email alterado com sucesso'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    accountStartTrial({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.accountStartTrial(),
            mutation: 'ACCOUNT_START_TRIAL',
            category: 'accountStartTrial',
            successMessage: 'Período de teste iniciado com sucesso!'
        }

        return apiCallAndDispatchState(commit, dispatch, data)
    },
    createSlug({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.create_slug(payload.destination, payload.token, payload.slugType, payload.desiredSlug, payload.promoCode),
            mutation: 'CREATE_SLUG',
            category: 'createSlug'
        }

        return apiCallAndDispatchState(commit, dispatch, data, payload.notify, payload.notify)
    },
    cancelCreateSlug({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.cancelSlugReservation(payload.slug),
            mutation: 'CANCEL_SLUG_CREATION',
            category: 'cancelSlugCreation'
        }

        return apiCallAndDispatchState(commit, dispatch, data, payload.notify, payload.notify)
    },
    renewSlugPaymentData({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.renewSlugPaymentData(payload.slug, payload.fromEmail),
            mutation: 'RENEW_SLUG_PAYMENT_DATA',
            category: 'renewSlugPaymentData'
        }

        return apiCallAndDispatchState(commit, dispatch, data, payload.notify, payload.notify)
    },
    requestSlugInvoice({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.requestSlugInvoice(payload.slug, payload.name, payload.address, payload.postalCode, payload.town, payload.country, payload.phone, payload.vatNumber),
            mutation: 'REQUEST_SLUG_INVOICE',
            category: 'requestSlugInvoice'
        }

        return apiCallAndDispatchState(commit, dispatch, data, payload.notify, payload.notify)
    },
    getSlugInvoice({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.getSlugInvoice(payload.slug),
            mutation: 'GET_SLUG_INVOICE',
            category: 'getSlugInvoice'
        }

        return apiCallAndDispatchState(commit, dispatch, data, payload.notify, payload.notify)
    },
    getBillingInvoice({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.getBillingInvoice(payload.number),
            mutation: 'GET_BILLING_INVOICE',
            category: 'getBillingInvoice'
        }

        return apiCallAndDispatchState(commit, dispatch, data, payload.notify, payload.notify)
    },
    payInvoice({ commit, dispatch }, payload) {
        const data = {
            apiCall: URLService.payInvoice(payload.action, payload.number, payload.paymentMethodId),
            mutation: 'PAY_INVOICE',
            category: 'payInvoice'
        }

        return apiCallAndDispatchState(commit, dispatch, data, true, false)
    },
}

////////////////////////////////////////////////////////////////////////////////

export const getters = {
    isPending: (state) => (category) => {
        return state[category].status === API_STATE.PENDING
    }
}
