<template>
    <div v-if="!loading">
        <!-- HEADER -->
        <HomeHeader
            :dataset="dates"
        />

      <!-- CARDS -->
      <div class="container-fluid mt-n6">
          <div class="row" v-if="systemMessage">
              <div class="col-12">
                  <div class="alert alert-warning text-center" role="alert">
                      <strong>{{ systemMessage }}</strong>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-12 col-xl-8">
                    <CardBarChart
                        title="Horas"
                        :dataLabel="['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h']"
                        :data="hours"
                    />
                </div>
                <div class="col-12 col-xl-4">
                    <CardPieChart
                        id="miscDataChart"
                        title="Tipos de Dispositivos"
                        :dataLabel="['Computador', 'Tablet', 'Móvel']"
                        :data="misc"
                    />
                </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-4">
                <CardSmallText
                    title="Total de Cliques"
                    :headingText="clickCount"
                    icon="fe-loader"
                />
              </div>
              <div class="col-12 col-xl-4">
                <CardSmallText
                    title="Número de Miniaturas"
                    :headingText="slugCount"
                    icon="fe-list"
                />
              </div>
              <div class="col-12 col-xl-4">
                <CardSmallText
                    title="Número de Utilizadores"
                    :headingText="userCount"
                    icon="fe-loader"
                />
              </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <CardLineChart
                        :dataset="cumulativeGraphs"
                    />
                </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-4">
                <CardPieChart
                    id="osDataChart"
                    title="Sistemas Operativos"
                    :dataLabel="osLabels"
                    :data="os"
                />
              </div>
              <div class="col-12 col-xl-4">
                <CardPieChart
                    id="browsersDataChart"
                    title="Navegadores"
                    :dataLabel="browserLabels"
                    :data="browsers"
                />
              </div>
              <div class="col-12 col-xl-4">
                <CardTable
                    title="Dispositivos Mais Populares"
                    :header="[{title: 'Dispositivo'}, {title: 'Número de cliques'}]"
                    :rowElements="['model', 'freq']"
                    :rows="devices"
                    showEmptyString="Amostra de dispositivos muito pequena para mostrar"
                />
              </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <CardTableAdminUrl
                        title="Miniaturas no sistema"
                        :rows="urlList"
                        :totalRows="Number(slugCount)"
                        :rowsReceivedCallback="this.storeSlugs"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardMap from '@/components/cards/CardMap'
import CardTable from '@/components/cards/CardTable'
import CardTableAdminUrl from '@/components/cards/CardTableAdminUrl'
import CardList from '@/components/cards/CardList'
import CardPieChart from '@/components/cards/CardPieChart'
import CardLineChart from '@/components/cards/CardLineChart'
import CardBarChart from '@/components/cards/CardBarChart'
import CardSmallText from '@/components/cards/CardSmallText'
import CardSmallProgress from '@/components/cards/CardSmallProgress'
import CardSparklineChart from '@/components/cards/CardSparklineChart'
import HomeHeader from '@/components/HomeHeader'

import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'

import DashboardDataProcessing from '@/mixins/dashboardDataProcessing'

export default {
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    mixins: [DashboardDataProcessing],
    components: {
        CardTable,
        CardTableAdminUrl,
        CardList,
        CardPieChart,
        CardLineChart,
        CardBarChart,
        CardSmallText,
        CardSmallProgress,
        CardSparklineChart,
        HomeHeader,
        CardMap
    },
    methods: {
        storeSlugs(slugs) {
            const processed = this.processSlugTable(slugs)
            return store.dispatch('dashboard/setPartialUrlData', processed, { root: true })
        }
    },
    computed: mapState({
        urlList: state => state.dashboard.urlList,
        clickCount: state => state.dashboard.clickCount,
        slugCount: state => state.dashboard.slugCount,
        userCount: state => state.dashboard.userCount,
        hours: state => state.dashboard.hours,
        dates: state => state.dashboard.dates,
        misc: state => state.dashboard.misc,
        osLabels: state => state.dashboard.osLabels,
        os: state => state.dashboard.os,
        deviceLabels: state => state.dashboard.deviceLabels,
        devices: state => state.dashboard.devices,
        browserLabels: state => state.dashboard.browserLabels,
        browsers: state => state.dashboard.browsers,
        cumulativeGraphs: state => state.dashboard.cumulativeGraphs,
        systemMessage: state => state.dashboard.systemMessage
    })
};
</script>

<style scoped></style>
