<template lang="html">
  <h1>This is the user verify page. {{ token }}</h1>
</template>

<script>
import store from '@/store/store'

export default {
    props: {
        token: {
            type: String
        }
    },
    beforeRouteEnter(routeTo, routeFrom, next) {
        if(!routeTo.query['token']) {
            // dispatch error to store
            const notification = {
                status: 'error',
                category: 'verify',
                message: 'Token de verificação inválido'
            }

            store.dispatch('notification/add', notification)
            next({ name: 'login' })
        } else {
            //NProgress.start()
            store.dispatch('api/verify', routeTo.query['token'])
                .then((res) => {
                    //NProgress.done()
                    console.log(res)
                    next({ name: 'login' })
                })
                .catch((err) => {
                    //NProgress.done()
                    console.log(err)
                    next({ name: 'login' })
                })
        }
    }
}
</script>

<style lang="css">
</style>
