<template>
<div class="row">
    <div class="col-12">
        <div class="form-group">
            <label for="name">Nome</label>
            <BaseInput id="name" placeholder="Nome (primeiro e último)" v-model.trim="billingData.name" type="text"
                @blur="$v.billingData.name.$touch()"
                :dirty="$v.billingData.name.$dirty"
                :error="$v.billingData.name.$error"
                @keyup="checkValid"
            />
            <BaseValidation :messages="getValidationObjects().name" />
        </div>
    </div>

    <div class="col-12">
        <div class="form-group">
            <label class="mb-1" for="address">Endereço</label>
            <BaseInput id="address" placeholder="Endereço" v-model.trim="billingData.address" type="text"
                @blur="$v.billingData.address.$touch()"
                :dirty="$v.billingData.address.$dirty"
                :error="$v.billingData.address.$error"
                @keyup="checkValid"
            />
            <BaseValidation :messages="getValidationObjects().address" />
        </div>
    </div>

    <div class="col-8 col-md-4">
        <div class="form-group">
            <label for="postal_code">Código Postal / CEP</label>
            <BaseInput id="postal_code" placeholder="Código Postal / CEP" v-model.trim="billingData.postalCode" type="text"
                @blur="$v.billingData.postalCode.$touch()"
                :dirty="$v.billingData.postalCode.$dirty"
                :error="$v.billingData.postalCode.$error"
                @keyup="checkValid"
            />
            <BaseValidation :messages="getValidationObjects().postalCode" />
        </div>
    </div>

    <div class="col-4 col-md-4">
        <div class="form-group">
            <label for="town">Localidade</label>
            <BaseInput id="town" placeholder="Localidade" v-model.trim="billingData.town" type="text"
                @blur="$v.billingData.town.$touch()"
                :dirty="$v.billingData.town.$dirty"
                :error="$v.billingData.town.$error"
                @keyup="checkValid"
            />
            <BaseValidation :messages="getValidationObjects().town" />
        </div>
    </div>

    <div class="col-12 col-md-4">
        <div class="form-group">
            <label for="country">País</label>
            <BaseSelect id="country" label="País" placeholder="País" v-model="billingData.country" :options="countries"
            :validationMessages="getValidationObjects().country"
                @blur="$v.billingData.country.$touch()"
                :dirty="$v.billingData.country.$dirty"
                :error="$v.billingData.country.$error"
                @change="checkValid"
            />
            <BaseValidation :messages="getValidationObjects().country" />
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="phone">Contacto Telefónico</label>
            <BaseInput id="phone" placeholder="Contacto Telefónico" v-model.trim="billingData.phone" type="text"
                @blur="$v.billingData.phone.$touch()"
                :dirty="$v.billingData.phone.$dirty"
                :error="$v.billingData.phone.$error"
                @keyup="checkValid"
            />
            <BaseValidation :messages="getValidationObjects().phone" />
        </div>

    </div>

    <div class="col-12 col-md-6">
        <div class="form-group">
            <label for="nif">NIF / CPF</label>
            <BaseInput id="nif" placeholder="NIF / CPF" v-model.trim="billingData.vatNumber" type="text"
                @blur="$v.billingData.vatNumber.$touch()"
                :dirty="$v.billingData.vatNumber.$dirty"
                :error="$v.billingData.vatNumber.$error"
                @keyup="checkValid"
            />
            <BaseValidation :messages="getValidationObjects().vatNumber" />
        </div>
    </div>

<!-- Divider -->
<!-- <hr class="mt-4 mb-5">

<div class="row">
    <div class="col-12 col-md-6">
        <div class="form-group">
            <label class="mb-1">
                Allow for additional Bookings
            </label>

            <small class="form-text text-muted">
                If you are available for hire outside of the current situation, you can encourage others to hire you.
            </small>

            <div class="row">
                <div class="col-auto">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="switchTwo">
                        <label class="custom-control-label" for="switchTwo"></label>
                    </div>
                </div>
                <div class="col ml-n2">
                    <small class="text-muted">
                        You're currently available
                    </small>
                </div>
            </div>
        </div>
    </div>
</div> -->
</div>
</template>

<script>
import store from '@/store/store'
import { mapGetters } from 'vuex'
import { authComputed } from '@/store/helpers.js'

import { required, minLength } from 'vuelidate/lib/validators'

const countryList = require('@/assets/countries.json') /*TODO handle nulls*/

export default {
    props: {
        validityCallback: {
            type: Function,
            required: true
        },
        setDataCallback: {
            type: Function,
            required: true
        },
        preexistingData: {
            type: Object
        }
    },
    data () {
        return {
            countries: countryList,
            billingData: {
                name: '',
                address: '',
                postalCode: '',
                town: '',
                country: '',
                phone: '',
                vatNumber: '',
            }
        }
    },
    computed: {
        validationStatus () {
            return !(this.$v.$pending || this.$v.$error || this.$v.$invalid)
        }
    },
    created () {
        if (this.preexistingData)
            this.billingData = preexistingData
    },
    methods: {
        checkValid (ev) {
            this.validityCallback(this.validationStatus)

            if (this.validationStatus)
                this.setDataCallback(this.billingData)
        },
        getValidationObjects() {
            return {
                name: [
                    { condition: !this.$v.billingData.name.required, message: 'Nome obrigatório' },
                    { condition: !this.$v.billingData.name.minLength, message: 'Nome muito curto' }
                ],
                address: [
                    { condition: !this.$v.billingData.address.required, message: 'Endereço obrigatório' },
                    { condition: !this.$v.billingData.address.minLength, message: 'Endereço muito curto' }
                ],
                postalCode: [
                    { condition: !this.$v.billingData.postalCode.required, message: 'Código postal obrigatório' },
                    { condition: !this.$v.billingData.postalCode.minLength, message: 'Código postal muito curto' }
                ],
                town: [
                    { condition: !this.$v.billingData.town.required, message: 'Localidade obrigatória' },
                    { condition: !this.$v.billingData.town.minLength, message: 'Localidade muito curta' }
                ],
                country: [
                    { condition: !this.$v.billingData.country.required, message: 'País obrigatório' }
                ],
                phone: [
                    { condition: !this.$v.billingData.phone.required, message: 'Telefone obrigatório' },
                    { condition: !this.$v.billingData.phone.minLength, message: 'Telefone muito curto' }
                ],
                vatNumber: [
                    { condition: !this.$v.billingData.vatNumber.required, message: 'NIF obrigatório' },
                    { condition: !this.$v.billingData.vatNumber.minLength, message: 'NIF muito curto' }
                ]
            }
        },
    },
    validations: {
        billingData : {
            name: {
                required,
                minLength: minLength(5)
            },
            address: {
                required,
                minLength: minLength(3)
            },
            postalCode: {
                required,
                minLength: minLength(4)
            },
            town: {
                required,
                minLength: minLength(3)
            },
            country: {
                required
            },
            phone: {
                required,
                minLength: minLength(9)
            },
            vatNumber: {
                required,
                minLength: minLength(9)
            }
        }
    }
};
</script>

<style lang="css" scoped>
</style>
