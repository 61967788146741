export const namespaced = true

export const state = {

}

export const mutations = {
    SET_DATA(state, data) {
        for (const key in data)
            state[key] = data[key]
    }
}

export const actions = {
    setData({ commit, dispatch }, data) {
        commit('SET_DATA', data)
    }
}
