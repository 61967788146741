<template>
<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="z-index: 100;">
    <div class="toast-header">
        <strong class="mr-auto">{{ title }}</strong>
        <small class="text-muted">{{ this.epochToHumanDate(date) }}</small>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Fechar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="toast-body">
        {{ body }}
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'

export default {
    props: {
        type: {
            type: String,
            required: true
        },
        date: {
            type: String,
            required: true
        },
        content: {
            type: Object
        }
    },
    computed: {
        title() {
            switch (this.type) {
                case 'payment_failed':
                    return 'Pagamento falhou'
                case 'needs_auth':
                    return 'Autorização necessária'
                default:
                    return 'Notificação'
            }
        },
        body() {
            if (this.type === 'needs_auth')
                return 'Um pagamento premium precisa da autorização 3D secure para ser processado. Verifica a tua secção de pagamentos.'

            if (this.type === 'payment_failed')
                return `${this.getHumanErrorCode(this.content.error_code)}${this.getHumanDeclineCode(this.content.decline_code)}`

            return this.content
        }
    },
    methods: {
        getHumanErrorCode(errorCode) {
            switch (errorCode) {
                case 'card_declined':
                    return `Um pagamento recente com o teu cartão foi recusado.`
                default:
                    return `Não conseguimos efetuar uma cobrança do teu serviço premium.`
            }
        },
        getHumanDeclineCode(declineCode) {
            switch (declineCode) {
                case 'generic_decline':
                default:
                    return ``
            }
        }
    }
};
</script>

<style scoped>
</style>
