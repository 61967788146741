<template>
<div class="main-content">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 col-xl-8">
                <div class="header mt-md-5">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <h1 class="header-title">
                                    Mensagem de sistema
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Form -->
                <form class="mb-4" @submit.prevent="setSystemMessage" v-if="!loading">
                    <div class="form-group">
                        <label for="message">Mensagem</label>
                        <BaseTextArea id="message" placeholder="Escreve aqui a mensagem" :resizable="false" v-model.trim="message" />
                    </div>

                    <div class="form-group" v-if="lastMessageDate">
                        <label for="message">Data (última mensagem)</label>
                        <BaseInput v-model="lastMessageDate" type="text" disabled />
                    </div>

                    <!-- Divider -->
                    <hr class="mt-5 mb-5">

                    <BaseButton type="submit" :status="buttonStatus">
                        Submeter
                    </BaseButton>

                    <router-link class="btn btn-block btn-link text-muted" :to="{ name: 'home' }">
                        Cancelar
                    </router-link>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/store'

export default {
    data () {
        return {
            message: '',
            lastMessageDate: null,
            loading: false,
            submitPending: false
        }
    },
    computed: {
        buttonStatus() {
            return this.submitPending ? 'loading' : 'default'
        }
    },
    async created () {
        NProgress.start()
        this.loading = true

        try {
            const res = await store.dispatch('api/adminGetSystemMessage')

            if (res.data.body.message) {
                this.message = res.data.body.message
                this.lastMessageDate = this.isoDateToHuman(res.data.body.date)
            }
        } catch (err) {
            console.log('error from server...')
            console.log(err)
        } finally {
            NProgress.done()
            this.loading = false
        }
    },
    methods: {
        async setSystemMessage () {
            NProgress.start()
            this.submitPending = true

            try {
                const res = await this.$store.dispatch('api/adminSetSystemMessage', {
                    message: this.message
                })

                console.log('submission successful, cleaning...')
            } catch (err) {
                console.log('error from server...')
                console.log(error)
            } finally {
                NProgress.done()
                this.submitPending = false
            }
        }
    }
}
</script>

<style lang="css">
</style>
