<template>
    <input :value="value" @input="updateValue" v-on="listeners" v-bind="$attrs" class="form-control" :class="computedClasses">
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        value: [String, Number],
        additionalClasses: {
            type: String,
            default: ''
        },
        dirty: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        listeners() {
            return { // vue mastery S04E11, conflict between listeners
                ...this.$listeners,
                input: this.updateValue
            }
        },
        computedClasses() {
            let classes = this.additionalClasses

            if (this.dirty)
                return this.error ? classes + ' is-invalid' : classes + ' is-valid'
            else
                return classes
        }
    },
    methods: {
        updateValue(event) {
            this.$emit('input', event.target.value)
        }
    }
}
</script>
