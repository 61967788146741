<template>
    <div class="main-content">
        <template v-if="!loading">
            <div class="header">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-end">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    Vista de Administração
                                </h6>

                                <h1 class="header-title">
                                    Estado do Serviço
                                </h1>
                            </div>
                            <!-- <div class="col-auto">
                                    <a href="#!" class="btn btn-primary lift">
                                        Create Report
                                    </a>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <CardSmallText
                            title="Último registo"
                            :headingText="healthData.last_registration.epoch + ' (' + healthData.last_registration.elapsed + ')'"
                            icon="fe-users"
                        />
                    </div>
                    <div class="col-12 col-xl-6">
                        <CardSmallText
                            title="Último clique miniatura"
                            :headingText="String(healthData.last_slug_click.epoch + ' (' + healthData.last_slug_click.elapsed + ')')"
                            icon="fe-minimize"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <CardSmallText
                            title="Último erro 'S3-grabber'"
                            :headingText="healthData.last_error_s3? healthData.last_error_s3.epoch + ' (' + healthData.last_error_s3.elapsed + ')' : '-'"
                            icon="fe-alert-triangle"
                        />
                    </div>
                    <div class="col-12 col-xl-6">
                        <CardSmallText
                            title="Último erro 'db-logger'"
                            :headingText="healthData.last_error_db? healthData.last_error_db.epoch + ' (' + healthData.last_error_db.elapsed + ')' : '-'"
                            icon="fe-alert-triangle"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-6">
                        <CardTableScriptLogs
                            title="Execuções 'S3-grabber'"
                            :header="[{title: 'Execução'}, {title: 'Objectos S3 lidos'}, {title: 'Tempo GET S3'}, {title: 'Cliques lidos'}, {title: 'Tempo processamento cliques'}, {title: 'Objectos S3 ignorados'}]"
                            :rowElements="['epoch', 's3_objects', 's3_time_taken', 'processing_clicks', 'processing_time_taken', 's3_objects_ignored']"
                            :rows="healthData.s3_grabber"
                        />
                    </div>
                    <div class="col-12 col-xl-6">
                        <CardTableScriptLogs
                            title="Execuções 'db-logger'"
                            :header="[{title: 'Execução'}, {title: 'Cliques processados'}, {title: 'Tempo'}]"
                            :rowElements="['epoch', 'processed_clicks', 'time_taken']"
                            :rows="healthData.db_click_logger"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CardMap from '@/components/cards/CardMap'
import CardTable from '@/components/cards/CardTable'
import CardTableScriptLogs from '@/components/cards/CardTableScriptLogs'
import CardList from '@/components/cards/CardList'
import CardPieChart from '@/components/cards/CardPieChart'
import CardBarChart from '@/components/cards/CardBarChart'
import CardSmallText from '@/components/cards/CardSmallText'
import CardSmallProgress from '@/components/cards/CardSmallProgress'
import CardSparklineChart from '@/components/cards/CardSparklineChart'
import HomeHeader from '@/components/HomeHeader'

import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'

export default {
    components: {
        CardTable,
        CardTableScriptLogs,
        CardList,
        CardPieChart,
        CardBarChart,
        CardSmallText,
        CardSmallProgress,
        CardSparklineChart,
        HomeHeader,
        CardMap
    },
    data () {
        return {
            loading: false
        }
    },
    created () {
        NProgress.start()
        this.loading = true

        store.dispatch('api/adminGetHealthData')
            .then((res) => {
                const healthData = _.cloneDeep(res.data.body.health_data)

                // convert dates to human readable format
                healthData.s3_grabber = healthData.s3_grabber.map((el) => {
                    el.epoch = this.isoDateToHuman(el.epoch)
                    el.s3_time_taken = el.s3_time_taken.toFixed(2)
                    el.processing_time_taken = el.processing_time_taken.toFixed(2)
                    return el
                })

                healthData.db_click_logger = healthData.db_click_logger.map((el) => {
                    el.epoch = this.isoDateToHuman(el.epoch)
                    el.time_taken = el.time_taken.toFixed(2)
                    return el
                })

                if(healthData.last_error_s3) {
                    healthData.last_error_s3.epoch = this.isoDateToHuman(healthData.last_error_s3.epoch)
                    healthData.last_error_s3.elapsed = this.readbleElapsedTimeMinutes(healthData.last_error_s3.elapsed)
                }


                if(healthData.last_error_db) {
                    healthData.last_error_db.epoch = this.isoDateToHuman(healthData.last_error_db.epoch)
                    healthData.last_error_db.elapsed = this.readbleElapsedTimeMinutes(healthData.last_error_db.elapsed)
                }

                healthData.last_slug_click.epoch = this.isoDateToHuman(healthData.last_slug_click.epoch)
                healthData.last_slug_click.elapsed = this.readbleElapsedTimeMinutes(healthData.last_slug_click.elapsed)

                healthData.last_registration.epoch = this.isoDateToHuman(healthData.last_registration.epoch)
                healthData.last_registration.elapsed = this.readbleElapsedTimeMinutes(healthData.last_registration.elapsed)

                store.dispatch('adminData/setAdminHealthData', healthData, { root: true })
                    .then((res) => {
                        NProgress.done()
                        this.loading = false
                    })
            })
            .catch((err) => {
                console.log(err)
                // TODO error page
            })
    },
    computed: mapState({
        healthData: state => state.adminData.health
    })
};
</script>

<style scoped></style>
