const slugData = {
    destination: null,
    namespace: null,
    slug: null,
}

const paymentData = {
    paymentIntentClientSecret: null,
    serverSideCostEur: null,
    serverSideCostEurOriginal: null,
    timeLimit: null
}

export const namespaced = true

export const state = {
    slugData: Object.assign({}, slugData),
    paymentData: Object.assign({}, paymentData)
}

export const mutations = {
    SET_SLUG_DATA(state, data) {
        state.slugData = data
    },
    SET_PAYMENT_DATA(state, data) {
        state.paymentData = data
    }
}

export const actions = {
    setSlugData({ commit, dispatch }, data) {
        commit('SET_SLUG_DATA', data)
    },
    clearSlugData({ commit, dispatch }) {
        commit('SET_SLUG_DATA', Object.assign({}, slugData))
    },
    setPaymentData({ commit, dispatch }, data) {
        commit('SET_PAYMENT_DATA', data)
    },
    clearPaymentData({ commit, dispatch }) {
        commit('SET_PAYMENT_DATA', Object.assign({}, paymentData))
    }
}
