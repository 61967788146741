export default {
    methods: {
        getErrorString(error) {
            return this.getErrorStringDirect(error.decline_code || error.code) || error.message
        },
        getErrorStringDirect(code) {
            switch (code) {
                case 'card_declined':
                case 'generic_decline':
                    return 'Cartão recusado. Por favor, tenta de novo ou usa outro cartão.'
                case 'insufficient_funds':
                    return 'Saldo insuficiente.'
                case 'payment_intent_authentication_failure':
                    return 'Não foi possível autenticar o teu método de pagamento. Por favor, tenta de novo ou usa outro cartão.'
                case 'expired_card':
                    return 'Cartão expirado.'
                case 'incorrect_cvc':
                    return 'Código de segurança errado.'
                case 'processing_error':
                    return 'Erro no processamento.'
                case 'resource_missing':
                    return 'Falha no pagamento. Por favor, atualiza a página e tenta de novo.'
                case 'lost_card':
                    return 'O teu banco deu o teu cartão como perdido. Por favor, usa outro cartão.'
                default:
                    return null
            }
        },
        getCardImage(brand) {
            if (['alipay', 'amex', 'diners', 'discover', 'elo', 'hipercard', 'jcb', 'maestro', 'mastercard', 'paypal', 'unionpay', 'verve', 'visa'].indexOf(brand) >= 0)
                return `/assets/img/payment-methods/${brand}.svg`
            else
                return `/assets/img/payment-methods/default.svg`
        },
        getHumanPrice (price) {
            // TODO BRL
            return `${(price / 100.0).toFixed(2).replace('.', ',')}€`
        },
    }
}
