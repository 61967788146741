<template lang="html">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">

                <!-- Heading -->
                <h1 class="display-4 text-center mb-3">
                    Entrar
                </h1>

                <!-- Subheading -->
                <p class="text-muted text-center mb-5">
                    A tua conta clica.ai
                </p>

                <!-- Form -->
                <form @submit.prevent="loginUser">
                    <div class="form-group">
                        <label for="inputEmail">Email</label>
                        <BaseInput
                            id="inputEmail" placeholder="O teu email" v-model.trim="email" type="text"
                            @blur="$v.email.$touch()"
                            :dirty="$v.email.$dirty"
                            :error="$v.email.$error"
                        />
                        <BaseValidation
                            :messages="getValidationObjects().email"
                        />
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <div class="col">
                                <label for="inputPassword">Palavra-passe</label>
                            </div>
                            <div class="col-auto">
                                <router-link :to="{ name: 'recover-password-request'}" class="form-text small text-muted">
                                    Esqueci-me da palavra-passe
                                </router-link>
                            </div>
                        </div>

                        <div class="input-group input-group-merge">
                            <BaseInput
                                id="inputPassword" placeholder="A tua palavra-passe" v-model="password" :type="ui.viewPassword? 'text' : 'password'"
                                additionalClasses="form-control-appended"
                                @blur="$v.password.$touch()"
                                :dirty="$v.password.$dirty"
                                :error="$v.password.$error"
                            />
                            <div class="input-group-append">
                                <span class="btn input-group-text" :class="{ 'text-dark': ui.viewPassword }" style="cursor: pointer;" @click="togglePasswordVisibility">
                                    <i class="fe fe-eye"></i>
                                </span>
                            </div>
                            <BaseValidation
                                :messages="getValidationObjects().password"
                            />
                        </div>
                    </div>

                    <vue-recaptcha @verify="onCaptchaVerified" @expired="onCaptchaExpired" ref="loginRecaptcha" :sitekey="constants.recaptchaKeyLogin" size="invisible"></vue-recaptcha>

                    <BaseButton type="submit" :status="buttonStatus" buttonExternalClasses="mb-3">
                        Entrar
                    </BaseButton>

                    <div class="text-center">
                        <small class="text-muted text-center">
                            <router-link :to="{ name: 'register'}">Criar uma conta clica.ai</router-link>
                        </small>
                    </div>
                </form>

            </div>
            <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
                <div class="bg-cover vh-100 mt-n1 mr-n3" :style="'background-image: url(assets/img/covers/' + uiCover + ')'"></div>
            </div>
      </div>
    </div>
</template>

<script>// TODO route guard if logged in (CLICA-85)
import { mapGetters } from 'vuex'
import { authComputed } from '@/store/helpers.js'
import VueRecaptcha from 'vue-recaptcha'
import { URLService } from '@/services/URLService'
import { required, email } from 'vuelidate/lib/validators'

export default {
    beforeRouteEnter(routeTo, routeFrom, next) {
        const classes = ["d-flex", "align-items-center", "bg-auth", "border-top", "border-top-2", "border-primary"]
        document.body.classList.add(...classes);
        next()
    },
    beforeRouteLeave(routeTo, routeFrom, next) {
        const classes = ["d-flex", "align-items-center", "bg-auth", "border-top", "border-top-2", "border-primary"]
        document.body.classList.remove(...classes);
        next()
    },
    data() {
        return {
            email: '',
            password: '',
            rememberMe: false,
            ui: {
                viewPassword: false
            }
        }
    },
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    computed: {
        ...authComputed,
        ...mapGetters({ pending: 'api/isPending' }),
        isLoginPending() {
            // get login api status for button feedback
            return this.pending('login')
        },
        buttonStatus() {
            return this.$v.$invalid? 'disabled' : (this.isLoginPending? 'loading' : 'default')
        },
        uiCover() {
            return 'auth-cover-lisbon3.jpg'
        }
    },
    methods: {
        getValidationObjects() {
            return {
                email: [
                    { condition: !this.$v.email.email, message: 'Email inválido' },
                    { condition: !this.$v.email.required, message: 'Email obrigatório' }
                ],
                password: [
                    { condition: !this.$v.password.required, message: 'Palavra-passe obrigatória' }
                ]
            }
        },
        loginUser() {
            this.$v.$touch() // makes the form dirty to show errors
            if (this.$v.$pending || this.$v.$error || this.$v.$invalid)
                return

            this.$refs.loginRecaptcha.execute()
        },
        onCaptchaExpired(err) {
            this.$refs.loginRecaptcha.reset()
        },
        onCaptchaVerified(recaptchaToken) {
            this.$refs.loginRecaptcha.reset()
            let payload = {
                email: this.email,
                password: this.password,
                rememberMe: this.rememberMe,
                recaptchaToken: recaptchaToken
            }

            this.$store.dispatch('api/login', payload)
                .then((res) => {
                    // remove notifications of other categories
                    this.$store.dispatch('notification/removeCategory', {
                        category: 'login',
                        toKeep: ['success']
                    })

                    this.$store.dispatch('notification/removeCategory', {
                        category: 'unauthorised',
                        toKeep: ['success']
                    })

                    this.$router.push({ name: 'home' })
                })
                .catch((err) => {
                    console.log("err")
                    console.log(err)
                })
        },
        togglePasswordVisibility() {
            this.ui.viewPassword = !this.ui.viewPassword
        }
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required
        }
    }
}
</script>


<style lang="css">
</style>
