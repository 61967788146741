<template lang="html">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">

                <!-- Heading -->
                <h1 class="display-4 text-center mb-3">
                    Cria a tua conta clica.ai
                </h1>

                <!-- Subheading -->
                <p class="text-muted text-center mb-5">
                    Preenche os teus dados no formulário
                </p>

                <!-- Form -->
                <form @submit.prevent="registerUser">
                    <div class="form-group">
                        <label for="inputEmail">Email</label>
                        <BaseInput
                            id="inputEmail" placeholder="O teu email" v-model.trim="email" type="text"
                            @blur="$v.email.$touch()"
                            :dirty="$v.email.$dirty"
                            :error="$v.email.$error"
                        />
                        <BaseValidation
                            :messages="getValidationObjects().email"
                        />
                    </div>

                    <div class="form-group">
                        <label for="inputPassword">Palavra-passe</label>
                        <div class="input-group input-group-merge">
                            <BaseInput
                                id="inputPassword" placeholder="Palavra-passe" v-model="password" :type="ui.viewPassword? 'text' : 'password'"
                                additionalClasses="form-control-appended"
                                @blur="$v.password.$touch()"
                                :dirty="$v.password.$dirty"
                                :error="$v.password.$error"
                            />

                            <div class="input-group-append">
                                <span class="btn input-group-text" :class="{ 'text-dark': ui.viewPassword }" style="cursor: pointer;" @click="togglePasswordVisibility('inputPassword')">
                                    <i class="fe fe-eye"></i>
                                </span>
                            </div>

                            <BaseValidation
                                :messages="getValidationObjects().password"
                            />
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="inputConfirmPassword">Confirmação de Palavra-passe</label>
                        <div class="input-group input-group-merge">
                            <BaseInput
                                id="inputConfirmPassword" placeholder="Confirmação da palavra-passe" v-model="passwordConfirmation" :type="ui.viewPasswordConfirmation? 'text' : 'password'"
                                additionalClasses="form-control-appended"
                                @blur="$v.passwordConfirmation.$touch()"
                                :dirty="$v.passwordConfirmation.$dirty"
                                :error="$v.passwordConfirmation.$error || $v.password.$error"
                            />
                            <!-- The confirmation has error if password has error two... Feels more user-friendly. Check in getValidationObjects for similar logic -->

                            <div class="input-group-append">
                                <span class="btn input-group-text" :class="{ 'text-dark': ui.viewPasswordConfirmation }" style="cursor: pointer;" @click="togglePasswordVisibility('inputConfirmPassword')">
                                    <i class="fe fe-eye"></i>
                                </span>
                            </div>

                            <BaseValidation
                                :messages="getValidationObjects().passwordConfirmation"
                            />
                        </div>
                    </div>

                    <vue-recaptcha @verify="onCaptchaVerified" @expired="onCaptchaExpired" ref="registerRecaptcha" :sitekey="constants.recaptchaKeyRegister" size="invisible"></vue-recaptcha>

                    <BaseButton type="submit" :status="buttonStatus" buttonExternalClasses="mb-3">
                        Registar
                    </BaseButton>

                    <div class="text-center">
                        <small class="text-muted text-center">
                            Já tens conta no clica.ai? <router-link :to="{ name: 'login'}">Entra aqui</router-link>.
                        </small>
                    </div>
                </form>

            </div>
            <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
                <div class="bg-cover vh-100 mt-n1 mr-n3" :style="'background-image: url(assets/img/covers/' + uiCover + ')'"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import { URLService } from '@/services/URLService'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import { owaspStrong, owaspTest, owaspToPT } from '@/validators'

export default {
    beforeRouteEnter(routeTo, routeFrom, next) {
        const classes = ["d-flex", "align-items-center", "bg-auth", "border-top", "border-top-2", "border-primary"]
        document.body.classList.add(...classes);
        next()
    },
    beforeRouteLeave(routeTo, routeFrom, next) {
        const classes = ["d-flex", "align-items-center", "bg-auth", "border-top", "border-top-2", "border-primary"]
        document.body.classList.remove(...classes);
        next()
    },
    data() {
        return this.createFreshUserRegistrationObject()
    },
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    computed: {
        ...mapGetters({ pending: 'api/isPending' }),
        isRegisterPending() {
            return this.pending('register')
        },
        buttonStatus() {
            return this.$v.$invalid? 'disabled' : (this.isRegisterPending? 'loading' : 'default')
        },
        uiCover() {
            return 'auth-cover-lisbon2.jpg'
        }
    },
    methods: {
        createFreshUserRegistrationObject() {
            return {
                email: '',
                password: '',
                passwordConfirmation: '',
                ui: {
                    viewPassword: false,
                    viewPasswordConfirmation: false
                }
            }
        },
        getValidationObjects() {
            let passwordErrors = owaspTest(this.password).errors;

            let errorsPT = owaspToPT(passwordErrors).map(e => {
                return {
                    condition: true,
                    message: e
                }
            })

            return {
                email: [
                    { condition: !this.$v.email.email, message: 'Email inválido' },
                    { condition: !this.$v.email.required, message: 'Email obrigatório' }
                ],
                password: [
                    { condition: !this.$v.password.required, message: 'Palavra-passe obrigatória' },
                    ...errorsPT
                ],
                passwordConfirmation: [
                    { condition: !this.$v.passwordConfirmation.required, message: 'Confirmação de palavra-passe obrigatória' },
                    { condition: !this.$v.passwordConfirmation.sameAsPassword, message: 'Tem de ser igual à palavra-passe' },
                    { condition: this.$v.password.$error, message: 'Palavra-passe tem problemas' } // show as red too if password is bad yet
                ]
            }
        },
        registerUser() {
            this.$v.$touch() // makes the form dirty to show errors
            if (this.$v.$pending || this.$v.$error || this.$v.$invalid)
                return

            this.$refs.registerRecaptcha.execute()
        },
        onCaptchaExpired(err) {
            this.$refs.registerRecaptcha.reset()
        },
        onCaptchaVerified(recaptchaToken) {
            this.$refs.registerRecaptcha.reset()
            let payload = {
                email: this.email,
                password: this.password,
                recaptchaToken: recaptchaToken
            }

            this.$store.dispatch('api/register', payload)
                .then((res) => {
                    this.$router.push({ name: 'login' })
                })
                .catch((err) => {
                })
        },
        togglePasswordVisibility(element) {
            switch (element) {
                case 'inputPassword':
                    this.ui.viewPassword = !this.ui.viewPassword
                    break
                case 'inputConfirmPassword':
                    this.ui.viewPasswordConfirmation = !this.ui.viewPasswordConfirmation
                    break
            }
        }
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required,
            owaspStrong
        },
        passwordConfirmation: {
            required,
            sameAsPassword: sameAs('password')
        }
    }
}
</script>

<style lang="css">
</style>
