const hasStorage = (item, key = null) => {
    if (key) {
        return typeof getStorage(item, key) !== 'undefined'
    } else {
        return localStorage.getItem(item)
    }
}

const getStorage = (item, key = null) => {
    const storage = localStorage.getItem(item)

    if (!key)
        return JSON.parse(storage)

    if (storage)
        return JSON.parse(storage)[key]
}

const setStorage = (item, key, value) => {
    const storage = JSON.parse(localStorage.getItem(item)) || {}
    storage[key] = value
    localStorage.setItem(item, JSON.stringify(storage))
}

const removeStorage = (item, key = null) => {
    if (key)
        setStorage(item, key, null)
    else
        localStorage.removeItem(item)
}

module.exports = { hasStorage, getStorage, setStorage, removeStorage }
