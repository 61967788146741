<template>
<form class="mb-4" @submit.prevent="changeAccountData">
    <!-- <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label class="mb-1">
                    Endereço de email
                </label>

                <small class="form-text text-muted">
                    Vais precisar de confirmar o novo email antes da mudança ficar concluída.
                </small>

                <input type="email" class="form-control">
            </div>
        </div>
    </div>

    <hr class="mt-4 mb-5">
 -->
    <div class="row">
        <div class="col-12 col-md-6 order-md-2">
            <div class="card bg-light border ml-md-4">
                <div class="card-body">
                    <p class="mb-2">
                        Requisitos de palavra-passe
                    </p>
                    <p class="small text-muted mb-2">
                        Para mudar a tua palavra-passe tens de cumprir estes requisitos:
                    </p>
                    <ul class="small text-muted pl-4 mb-0">
                        <li>Pelo menos 8 caracteres</li>
                        <li>Pelo menos uma letra maiúscula</li>
                        <li>Pelo menos uma letra minúscula</li>
                        <li>Pelo menos um número</li>
                        <li>Pelo menos um caracter especial</li>
                        <li>Não pode ter sequências de 3 ou mais caracteres iguais</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <!-- New password -->
            <div class="form-group">
                <label for="inputPassword">Nova palavra-passe</label>
                <div class="input-group input-group-merge">
                    <BaseInput
                        id="inputNewPassword" placeholder="Palavra-passe" v-model="passwordChange.newPassword" :type="ui.viewNewPassword? 'text' : 'password'"
                        additionalClasses="form-control-appended"
                        @blur="$v.passwordChange.newPassword.$touch()"
                        :dirty="$v.passwordChange.newPassword.$dirty"
                        :error="$v.passwordChange.newPassword.$error"
                    />

                    <div class="input-group-append">
                        <span class="btn input-group-text" :class="{ 'text-dark': ui.viewNewPassword }" style="cursor: pointer;" @click="togglePasswordVisibility('inputNewPassword')">
                            <i class="fe fe-eye"></i>
                        </span>
                    </div>

                    <BaseValidation
                        :messages="getValidationObjects().newPassword"
                    />
                </div>
            </div>

            <div class="form-group">
                <label for="inputConfirmPassword">Confirmar nova palavra-passe</label>
                <div class="input-group input-group-merge">
                    <BaseInput
                        id="inputConfirmNewPassword" placeholder="Confirmação da palavra-passe" v-model="passwordChange.newPasswordConfirmation" :type="ui.viewNewPasswordConfirmation? 'text' : 'password'"
                        additionalClasses="form-control-appended"
                        @blur="$v.passwordChange.newPasswordConfirmation.$touch()"
                        :dirty="$v.passwordChange.newPasswordConfirmation.$dirty"
                        :error="$v.passwordChange.newPasswordConfirmation.$error || $v.passwordChange.newPassword.$error"
                    />
                    <!-- The confirmation has error if password has error two... Feels more user-friendly. Check in getValidationObjects for similar logic -->

                    <div class="input-group-append">
                        <span class="btn input-group-text" :class="{ 'text-dark': ui.viewNewPasswordConfirmation }" style="cursor: pointer;" @click="togglePasswordVisibility('inputConfirmNewPassword')">
                            <i class="fe fe-eye"></i>
                        </span>
                    </div>

                    <BaseValidation
                        :messages="getValidationObjects().newPasswordConfirmation"
                    />
                </div>
            </div>
        </div>
    </div>

    <hr class="mt-4 mb-5">

    <div class="row">
        <div class="col-12">
            <!-- Password -->
            <div class="form-group">
                <label for="inputCurrentPassword">Palavra-passe atual</label>
                <small class="form-text text-muted">
                    Tens de inserir a tua palavra-passe atual para atualizar os teus dados pessoais.
                </small>
                <div class="input-group input-group-merge">
                    <BaseInput
                        id="inputCurrentPassword" placeholder="Palavra-passe atual" v-model="currentPassword" :type="ui.viewCurrentPassword? 'text' : 'password'"
                        additionalClasses="form-control-appended"
                        @blur="$v.currentPassword.$touch()"
                        :dirty="$v.currentPassword.$dirty"
                        :error="$v.currentPassword.$error"
                    />

                    <div class="input-group-append">
                        <span class="btn input-group-text" :class="{ 'text-dark': ui.viewCurrentPassword }" style="cursor: pointer;" @click="togglePasswordVisibility('inputCurrentPassword')">
                            <i class="fe fe-eye"></i>
                        </span>
                    </div>

                    <BaseValidation
                        :messages="getValidationObjects().currentPassword"
                    />
                </div>
            </div>

            <!-- Submit -->
            <BaseButton type="submit" :status="buttonStatus" buttonExternalClasses="lift">
                Atualizar dados
            </BaseButton>
        </div>
    </div>

</form>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'

import { required, email, sameAs } from 'vuelidate/lib/validators'
import { owaspStrong, owaspTest, owaspToPT } from '@/validators'

export default {
    data() {
        return {
            passwordChange: {
                newPassword: '',
                newPasswordConfirmation: ''
            },
            newEmail: '',
            currentPassword: '',
            loading: false,
            ui: {
                viewNewPassword: false,
                viewNewPasswordConfirmation: false,
                viewCurrentPassword: false
            }
        }
    },
    async created() {

    },
    methods: {
        getValidationObjects() {
            const passwordErrors = owaspTest(this.passwordChange.newPassword).errors;

            const errorsPT = owaspToPT(passwordErrors).map(e => {
                return {
                    condition: true,
                    message: e
                }
            })

            return {
                newEmail: [
                    { condition: !this.$v.newEmail.email, message: 'Email inválido' },
                    { condition: !this.$v.newEmail.required, message: 'Email obrigatório' }
                ],
                newPassword: [
                    { condition: !this.$v.passwordChange.newPassword.required, message: 'Palavra-passe obrigatória' },
                    ...errorsPT
                ],
                newPasswordConfirmation: [
                    { condition: !this.$v.passwordChange.newPasswordConfirmation.required, message: 'Confirmação de palavra-passe obrigatória' },
                    { condition: !this.$v.passwordChange.newPasswordConfirmation.sameAsNewPassword, message: 'Tem de ser igual à palavra-passe' },
                    { condition: this.$v.passwordChange.newPassword.$error, message: 'Palavra-passe tem problemas' } // show as red too if password is bad yet
                ],
                currentPassword: [
                    { condition: !this.$v.currentPassword.required, message: 'Palavra-passe atual obrigatória' }
                ],
            }
        },
        changeAccountData() {
            this.$v.currentPassword.$touch()

            if (this.$v.$pending || this.$v.$error || this.$v.$invalid)
                return

            // we want to change the password
            if(this.passwordChange.newPassword && this.passwordChange.newPasswordConfirmation) {
                this.changePassword()
            }
        },
        async changePassword() {
            this.loading = true
            NProgress.start()

            try {
                const res = await store.dispatch('api/accountChangePassword', {
                    currentPassword: this.currentPassword,
                    newPassword: this.passwordChange.newPassword
                })

                // reset form
                this.currentPassword = ''
                this.passwordChange = {
                    newPassword: '',
                    newPasswordConfirmation: ''
                }

                this.$v.$reset()

                this.loading = false
                NProgress.done()
            } catch (err) {
                this.loading = false
                NProgress.done()

                // TODO handle errors
            }
        },
        async changeEmail() {
            this.loading = true
            NProgress.start()

            try {
                const res = await store.dispatch('api/accountChangeEmail', {
                    currentPassword: this.currentPassword,
                    newEmail: this.newEmail
                })

                // reset form
                this.currentPassword = ''
                this.newEmail = ''

                this.$v.$reset()

                this.loading = false
                NProgress.done()
            } catch (err) {
                this.loading = false
                NProgress.done()

                // TODO handle errors
            }
        },
        togglePasswordVisibility(element) {
            switch (element) {
                case 'inputNewPassword':
                    this.ui.viewNewPassword = !this.ui.viewNewPassword
                    break
                case 'inputConfirmNewPassword':
                    this.ui.viewNewPasswordConfirmation = !this.ui.viewNewPasswordConfirmation
                    break
                case 'inputCurrentPassword':
                    this.ui.viewCurrentPassword = !this.ui.viewCurrentPassword
                    break
            }
        }
    },
    computed: mapState({
        slugStatistics: state => state.statistics,
        buttonStatus() {
            return this.loading? 'loading' : 'default'
        }
    }),
    validations: {
        passwordChange: {
            newPassword: {
                required,
                owaspStrong
            },
            newPasswordConfirmation: {
                required,
                sameAsNewPassword: sameAs('newPassword')
            }
        },
        newEmail: {
            // required,
            // email
        },
        currentPassword: {
            required
        },
    }
};
</script>
