<template>
    <i class="fe fe-help-circle text-info"
        id="helpButtonTimeLeft"
        style="cursor: help; font-size: 2em;"
        @mouseover="showTooltip"
        @mouseleave="hideTooltip"
    ></i>
</template>

<script>
export default {
    props: {
        tooltip: {
            type: String,
            required: true
        }
    },
    mounted() {
        // activate Bootstrap tooltips
        $('#helpButtonTimeLeft').tooltip({
            title: this.tooltip,
            placement: 'top',
            trigger: 'manual'
        })
    },
    methods: {
        showTooltip() {
            $('#helpButtonTimeLeft').tooltip('show')
        },
        hideTooltip() {
            $('#helpButtonTimeLeft').tooltip('hide')
        }
    }
}
</script>
