<template>
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col">
                    <!-- Title -->
                    <h6 class="card-title text-uppercase text-muted mb-2">
                        {{ title }}
                    </h6>

                    <div class="row align-items-center no-gutters">
                        <div class="col-auto">
                            <!-- Heading -->
                            <span class="h2 mr-2 mb-0">
                                {{ value }}%
                            </span>
                        </div>
                        <div class="col">
                            <!-- Progress -->
                            <div class="progress progress-sm">
                                <div class="progress-bar" role="progressbar" :style="'width: ' + Math.round(value) + '%'" :aria-valuenow="Math.round(value)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <!-- Icon -->
                    <span :class="'h2 fe ' + icon + ' text-muted mb-0'"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="css" scoped>
</style>
