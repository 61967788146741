<template>
    <router-view></router-view>
</template>
<script>
import { authComputed } from '@/store/helpers.js'

export default {
    computed: {
        ...authComputed
    }
}
</script>
<style>
.grecaptcha-badge{
	visibility: collapse !important;
}
</style>
