<template>
<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
    <div class="container-fluid">

        <!-- Toggler -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Brand -->
        <router-link class="navbar-brand" :to="{ name: 'home' }">
            <inline-svg src="/assets/img/logo.svg" class="navbar-brand-img mx-auto" alt="Clica.ai"></inline-svg>
        </router-link>

        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidebarCollapse">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'home' }">
                        <i class="fe fe-home"></i> Vista Geral
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'account-settings' }">
                        <i class="fe fe-user"></i> A minha conta
                    </router-link>
                </li>
            </ul>

            <!-- Divider -->
            <hr class="navbar-divider my-3">

            <!-- Heading -->
            <h6 class="navbar-heading">
                Admin
            </h6>

            <!-- Navigation -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'admin-health' }">
                        <i class="fe fe-activity"></i> Estado do Serviço
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'admin-clicks' }">
                        <i class="fe fe-minimize"></i> Cliques
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'admin-slugs' }">
                        <i class="fe fe-link"></i> Miniaturas
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'admin-users' }">
                        <i class="fe fe-users"></i> Utilizadores
                    </router-link>
                </li>



                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'admin-system-message' }">
                        <i class="fe fe-message-circle"></i> Mensagem de Sistema
                    </router-link>
                </li>
            </ul>

            <hr class="navbar-divider my-3">

            <!-- Heading -->
            <h6 class="navbar-heading">
                Admin E-mail
            </h6>

            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'admin-emails' }">
                        <i class="fe fe-mail"></i> Caixa de entrada
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'admin-send-email' }">
                        <i class="fe fe-send"></i> Enviar
                    </router-link>
                </li>
            </ul>

            <!-- Divider -->
            <hr class="navbar-divider my-3">

            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'contact-us' }">
                        <i class="fe fe-mail"></i> Contacta-nos
                    </router-link>
                </li>

                <li class="nav-item">
                    <template v-if="isLogoutPending">
                        <span class="nav-link text-muted" style="cursor: default;">
                            <i class="fe fe-log-out"></i> Sair
                            &nbsp;&nbsp;
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">A sair...</span>
                            </div>
                        </span>
                    </template>
                    <template v-else>
                        <router-link class="nav-link" :to="{ name: 'logout' }">
                            <i class="fe fe-log-out"></i> Sair
                        </router-link>
                    </template>
                </li>
            </ul>
        </div>
    </div>
</nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({ pending: 'api/isPending' }),
        isLogoutPending() {
            return this.pending('logout')
        },
    },
}
</script>

<style lang="css" scoped>
</style>
