<template>
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col">
                    <!-- Title -->
                    <h6 class="card-title text-uppercase text-muted mb-2">
                        {{ title }}
                    </h6>

                    <!-- Heading -->
                    <span class="h2 mb-0">
                        {{ headingText }}
                    </span>
                </div>
                <div class="col-auto">
                    <!-- Chart -->
                    <div class="chart chart-sparkline">
                        <canvas class="chart-canvas" id="sparklineChartCustom"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        const chart = document.getElementById('sparklineChartCustom');

        new Chart(chart, {
            type: 'line',
            options: {
                scales: {
                    yAxes: [{
                        display: false
                    }],
                    xAxes: [{
                        display: false
                    }]
                },
                elements: {
                    line: {
                        borderWidth: 2
                    },
                    point: {
                        hoverRadius: 0
                    }
                },
                tooltips: {
                    custom: function() {
                        return false
                    }
                }
            },
            data: {
                labels: new Array(12),
                datasets: [{
                    data: [0, 15, 10, 25, 30, 15, 40, 50, 80, 60, 55, 65]
                }]
            }
        })
    },
    props: {
        title: {
            type: String,
            required: true
        },
        headingText: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="css" scoped>
</style>
