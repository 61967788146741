<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <!-- Title -->
                    <h4 class="card-header-title">
                        Cliques
                    </h4>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col">
                    <button @click="initMap" class="btn btn-lg btn-primary">
                        Carregar
                    </button>
                    <div id="map-container" style="position: relative; width: 500px; height: 280px; border: 1px black solid;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        initMap() {
            let map = new Datamap({
                element: document.getElementById('map-container'),
                geographyConfig: {
                    dataUrl: '/assets/maps/prt.topo.json'
                },
                dataType: 'json',
                scope: 'prt',
                setProjection: function(element, options) {
                    const projection = d3.geo.mercator()
                        .center([-14.80, 38.45])
                        .scale(1700)
                        .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                    const path = d3.geo.path().projection(projection)
                    return {path: path, projection: projection}
                },
                fills: {
                   defaultFill: '#12263f',
                   highlight: '#fa0fa0'
                },
                data: {
                   'SE': { fillKey: 'highlight' },
               }
            })
        }
    },
    mounted() {
        this.initMap()
    }
};
</script>

<style lang="css" scoped>
</style>
