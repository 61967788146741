<template lang="html">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">

                <h1 class="display-4 text-center mb-3">
                    Recuperação de palavra-passe
                </h1>
                <p class="text-muted text-center mb-5">
                    Indica-nos o email com o qual registaste a tua conta.
                </p>

                <form @submit.prevent="requestPasswordReset">
                    <div class="form-group">
                        <label for="inputEmail">Email</label>
                        <BaseInput
                            id="inputEmail" placeholder="Email" v-model.trim="email" type="email"
                            @blur="$v.email.$touch()"
                            :dirty="$v.email.$dirty"
                            :error="$v.email.$error"
                        />
                        <BaseValidation
                            :messages="getValidationObjects().email"
                        />
                    </div>

                    <vue-recaptcha @verify="onCaptchaVerified" @expired="onCaptchaExpired" ref="requestPasswordResetRecaptcha" :sitekey="constants.recaptchaKeyRecoverPasswordRequest" size="invisible"></vue-recaptcha>

                    <BaseButton type="submit" :status="buttonStatus" buttonExternalClasses="mb-3">
                        Submeter
                    </BaseButton>

                    <div class="text-center">
                        <small class="text-muted text-center">
                            Lembras-te da tua palavra-passe? <router-link :to="{ name: 'login'}">Entra na tua conta clica.ai</router-link>
                        </small>
                    </div>

                </form>

            </div>
            <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
                <div class="bg-cover vh-100 mt-n1 mr-n3" :style="'background-image: url(assets/img/covers/' + uiCover + ')'"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import { required, email } from 'vuelidate/lib/validators'

export default {
    beforeRouteEnter(routeTo, routeFrom, next) {
        const classes = ["d-flex", "align-items-center", "bg-auth", "border-top", "border-top-2", "border-primary"]
        document.body.classList.add(...classes);
        next()
    },
    beforeRouteLeave(routeTo, routeFrom, next) {
        const classes = ["d-flex", "align-items-center", "bg-auth", "border-top", "border-top-2", "border-primary"]
        document.body.classList.remove(...classes);
        next()
    },
    data() {
        return this.createFreshPasswordResetRequestObject()
    },
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    computed: {
        ...mapGetters({ pending: 'api/isPending' }),
        isRequestPending() {
            return this.pending('requestPasswordReset')
        },
        buttonStatus() {
            return this.$v.$invalid? 'disabled' : (this.isRequestPending? 'loading' : 'default')
        },
        uiCover() {
            return 'auth-cover-lisbon.jpg'
        }
    },
    methods: {
        createFreshPasswordResetRequestObject() {
            return {
                email: ''
            }
        },
        getValidationObjects() {
            return {
                email: [
                    { condition: !this.$v.email.email, message: 'Email inválido' },
                    { condition: !this.$v.email.required, message: 'Email obrigatório' }
                ]
            }
        },
        requestPasswordReset() {
            this.$v.$touch() // makes the form dirty to show errors
            if (this.$v.$pending || this.$v.$error || this.$v.$invalid)
                return

            this.$refs.requestPasswordResetRecaptcha.execute()
        },
        onCaptchaExpired(err) {
            this.$refs.requestPasswordResetRecaptcha.reset()
        },
        onCaptchaVerified(recaptchaToken) {
            this.$refs.requestPasswordResetRecaptcha.reset()
            const payload = {
                email: this.email,
                recaptchaToken: recaptchaToken
            }

            this.$store.dispatch('api/requestPasswordReset', payload)
                .then((res) => {
                    this.$router.push({ name: 'login' })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        email: {
            required,
            email
        }
    }
}
</script>

<style lang="css">
</style>
