<template>
<div class="main-content">
    <div class="pt-7 pb-8 bg-dark bg-ellipses">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8 col-xl-6">
                    <h1 class="display-3 text-center text-white">
                        Preçário clica.ai
                    </h1>
                    <p class="lead text-center text-muted">
                        O plano <i>clica.ai premium</i> permite-te aceder a estatísticas detalhadas sobre a tua audiência.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row mt-n7">
            <div class="col-12 col-xl-4 offset-xl-2">
                <div class="card">
                    <div class="card-body">
                        <h6 class="text-uppercase text-center text-muted my-4">
                            Plano gratuito
                        </h6>

                        <div class="row no-gutters align-items-center justify-content-center">
                            <div class="col-auto">
                                <div class="h2 mb-0">€</div>
                            </div>
                            <div class="col-auto">
                                <div class="display-2 mb-0">0</div>
                            </div>
                        </div>

                        <div class="h6 text-uppercase text-center text-muted mb-5">
                            / mês
                        </div>

                        <div class="">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Miniaturas ilimitadas</small> <i class="fe fe-check-circle text-success"></i>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Cliques totais</small> <i class="fe fe-check-circle text-success"></i>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Último clique (por miniatura)</small> <i class="fe fe-check-circle text-success"></i>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Registo de estatísticas (acessíveis com <i>premium</i>)</small> <i class="fe fe-check-circle text-success"></i>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Apoio ao cliente</small> <i class="fe fe-check-circle text-success"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <h6 class="text-uppercase text-center text-muted my-4">
                            Plano premium
                        </h6>

                        <div class="row no-gutters align-items-center justify-content-center">
                            <div class="col-auto">
                                <div class="h2 mb-0">€</div>
                            </div>
                            <div class="col-auto">
                                <div class="display-2 mb-0">Flexível</div>
                            </div>
                        </div>

                        <div class="h6 text-uppercase text-center text-muted mb-5">
                            / mês
                        </div>

                        <div class="mb-3">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Estatísticas individualizadas por miniatura</small> <i class="fe fe-check-circle text-success"></i>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Horas / Dispositivos / Locais mais populares</small> <i class="fe fe-check-circle text-success"></i>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Preço por miniatura criada (no período de faturação)</small> <small>0,50€</small>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Preço por clique (no período de faturação)</small> <small>0,005€</small>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                                    <small>Taxa de serviço mensal</small> <small>4,99€</small>
                                </li>
                            </ul>
                        </div>

                        <router-link :to="{ name: 'account-billing' }" class="btn btn-block btn-primary">
                            Subscrever com o meu cartão
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card card-inactive">
                    <div class="card-body">
                        <h3 class="text-center">
                            Precisas de ajuda?
                        </h3>

                        <p class="text-muted text-center">
                            Estamos disponíveis para esclarecimentos ou para ouvir as tuas sugestões!
                        </p>

                        <div class="text-center">
                            <router-link :to="{ name: 'contact-us' }" class="btn btn-outline-secondary">
                                Contacta-nos
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'

export default {

}
</script>

<style scoped></style>
