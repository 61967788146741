<template>
    <div>
        <button v-on="$listeners" v-bind="$attrs" :disabled="!isEnabled" :class="buttonClasses">
            <template v-if="status === 'loading'">
                <div class="spinner-border" style="width: 1.5rem; height: 1.5rem;" role="status">
                </div>
            </template>
            <template v-else>
                <slot/>
            </template>
        </button>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        buttonExternalClasses: {
            type: String,
            default: ''
        },
        status: {
            type: String,
            default: 'default'
        },
        setButtonType: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isEnabled() {
            return this.status === 'default'
        },
        buttonClasses() {
            let base = 'btn'
            base += ' btn-lg'
            base += ' btn-block'

            if (!this.setButtonType)
                return base + ' ' + this.buttonExternalClasses

            if(this.status === 'disabled')
                return base + ' btn-secondary ' + this.buttonExternalClasses

            return base + ' btn-primary ' + this.buttonExternalClasses
        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
