<template>
<div class="main-content">
    <div aria-live="polite" aria-atomic="true" style="position: relative; z-index: 100;">
        <div style="position: absolute; top: 0; right: 0; z-index: 100;" class="toast-container">
            <ToastNotification v-for="(row, rowIndex) in userNotifications" :key="rowIndex" :type="row.t" :date="row.d" :content="row.c" />
        </div>
    </div>

    <template v-if="!loading">
        <DashboardFree v-if="userType === 'free'" :loading="loading" />
        <DashboardPremium v-else-if="userType === 'premium'" :loading="loading" />
        <DashboardAdmin v-else-if="userType === 'admin'" :loading="loading" />
    </template>
</div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'

import DashboardFree from '@/components/DashboardFree'
import DashboardPremium from '@/components/DashboardPremium'
import DashboardAdmin from '@/components/DashboardAdmin'
import ToastNotification from '@/components/ToastNotification'

import DashboardDataProcessing from '@/mixins/dashboardDataProcessing'

export default {
    components: {
        DashboardAdmin,
        DashboardPremium,
        DashboardFree,
        ToastNotification
    },
    mixins: [DashboardDataProcessing],
    computed: {
        ...mapState({
            userNotifications: state => state.dashboard.userNotifications,
            ...authComputed // loggedIn and userType prop
        })
    },
    params: {
        useCache: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading: true
        }
    },
    async created() {
        const useCache = this.$route.params.useCache === 'undefined'? true : this.$route.params.useCache
        NProgress.start()
        this.loading = true

        try {
            const res = await store.dispatch('api/fetchUserDashboard', { useCache: useCache, limitRows: this.constants.rowsPerUrlTable })
            const dashboardData = _.cloneDeep(res.data.body.dashboard)

            // save user data
            await store.dispatch('userLogin', { type: res.data.body.type, hasTriedPremium: res.data.body.hasTriedPremium, email: res.data.body.email })

            let processedData = null
            switch (this.userType) {
                case 'free': {
                    processedData = this.processFreeDashboardData(dashboardData)
                }
                break
                case 'premium': {
                    processedData = this.processPremiumDashboardData(dashboardData)
                }
                break
                case 'admin': {
                    processedData = this.processAdminDashboardData(dashboardData)
                    const clickCumulativeGraph = this.processDates(dashboardData.cumulative_graphs.click_data, -1, 'Cliques', 'Cliques cumulativos', processedData.clickCount, 'cliques')
                    const slugCumulativeGraph = this.processDates(dashboardData.cumulative_graphs.slug_data, -1, 'Miniaturas', 'Miniaturas criadas cumulativas', processedData.slugCount, 'miniaturas')

                    processedData = { ...processedData, cumulativeGraphs: [ clickCumulativeGraph, slugCumulativeGraph ]}
                }
                break
            }

            await store.dispatch('dashboard/setData', processedData, { root: true })

        } catch(err) {
            console.log(err)
            // TODO error page
        } finally {
            NProgress.done()
            this.loading = false

            // activate toasts
            $('.toast').toast({ autohide: false})
            $('.toast').toast('show')
        }
    }
};
</script>

<style lang="css" scoped>
.toast-container {
    padding-top: 10px;
    padding-right: 10px;
}
</style>
