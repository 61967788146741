<template>
    <div class="main-content">
        <template v-if="!loading">
            <div class="header">
                <div class="container-fluid">
                    <div class="header-body">
                        <div class="row align-items-end">
                            <div class="col">
                                <h6 class="header-pretitle">
                                    Vista de Administração
                                </h6>

                                <h1 class="header-title">
                                    Emails
                                </h1>
                            </div>
                            <!-- <div class="col-auto">
                                    <a href="#!" class="btn btn-primary lift">
                                        Create Report
                                    </a>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-xl-12">
                        <CardTable
                            title="Emails"
                            :header="[{title: 'Remetente'}, {title: 'Destino'}, {title: 'Data'}, {title: 'Assunto'}, {title: 'Conteúdo'}]"
                            :rowElements="['from', 'to', 'date', 'subject', 'content']"
                            :rows="emails"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CardMap from '@/components/cards/CardMap'
import CardTable from '@/components/cards/CardTable'
import CardList from '@/components/cards/CardList'
import CardPieChart from '@/components/cards/CardPieChart'
import CardBarChart from '@/components/cards/CardBarChart'
import CardSmallText from '@/components/cards/CardSmallText'
import CardSmallProgress from '@/components/cards/CardSmallProgress'
import CardSparklineChart from '@/components/cards/CardSparklineChart'
import HomeHeader from '@/components/HomeHeader'

import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers.js' // TODO use this as route guard if not logged in (see CLICA-85)
import store from '@/store/store'

export default {
    components: {
        CardTable
    },
    data () {
        return {
            loading: false,
            emails: []
        }
    },
    async created () {
        NProgress.start()
        this.loading = true

        try {
            const res = await store.dispatch('api/adminGetEmail')
            const emails = _.cloneDeep(res.data.body.emails)

            this.emails = emails
            this.emails = this.emails.map((el) => {
                el.date = this.isoDateToHuman(el.date)
                return el
            })

            this.loading = false
            NProgress.done()
        } catch(err) {
            console.log(err)
            // TODO error page
            this.loading = false
            NProgress.done()
        }
    },
    computed: mapState({
        clickData: state => state.adminData.click
    })
};
</script>

<style scoped></style>
