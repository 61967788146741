<template>
    <div class="card">
        <div id="overlay" style="display:none;">
          <div class="w-100 d-flex justify-content-center align-items-center">

            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
              <span class="sr-only">A carregar...</span>
            </div>
          </div>
        </div>
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <h4 class="card-header-title">
                        {{ title }}
                    </h4>
                </div>
                <div class="col-auto">
                    <span class="btn btn-sm btn-white" :style="loading? 'cursor: default;' : 'cursor: pointer;'" @click="getNextUrlPage(constants.rowsPerUrlTable * currentPage, currentPage, false)" :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Atualizar
                    </span>
                </div>
                <!-- <div class="col-auto">
                    <a href="#!" class="btn btn-sm btn-white">
                        Exportar
                    </a>
                </div> -->
            </div>
        </div>

        <div class="table-responsive mb-0">
            <table class="table table-sm table-nowrap card-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Miniatura</th>
                        <th>Destino</th>
                        <th>Título</th>
                        <th>
                            <span style="cursor:pointer;" class="text-muted sort" data-sort="" @click="changeTableSorting('click_count')">
                                Cliques
                            </span>
                        </th>
                        <th>
                            <span style="cursor:pointer;" class="text-muted sort" data-sort="" @click="changeTableSorting('date_created')">
                                Criado em
                            </span>
                        </th>
                        <th>Último Clique</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="list">
                    <template v-if="!rows || rows.length === 0">
                      <tr>
                        <td :colspan="6" align="center">Ainda não tens miniaturas. Por que não criar uma agora?</td>
                      </tr>
                    </template>
                    <template v-for="(row, rowIndex) in rows">
                        <tr>
                            <td>
                                <!-- "Show details" if it has clicks -->
                                <template v-if="row.click_count > 0">
                                    <router-link :to="{ name: 'single-url', params: { slug: row.slug }}">
                                        <i class="fe fe-zoom-in"></i>
                                    </router-link>
                                    {{ &nbsp; }}
                                </template>
                                <template v-else>
                                    <span class="text-secondary" title="Miniatura sem cliques para mostrar">
                                        <i class="fe fe-zoom-in"></i>
                                    </span>
                                    {{ &nbsp; }}
                                </template>

                                <!-- Show "blocked" if so -->
                                <span v-if="row.blocked_at">
                                    <i :title="'Bloqueado a ' + row.blocked_at" class="fe fe-x-circle" style="color: red;"></i>
                                    {{ &nbsp; }}
                                </span>

                                <!-- Show "user" if slug belongs to someone -->
                                <span v-if="row.email">
                                    <i :title="'Criado por ' + row.email" class="fe fe-user"></i>
                                    {{ &nbsp; }}
                                </span>

                                <!-- Show "premium" if so -->
                                <router-link v-if="row.premium" :to="{ name: 'slug-invoice', params: { slug: row.slug }}">
                                    <i title="Miniatura personalizada" class="fe fe-star"></i>
                                    {{ &nbsp; }}
                                </router-link>

                                <!-- Show "pending" if slug needs to be paid -->
                                <span v-if="row.pending">
                                    <i title="Pagamento pendente" class="fe fe-credit-card" style="color: red;"></i>
                                    {{ &nbsp; }}
                                </span>
                            </td>
                            <td>
                                {{ row.slug }} <span style="cursor: pointer;">&nbsp;<span class="copy-btn text-primary inline" :data-clipboard-text="'https://clica.ai/' + row.slug"><i class="fe fe-copy"></i></span></span>
                            </td>
                            <td>
                                <span :title="row.destination_long">{{ row.destination }}</span> &nbsp;<a :href="row.destination_long" target="_blank"><i class="fe fe-external-link"></i></a>
                            </td>
                            <td>
                                <span class="text-muted" :title="row.description_long">{{ row.description }}</span>
                            </td>
                            <td>
                                {{ row.click_count }}
                            </td>
                            <td>
                                {{ row.date_created }}
                            </td>
                            <td>
                                {{ row.last_click }}
                            </td>
                            <td class="text-right">
                                <div class="dropdown">
                                    <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fe fe-more-vertical"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <span class="dropdown-item" style="cursor:pointer;" @click="selectSlugBlock(row.slug)">
                                            Bloquear
                                        </span>
                                        <span class="dropdown-item" style="cursor:pointer;" @click="selectSlugChangeDestination(row.slug)">
                                            Alterar destino
                                        </span>
                                        <span class="dropdown-item" style="cursor:pointer;" @click="selectSlugAdminDelete(row.slug)">
                                            Apagar (admin)
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <template v-if="totalRows > constants.rowsPerUrlTable">
            <hr>
            <div class="row">
                <div class="col">
                    <Pagination :totalRows="totalRows" :callback="getNextUrlPage" ref="pagination" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'

import ClipboardJS from 'clipboard'
import store from '@/store/store'
import { mapState } from 'vuex'

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        rows: {
            type: Array,
            default: []
        },
        totalRows: {
            type: Number,
            required: true
        },
        rowsReceivedCallback: {
            type: Function,
            required: true
        }
    },
    components: {
        Pagination
    },
    data() {
        return {
            tableSortingColumn: 'date_created',
            tableSortingOrder: 'd',
            currentPage: 0,
            loading: false
        }
    },
    created() {
        new ClipboardJS('.copy-btn')
    },
    methods: {
        selectSlugBlock(slug) {
            this.$root.$emit('openBlockModal', 'clica.ai/', slug)
        },
        selectSlugChangeDestination(slug) {
            this.$root.$emit('openChangeDestinationModal', 'clica.ai/', slug)
        },
        selectSlugAdminDelete(slug) {
            this.$root.$emit('openAdminDeleteModal', 'clica.ai/', slug)
        },
        changeTableSorting(column) {
            if (this.tableSortingColumn === column) {
                // same column toggles order
                this.tableSortingOrder = this.tableSortingOrder === 'a'? 'd' : 'a'
            } else {
                this.tableSortingColumn = column
                this.tableSortingOrder = 'd'
            }

            this.getNextUrlPage(0, 0, true)
            this.$refs.pagination.resetPage()
        },
        getNextUrlPage(start, newPage, useCache = true) {
            this.loading = true

            return store.dispatch('api/fetchUserUrls', { start: start, limit: this.constants.rowsPerUrlTable, sort: this.tableSortingColumn, order: this.tableSortingOrder, useCache: useCache })
                .then((res) => {
                    const slugs = _.cloneDeep(res.data.body.slugs)
                    return this.rowsReceivedCallback(slugs)
                        .then((res) => {
                            this.currentPage = newPage
                            this.loading = false
                        })
                })
        }
    }
};
</script>

<style lang="css" scoped>
.spinner {
    height: 60px;
    width: 60px;
    margin: auto;
    display: flex;
    position: absolute;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(0, 174, 239, .15);
    border-right: 6px solid rgba(0, 174, 239, .15);
    border-bottom: 6px solid rgba(0, 174, 239, .15);
    border-top: 6px solid rgba(0, 174, 239, .8);
    border-radius: 100%;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(359deg);
    }
}

@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

#overlay {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: default;
    border: 1px solid #edf2f9;
    border-radius: 0.5rem;
}
</style>
