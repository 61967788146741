<template>
<div class="card">
    <div class="card-header">
        <i class="fe fe-dollar-sign"></i> Total
        <div class="col-auto align-self-center d-lg-none" v-if="!isFree">
            <CurrencySelector :currentCountry="uiCountryCode" :currencyChanged="currencyChanged" />
        </div>
    </div>
    <div class="card-body">
        <div class="row mt-n1 mb-1">
            <div class="col-auto align-self-center d-none d-lg-block" v-if="!isFree">
                <CurrencySelector :currentCountry="uiCountryCode" :currencyChanged="currencyChanged" />
            </div>
            <div class="col align-self-center">
                <span class="card-text">{{ getCostString() }}</span>
                <p class="card-text text-muted" v-if="!isFree && priceAvailable && !error && cost > 0 && uiCountryCode !== 'PT'">Valor cobrado: {{ getCostStringEur() }}</p>
            </div>
            <div class="col-5 align-self-center">
                <BaseButton type="submit" :status="submitStatus">
                    {{ isFree? 'Criar miniatura' : 'Continuar' }}
                </BaseButton>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/store'

import CurrencySelector from '@/components/CurrencySelector'

export default {
    props: {
        cost: {
            type: Number,
            required: true
        },
        costBeforePromo: {
            type: Number,
            required: true
        },
        error: {
            type: String,
            default: null
        },
        submitStatus: {
            type: String,
            required: true
        },
        isFree: {
            type: Boolean,
            required: true
        },
        priceAvailable: {
            type: Boolean,
            required: true
        }
    },
    components: {
        CurrencySelector
    },
    computed: {
        ...mapState({
            uiCountryCode: state => state.shoppingCart.countryCode,
            uiCurrencyConvertBRL: state => state.shoppingCart.exchangeRateBrl
        })
    },
    async mounted () {
        // see if user is in Brazil to show adequate currency
        $.ajax({
            method: 'GET',
            url: 'https://ipapi.co/json/',
            success: async (data) => {
                if(data.country_code === 'BR')
                    await store.dispatch('shoppingCart/setCountryCode', 'BR', { root: true })
            },
            error: async (err) => {
                // try to get locale alternatively
                if (navigator.languages !== undefined) {
                    for (var i = 0; i < navigator.languages.length; i++) {
                        if (navigator.languages[i] === 'pt-BR') {
                            await store.dispatch('shoppingCart/setCountryCode', 'BR', { root: true })
                            break
                        }
                    }
                } else {
                    if (navigator.language === 'pt-BR')
                        await store.dispatch('shoppingCart/setCountryCode', 'BR', { root: true })
                }
            }
        })

        $.ajax({
            method: 'GET',
            url: 'https://api.exchangerate.host/latest?base=EUR&symbols=BRL',
            success: async (data) => {
                await store.dispatch('shoppingCart/setExchangeRateBrl', data.rates.BRL, { root: true })
            }
        })
    },
    methods: {
        async currencyChanged(ev) {
            await store.dispatch('shoppingCart/setCountryCode', ev.target.value, { root: true })
        },
        formatCurrencyString(value) {
            const str = value.toString()

            if (str.indexOf('.') < 0)
                return str

            const parts = str.split('.')
            return parts[0] + ',' + parts[1].padEnd(2, '0')
        },
        getCostString() {
            if (!this.priceAvailable)
                return 'O preço da tua miniatura será calculado no próximo passo.'

            if (this.error)
                return 'Escolhe uma miniatura única'

            if (this.isFree || this.cost === 0)
                return 'Gratuita'

            // generate a string with euro cost
            const costEur = Math.round(this.cost * 100) / 100
            const euroString = this.formatCurrencyString(costEur) + '€'

            const costEurBeforePromo = Math.round(this.costBeforePromo * 100) / 100
            const euroStringBeforePromo = this.formatCurrencyString(costEurBeforePromo) + '€'

            if (this.uiCountryCode === 'BR') {
                let costBrl = Math.round(this.cost*this.uiCurrencyConvertBRL * 100) / 100
                costBrl = this.formatCurrencyString(costBrl)

                let costBrlBeforePromo = Math.round(this.costBeforePromo*this.uiCurrencyConvertBRL * 100) / 100
                costBrlBeforePromo = this.formatCurrencyString(costBrlBeforePromo)

                return `Cerca de R$ ${costBrl}` + (this.costBeforePromo !== this.cost? ` (era R$ ${costBrlBeforePromo})` : '')
            } else {
                return `${euroString}` + (this.costBeforePromo !== this.cost? ` (era ${euroStringBeforePromo})` : '')
            }
        },
        getCostStringEur() {
            // generate a string with euro cost
            const costEur = Math.round(this.cost * 100) / 100
            const euroString = this.formatCurrencyString(costEur) + '€'

            return euroString
        }
    }
}
</script>
