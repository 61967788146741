<template>
<div class="card">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h4 class="card-header-title">
                    Evolução de Cliques - {{ dataset[active].textLong }}
                </h4>
            </div>
            <div class="col-auto">
                <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
                    <template v-for="(data, index) in dataset">
                        <li class="nav-item" @click="setActive(index)">
                            <a style="cursor: pointer;" class="nav-link" :class="{ 'active': index === active }">
                                {{ data.textShort }} ({{ data.labelNum }})
                            </a>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="chart">
            <canvas id="performanceChartCustom" class="chart-canvas"></canvas>
        </div>
    </div>
</div>
</template>

<script>
import { addCallbacks } from '../../../assets/js/new-chart-callbacks.js'

export default {
    props: {
        dataset: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            chart: null,
            active: 0
        }
    },
    mounted() {
        const chart = document.getElementById('performanceChartCustom');

        if (typeof Chart === 'undefined' || !chart) {
            return
        }

        this.chart = new Chart(chart, {
            type: 'line',
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function(value) {
                                if (!(value % 5)) {
                                    return value + ' cliques'
                                }
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function(item, data) {
                            const label = data.datasets[item.datasetIndex].label || ''
                            const yLabel = item.yLabel
                            let content = ''

                            if (data.datasets.length > 1) {
                                content += '<span class="popover-body-label mr-auto">' + label + '</span>'
                            }

                            const clickText = yLabel === 1 ? 'clique' : 'cliques'

                            content += '<span class="popover-body-value">' + yLabel + ' ' + clickText + '</span>'
                            return content
                        }
                    }
                }
            }
        })

        addCallbacks('performanceChartCustom')
        this.setActive(0)
    },
    methods: {
        setActive(index) {
            this.active = index
            this.updateData()
        },
        updateData() {
            const dataset = this.dataset[this.active]

            // cleanup previous labels and data
            while(this.chart.data.labels.length > 0)
                this.chart.data.labels.pop()

            while(this.chart.data.datasets.length > 0)
                this.chart.data.datasets.pop()

            this.chart.data.datasets.push({data: []})

            // add new data
            for (let i = 0; i < dataset.labels.length; i++) {
                this.chart.data.labels.push(dataset.labels[i])
                this.chart.data.datasets[0].data.push(dataset.data[i])
            }

            this.chart.update()
        },
        createDataObject(obj) {
            const copy = Object.assign({}, obj)
            return JSON.stringify({
                data: {
                    labels: copy.labels,
                    datasets: [{
                        // label: obj.textLong,
                        data: copy.data
                    }]
                }
            })
        }
    }
};
</script>

<style lang="css" scoped>
</style>
