<template>
    <div class="card">
        <div id="overlay" style="display:none;">
          <div class="w-100 d-flex justify-content-center align-items-center">

            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <h4 class="card-header-title">
                        {{ title }}
                    </h4>
                </div>
                <!-- <div class="col-auto">
                    <a href="#!" class="btn btn-sm btn-white">
                        Export
                    </a>
                </div> -->
            </div>
        </div>

        <div class="table-responsive mb-0">
            <table class="table table-sm table-nowrap card-table">
                <thead>
                    <tr>
                        <th v-for="col in header">
                            <!-- uncomment when implementing sortable cols -->
                            <!-- <a href="#" class="text-muted sort" :data-sort="col.label">
                                {{ col.title }}
                            </a> -->
                            {{ col.title }}
                        </th>
                    </tr>
                </thead>
                <tbody class="list">
                    <template v-for="(row, rowIndex) in rows">
                        <tr v-if="row['success']">
                            <td v-for="(element, colIndex) in rowElements">
                                {{ row[element] }}
                            </td>
                        </tr>

                        <tr v-else bgcolor="#FFB199">
                            <td>
                                {{ row.epoch }}
                            </td>
                            <td :colspan="rowElements.length-1">
                                {{ row.description }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <!-- <hr>
        <div class="row">
            <div class="col">
                <nav aria-label="Barra de navegação da tabela">
                    <ul class="pagination justify-content-center">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Anterior">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Próximo">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div> -->
    </div>
</template>

<script>
import ClipboardJS from 'clipboard'

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        header: {
            type: Array,
            required: true
        },
        rowElements: {
            type: Array,
            default: []
        },
        rows: {
            type: Array,
            default: []
        },
    },
    created() {
      new ClipboardJS('.copy-btn')
    },
    methods: {
        columnHasTooltip(column) {
            return this.tooltips.filter(e => e.for === column).length > 0
        },
        getTooltips(column, index) {
            const rowKey = this.tooltips.filter(e => e.for === column)[0]['key']
            return this.rows[index][rowKey]
        },
        getUrl(element, row, rowIndex) {
            return this.columnHasTooltip(element)? this.getTooltips(element, rowIndex) : row[element]
        }
    },
    beforeMount() {

    }
};
</script>

<style lang="css" scoped>
.spinner {
  height: 60px;
  width: 60px;
  margin: auto;
  display: flex;
  position: absolute;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, .15);
  border-right: 6px solid rgba(0, 174, 239, .15);
  border-bottom: 6px solid rgba(0, 174, 239, .15);
  border-top: 6px solid rgba(0, 174, 239, .8);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

#overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: default;
  border: 1px solid #edf2f9;
  border-radius: 0.5rem;
}
</style>
