import axios from 'axios'
import router from '@/router.js'
import store from '@/store/store.js'

import { cacheAdapterEnhancer } from 'axios-extensions'

const API_URL = 'https://api.clica.ai'

const apiClient = axios.create({
    baseURL: API_URL,
    timeout: 25000,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter) // CLICA-153
})

// set global interceptors for axios
apiClient.interceptors.response.use(
    (response) => {
        // simply return the response
        return response
    },
    (error) => {
        // TODO seems like error.response is sometimes undefined
        // found out why this is, we need to set access-control-allow origin on ERROR status codes AS WELL AS 200, for each possible code in each endpoint!
        if (error && error.response && error.response.status === 401 && router.history.current.name !== 'login') {
            store.dispatch('notification/add', {
                status: 'error',
                category: 'unauthorised',
                message: 'A sua sessão expirou, volte a autenticar-se'
            })

            // if we catch a 401 error force a log out
            router.push({ name: 'logout' })
        }

        return Promise.reject(error) // reject the Promise, with the error as the reason
    }
)

export const setHeader = (key, value) => {
    apiClient.defaults.headers.common[key] = value
}

export const URLService = {
    create_slug(destination, token, slugType, desiredSlug, promoCode) {
        return apiClient.post('/urls', {url: destination, token: token, type: slugType, desiredSlug: desiredSlug, promoCode: promoCode})
    },
    report_url(motive, slug, comment, recaptchaToken) {
        return apiClient.post('/urls/' + slug + '/report', {
            motive: motive,
            comment: comment,
            recaptchaToken: recaptchaToken
        })
    },
    register_user(email, password, recaptchaToken) {
        return apiClient.post('/users', {
            email: email,
            password: password,
            recaptchaToken: recaptchaToken
        })
    },
    verify_user(token) {
        return apiClient.get('/users/verify', {
            params: {
                token: token
            }
        })
    },
    login(email, password, rememberMe, recaptchaToken) {
        return apiClient.post('/login', {
            email: email,
            password: password,
            rememberMe: rememberMe,
            recaptchaToken: recaptchaToken
        })
    },
    logout(token) {
        return apiClient.post('/logout', {
            token: token
        })
    },
    requestPasswordReset(email, recaptchaToken) {
        return apiClient.post('/users/recover-password', {
            email: email,
            recaptchaToken: recaptchaToken
        })
    },
    resetPassword(password, userId, resetToken, recaptchaToken) {
        return apiClient.post('/users/recover-password', {
            password: password,
            userId: userId,
            resetToken: resetToken,
            recaptchaToken: recaptchaToken
        })
    },
    urlList(useCache, start, limit, sort, order) {
        return apiClient.get('/urls', {
            params: {
                start,
                limit,
                sort,
                order
            },
            cache: useCache
        })
    },
    dashboard(useCache, limitRows) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/users/dashboard', { params: { limitRows }, cache: useCache })
    },
    billingSetBillingData(name, address, postalCode, town, country, phone, vatNumber) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('billing/customer', {
            action: 'updateBillingData',
            billingData: {
                name: name,
                address: address,
                postalCode: postalCode,
                town: town,
                country: country,
                phone: phone,
                vatNumber: vatNumber
            }
        })
    },
    billingClearBillingData() {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('billing/customer', {
            action: 'clearBillingData'
        })
    },
    billingRequestSetupIntent() {
        return apiClient.post('billing/setupintent')
    },
    billingSetDefaultCard(cardId) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('billing/customer', {
            action: 'setDefaultCard',
            cardId: cardId
        })
    },
    billingDeleteCard(cardId) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.delete('billing/customer/card/' + cardId)
    },
    getCustomerData(useCache) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/billing/customer', { cache: useCache })
    },
    getSlugData(slug) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/urls/' + slug)
    },
    sendContactForm(type, subject, message) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.post('/contact-us', {
            type: type,
            subject: subject,
            message: message
        })
    },
    blockSlug(slug, reason) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('/urls/' + slug, {
            action: 'block',
            reason: reason
        })
    },
    changeSlugDestination(slug, newDestination) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('/urls/' + slug, {
            action: 'changeDestination',
            newDestination: newDestination
        })
    },
    adminGetUserData() {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/admin/user')
    },
    adminGetSlugData() {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/admin/slug')
    },
    adminGetClickData() {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/admin/click')
    },
    adminGetHealthData() {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/admin/health')
    },
    adminGetEmail() {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/admin/email')
    },
    adminSendEmail(fromAddress, to, subject, body) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.post('/admin/email', {
            fromAddress, to, subject, body
        })
    },
    adminGetSystemMessage() {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/admin/system-message', { cache: false })
    },
    adminSetSystemMessage(message) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.post('/admin/system-message', {
            message
        })
    },
    adminDeleteSlug(slug) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.delete('/urls/' + slug, {
            params: {
                type: 'admin'
            }
        })
    },
    accountChangePassword(currentPassword, newPassword) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('/users', {
            action: 'changePassword',
            currentPassword: currentPassword,
            newPassword: newPassword
        })
    },
    accountChangeEmail(currentPassword, newEmail) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('/users', {
            action: 'requestEmailChange',
            currentPassword: currentPassword,
            newEmail: newEmail
        })
    },
    accountStartTrial() {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('/users', {
            action: 'startTrial'
        })
    },
    cancelSlugReservation(slug) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('/urls/' + slug, {
            action: 'cancel_reservation'
        })
    },
    renewSlugPaymentData(slug, fromEmail) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('/urls/' + slug, {
            action: 'renew_payment_data',
            fromEmail: fromEmail
        })
    },
    requestSlugInvoice(slug, name, address, postalCode, town, country, phone, vatNumber) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.post('/urls/' + slug + '/invoice', {
            name, address, postalCode, town, country, phone, vatNumber
        })
    },
    getSlugInvoice(slug) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/urls/' + slug + '/invoice')
    },
    getBillingInvoice(number) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.get('/billing/customer/invoice/' + number)
    },
    payInvoice(action, number, paymentMethodId) {
        setHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`)
        return apiClient.patch('/billing/customer/invoice/' + number, { action, paymentMethodId })
    }
}
