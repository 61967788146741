<template>
<div class="main-content">
    <div class="container-fluid" v-if="!loading">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 col-xl-8" id="invoiceToPrint">
                <div class="header mt-md-5">
                    <div class="header-body">
                        <div class="row align-items-center">
                            <div class="col-auto my-auto">
                                <a class="btn btn-primary active" @click="$router.go(-1)">
                                    <i class="fe fe-arrow-left d-none d-md-inline mr-3"></i> Voltar atrás
                                </a>
                            </div>
                            <div class="col">
                                <h6 class="header-pretitle">
                                    Faturação
                                </h6>
                                <h1 class="header-title">
                                    Fatura #{{ number }}
                                </h1>
                            </div>
                            <div class="col-auto">
                                <!-- <span class="btn btn-white lift" @click="downloadPdf" :style="generatingPdf? 'cursor: default;' : 'cursor: pointer;'">
                                    <span v-if="generatingPdf" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Descarregar
                                </span> -->
                                <!-- <a href="#!" class="btn btn-primary ml-2 lift">
                                    Pay
                                </a> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-body p-5">
                    <!-- <div class="row">
                        <div class="col text-right">
                            <div class="badge badge-danger">
                                Overdue
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col text-center">
                            <inline-svg src="/assets/img/logo.svg" class="img-fluid mb-4" alt="Clica.ai" style="max-width: 2.5rem;"></inline-svg>

                            <h2 class="mb-2">
                                Fatura clica.ai
                            </h2>

                            <p class="text-muted mb-6">
                                Fatura #{{ number }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h6 class="text-uppercase text-muted">
                                Fatura número
                            </h6>
                            <p class="mb-4">
                                #{{ number }}
                            </p>

                            <h6 class="text-uppercase text-muted">
                                Data de emissão
                            </h6>

                            <p class="mb-4">
                                <span>{{ date }}</span>
                            </p>
                        </div>
                        <div class="col-12 col-md-6 text-md-right">
                            <h6 class="text-uppercase text-muted">
                                Emitida a
                            </h6>

                            <p class="text-muted mb-4">
                                <strong class="text-body">{{ invoicee.name }}</strong><br>
                                <template v-if="invoicee.address">
                                    {{ invoicee.address }}<br>
                                </template>
                                <template v-if="invoicee.postalCode">
                                    {{ invoicee.postalCode }} {{ invoicee.town }}<br>
                                </template>
                                <template v-if="invoicee.country">
                                    {{ invoicee.country }}<br>
                                </template>
                                <template v-if="invoicee.contact">
                                    Contacto: {{ invoicee.contact }}<br>
                                </template>
                                <template v-if="invoicee.vatNumber">
                                    NIF / CPF: {{ invoicee.vatNumber }}<br>
                                </template>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table my-4">
                                    <thead>
                                        <tr>
                                            <th class="px-0 bg-transparent border-top-0">
                                                <span class="h6">Descrição</span>
                                            </th>
                                            <th class="px-0 bg-transparent border-top-0">
                                                <span class="h6">Quantidade</span>
                                            </th>
                                            <th class="px-0 bg-transparent border-top-0 text-right">
                                                <span class="h6">Preço (EUR)</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in items">
                                            <td class="px-0">
                                                {{ item.descPrefix }} <b>{{ item.desc }}</b>
                                            </td>
                                            <td class="px-0">
                                                {{ item.quantity }}
                                            </td>
                                            <td class="px-0 text-right">
                                                {{ item.price || item.amount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-0 border-top border-top-2">
                                                <strong>Total</strong>
                                            </td>
                                            <td colspan="2" class="px-0 text-right border-top border-top-2">
                                                <span class="h3">
                                                    {{ total }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <hr class="my-5">

                            <h6 class="text-uppercase">
                                Notas
                            </h6>

                            <p class="text-muted mb-0">
                                Caso pretendas a emissão de fatura com número de contribuinte, por favor contacta <a href="mailto:faturacao@clica.ai">faturacao@clica.ai</a> indicando o teu email e número da fatura em causa.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'

//import html2canvas from 'html2canvas'

const countryList = require('@/assets/countries.json') /*TODO handle nulls*/

export default {
    props: {
        type: {
            type: String,
            required: true
        },
        slug: {
            type: String
        },
        invoiceNumber: {
            type: String
        }
    },
    computed: {
        ...authComputed
    },
    data() {
        return {
            countries: null,

            loading: false,
            generatingPdf: false,

            status: null,
            number: null,
            date: null,
            items: null,
            total: 0,
            invoicee: {
                name: null,
                address: null,
                postalCode: null,
                town: null,
                country: null,
                contact: null,
                vatNumber: null
            }
        }
    },
    async created() {
        NProgress.start()
        this.loading = true

        this.convertCountries()

        switch (this.type) {
            case 'slug':
                await this.getSlugInvoice()
                break
            case 'billing':
                await this.getBillingInvoice()
                break
        }

        NProgress.done()
        this.loading = false
    },
    methods: {
        convertCountries() {
            this.countries = []
            for (let i = 0; i < countryList.length; i++)
                this.countries[countryList[i]['value']] = countryList[i]['text']
        },
        async getSlugInvoice() {
            try {
                const res = await store.dispatch('api/getSlugInvoice', { slug: this.slug })
                const data = _.cloneDeep(res.data.body.invoice)

                this.number = data.invoice_number
                this.date = this.isoDateToHuman(data.invoice_date)
                this.items = [
                    { descPrefix: 'Compra de miniatura: ', desc: data.domain + data.slug, quantity: 1, price: this.getHumanPrice(data.invoice_total) }
                ]
                this.total = this.getHumanPrice(data.invoice_total)

                if (data.invoicee_name) {
                    this.invoicee = {
                        name: data.invoicee_name,
                        address: data.invoicee_address,
                        postalCode: data.invoicee_postal_code,
                        town: data.invoicee_town,
                        country: this.countries[data.invoicee_country],
                        contact: data.invoicee_contact,
                        vatNumber: data.invoicee_vat
                    }
                } else {
                    this.invoicee = {
                        name: 'Cliente final'
                    }
                }
            } catch (err) {
                if (err.response && err.response.status === 403) {
                    this.$router.push({ name: 'home' })
                }
            }
        },
        async getBillingInvoice() {
            try {
                const res = await store.dispatch('api/getBillingInvoice', { number: this.invoiceNumber })
                const data = _.cloneDeep(res.data.body.invoice)

                this.number = this.invoiceNumber
                this.date = this.isoDateToHuman(data.date)
                this.status = data.status
                this.items = data.lines.map((l) => {
                    return Object.assign({}, l, { amount: this.getHumanPrice(l.amount) })
                })
                this.total = this.getHumanPrice(data.total)

                if (data.invoicee_name) {
                    this.invoicee = {
                        name: data.invoicee_name,
                        address: data.invoicee_address,
                        postalCode: data.invoicee_postal_code,
                        town: data.invoicee_town,
                        country: this.countries[data.invoicee_country],
                        contact: data.invoicee_contact,
                        vatNumber: data.invoicee_vat
                    }
                } else {
                    this.invoicee = {
                        name: 'Cliente final'
                    }
                }
            } catch (err) {
                if (err.response.status === 403) {
                    this.$router.push({ name: 'home' })
                }
            }
        },
        async downloadPdf() {
            const quality = 2
    		const filename  = 'fatura-clicaai.pdf'

            try {
                this.generatingPdf = true
                const canvas = await html2canvas(document.getElementById('invoiceToPrint'), { scale: quality })

    			let pdf = new jsPDF('p', 'mm', 'a4')
    			pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298)
    			pdf.save(filename)
            } catch (e) {

            } finally {
                this.generatingPdf = false
            }
        }
    }
}
</script>

<style scoped></style>
