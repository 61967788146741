<template>
<div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xl-8">
        <!-- Header -->
        <div class="header mt-md-5">
            <div class="header-body">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="header-pretitle">
                            Miniaturas
                        </h6>
                        <h1 class="header-title">
                            Pagar miniatura
                        </h1>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="paymentError" class="alert alert-danger" role="alert">
            Erro no pagamento: {{ paymentError }}
        </div>

        <div class="row">
            <div class="col-auto">
                <h2>Miniatura <i>{{ namespace.split('//')[1] }}{{ slug }}</i> reservada com sucesso!</h2>
            </div>
            <div class="col-auto ml-auto">
                <span class="align-middle">Reserva garantida: <b>{{ timeLeftHuman }}</b></span>
                &nbsp;&nbsp;
                <HelpButton class="align-middle" tooltip="A tua miniatura fica reservada durante um tempo limitado. Passado este tempo ainda podes comprar a miniatura, mas ela poderá ser reservada por outros utilizadores primeiro!" />
            </div>
        </div>
        <form class="mb-4" @submit.prevent="submitPaymentData">
            <CreditCardData @card-validation="setCardDataValidity" @stripe-card-element="setStripeCardElement" />

            <div class="form-group">
                <div class="row">
                    <div class="col-auto">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="emailSwitch" v-model="emitInvoice">
                            <label class="custom-control-label" for="emailSwitch"></label>
                        </div>
                    </div>
                    <div class="col ml-n2">
                        <small class="text-muted">
                            Inserir dados de faturação?
                        </small>
                    </div>
                </div>
            </div>

            <template v-if="emitInvoice">
                <hr class="mt-4 mb-5">
                <h2>Preenche os dados para a tua fatura:</h2>
                <div class="row">
                    <div class="col-12">
                        <BillingDataForm :setDataCallback="setBillingData" :validityCallback="setBillingDataValidity" />
                    </div>
                </div>
                <hr class="mt-4 mb-5">
            </template>

            <div class="row">
                <div class="col-12">
                    <p>
                        <span class="text-muted">Ao clicares em finalizar, vai ser debitado do teu cartão o valor de <b>{{ costEur / 100.0 }}€ (euros)</b>. O teu cartão será usado apenas para esta transação.</span>
                        <br/>
                        <span class="text-muted">A tua miniatura ficará ativa depois de confirmado o pagamento.</span>
                    </p>
                </div>
            </div>

            <hr class="mt-4 mb-5">

            <div class="row">
                <div class="col-12">
                    <BaseButton type="submit" :status="submitButtonStatus">
                        Submeter
                    </BaseButton>
                    <BaseButton :status="cancelButtonStatus" :setButtonType="false" buttonExternalClasses="btn-link text-muted" @click="cancelCreateSlug">
                        Cancelar
                    </BaseButton>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import store from '@/store/store'
import { mapState, mapGetters } from 'vuex'

import { required, minLength } from 'vuelidate/lib/validators'

import HelpButton from '@/components/HelpButton'
import CreditCardData from '@/components/billing/CreditCardData'
import BillingDataForm from '@/components/billing/BillingDataForm'

export default {
    props: {
        parentNextStage: {
            type: Function,
            required: true
        }
    },
    components: {
        HelpButton,
        CreditCardData,
        BillingDataForm
    },
    data () {
        return {
            paymentError: null,
            countdownTimeout: null,
            timeLeftHuman: '10:00',
            emitInvoice: false,
            billingData: null,

            isCardDataValid: false,
            stripeCardElement: null,
            isBillingDataValid: false,

            stripeSubmitting: false, // track submission status to display pending button
        }
    },
    computed: {
        ...mapState({
            // userEmail: state => state.user.email,

            stripeObject: state => state.stripe.stripeObject,
            paymentIntentClientSecret: state => state.createSlug.paymentData.paymentIntentClientSecret,
            costEur: state => state.createSlug.paymentData.serverSideCostEur,
            originalCostEur: state => state.createSlug.paymentData.serverSideCostEurOriginal,
            timeLimit: state => state.createSlug.paymentData.timeLimit,

            namespace: state => state.createSlug.slugData.namespace,
            slug: state => state.createSlug.slugData.slug
        }),
        ...mapGetters({ pending: 'api/isPending' }),
        isSubmitPending() {
            // get requestBillingSetup api status for button feedback
            return this.pending('requestBillingSetupIntent')
        },
        isCancelPending() {
            return this.pending('cancelSlugCreation')
        },
        submitButtonStatus() {
            if (!this.isCardDataValid || (this.emitInvoice && !this.isBillingDataValid) || this.isCancelPending)
                return 'disabled'

            if (this.stripeSubmitting || this.isSubmitPending)
                return 'loading'

            return 'default'
        },
        cancelButtonStatus() {
            if (this.stripeSubmitting || this.isSubmitPending)
                return 'disabled'

            if (this.isCancelPending)
                return 'loading'

            return 'default'
        }
    },
    mounted() {
        this.countdownTimeout = setInterval(() => {
            const left = this.timeLimit - Date.now()

            if (left > 0) {
                const m = Math.floor((left % (1000 * 60 * 60)) / (1000 * 60))
                const s = Math.floor((left % (1000 * 60)) / 1000)

                this.timeLeftHuman = m + ':' + s.toString().padStart(2, '0')
            } else {
                this.timeLeftHuman = '0:00'
                clearInterval(this.countdownTimeout)
            }
        }, 1000)
    },
    beforeDestroy () {
        clearInterval(this.countdownTimeout)
    },
    methods: {
        setCardDataValidity(status) {
            this.isCardDataValid = status
        },
        setStripeCardElement(element) {
            this.stripeCardElement = element
        },
        setBillingDataValidity(status) {
            this.isBillingDataValid = status
        },
        setBillingData (billingData) {
            this.billingData = billingData
        },
        async cancelCreateSlug () {
            const res = await store.dispatch('api/cancelCreateSlug', { slug: this.slug })
            this.$router.push({ name: 'home' })
        },
        async submitPaymentData() {
            this.stripeSubmitting = true

            try {
                // request invoice to our server
                if (this.emitInvoice) {
                    await store.dispatch('api/requestSlugInvoice', { slug: this.slug, ...this.billingData })
                }

                // send payment confirmation to stripe
                const result = await this.stripeObject.confirmCardPayment(this.paymentIntentClientSecret, {
                    payment_method: { card: this.stripeCardElement }
                })

                this.stripeSubmitting = false

                if (result.error) {
                    // display error message
                    this.paymentError = this.getErrorString(result.error)
                    this.$root.$emit('cleanupCardData')
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {
                        // clear state
                        await store.dispatch('createSlug/clearPaymentData')

                        // advance stage
                        this.parentNextStage('success')
                    } else {
                        console.log('error');
                        console.log(result);
                    }
                }
            } catch (err) {
                this.stripeSubmitting = false
                console.log(err)
                // TODO error page
            }

        }
    }
}
</script>

<style lang="css" scoped>
.card-body {
  max-height: 2000px !important;
}

/* .StripeElement {
    display: block;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem .75rem;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.StripeElement::placeholder {
    color: #b1c2d9;
    opacity: 1;
}
*/
.StripeElement--focus {
    border-color: #2C7BE5;
}
</style>
