export const namespaced = true

export const state = {
    user: null,
    slug: null,
    click: null,
    health: null
}

export const mutations = {
    SET_ADMIN_USER_DATA(state, data) {
        state.user = data
    },
    SET_ADMIN_SLUG_DATA(state, data) {
        state.slug = data
    },
    SET_PARTAL_SLUG_DATA(state, data) {
        state.slug.slug_table = data
    },
    SET_ADMIN_CLICK_DATA(state, data) {
        state.click = data
    },
    SET_ADMIN_HEALTH_DATA(state, data) {
        state.health = data
    }
}

export const actions = {
    setAdminUserData({ commit, dispatch }, data) {
        commit('SET_ADMIN_USER_DATA', data)
    },
    setAdminSlugData({ commit, dispatch }, data) {
        commit('SET_ADMIN_SLUG_DATA', data)
    },
    setPartialSlugData({ commit, dispatch }, data) {
        commit('SET_PARTAL_SLUG_DATA', data)
    },
    setAdminClickData({ commit, dispatch }, data) {
        commit('SET_ADMIN_CLICK_DATA', data)
    },
    setAdminHealthData({ commit, dispatch }, data) {
        commit('SET_ADMIN_HEALTH_DATA', data)
    }
}
