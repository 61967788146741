<template>
<div class="modal fade" id="modalBillingConfirmRemoveAddress" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h4 class="card-header-title">
                                Apagar Dados de Faturação
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <p>Tens a certeza de que queres apagar os teus dados de faturação?</p>
                    <p>A remoção destes dados não implica o cancelamento de subscrições do clica.ai. Se pretenderes cancelar a tua subsrição <i>clica.ai premium</i> deves remover os cartões associados à tua conta clica.ai.</p>

                    <hr />

                    <form @submit.prevent="deleteBillingData">
                        <BaseButton type="submit" :status="buttonStatus">
                            {{ userType === 'premium'? 'Remover e terminar o <i>clica.ai premium</i>' : 'Remover' }}
                        </BaseButton>

                        <span class="btn btn-block btn-link text-muted" style="cursor: pointer;" data-dismiss="modal">
                            Cancelar
                        </span>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from '@/store/store'
import { authComputed } from '@/store/helpers.js'

export default {
    props: {
        statusSetter: {
            type: Function
        }
    },
    computed: {
        ...mapGetters({
            pending: 'api/isPending'
        }),
        ...mapState({
            ...authComputed // loggedIn and userType prop
        }),
        isPending() {
            // get api status for button feedback
            return this.pending('clearCustomerBillingData')
        },
        buttonStatus() {
            return this.isPending ? 'loading' : 'default'
        }
    },
    methods: {
        async deleteBillingData () {
            try {
                this.statusSetter(true)
                const res = await store.dispatch('api/clearCustomerBillingData')

                await store.dispatch('billing/clearData')
            } catch (err) {
                console.log(err)
            } finally {
                this.statusSetter(false)
                this.closeModal()
                // location.reload()
            }
        },
        closeModal () {
            $('#modalBillingConfirmRemoveAddress').modal('hide')
        }
    }
};
</script>

<style lang="css" scoped>
</style>
